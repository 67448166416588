import BaseReducer from "../BaseReducer";
import AudioAction from "./audioAction";

export default class AudioReducer extends BaseReducer {
  initialState = {
    requestId: null,
    parsingStatus: {},//loading,done
    analysisStatus: {},//loading,done
    //audioFile: null,
    player: null,
    results: {},
    progress: 0,
    models: {},
    filter: null,
    summary: {},
  };

  [AudioAction.CLEAR_AUDIO_STATE](state, action) {
    return {
      ...this.initialState,
    };
  }

  /* SET ANY PROPERTY TO Audio store*/

  [AudioAction.UPDATE_AUDIO_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [AudioAction.UPDATE_AUDIO_ANALYZING](state, action) {
    const {sourceId, data} = action.payload;
    return {
      ...state,
      results: {
        ...state.results,
        [sourceId]: {
          ...state.results[sourceId],
          analysis: data,
        },
      },
    };
  }

  [AudioAction.UPDATE_AUDIO_ANALYZING_STATUS](state, action) {
    const {sourceId, status} = action.payload;
    return {
      ...state,
      analysisStatus: {
        ...state.analysisStatus,
        [sourceId]: status,
      },
    };
  }

  [AudioAction.UPDATE_AUDIO_PARSING](state, action) {
    const {id, sourceId, data} = action.payload;
    return {
      ...state,
      results: {
        ...state.results,
        [sourceId]: {
          ...state.results[sourceId],
          parsing: data,
          id,
        },
      },
    };
  }

  [AudioAction.UPDATE_AUDIO_PARSING_STATUS](state, action) {
    const {sourceId, status} = action.payload;
    return {
      ...state,
      parsingStatus: {
        ...state.parsingStatus,
        [sourceId]: status,
      },
    };
  }

  [AudioAction.UPDATE_AUDIO_PROGRESS](state, action) {
    return {
      ...state,
      progress: state.progress + action.payload >= 100 ? 100 : state.progress + action.payload,
    };
  }

  [AudioAction.UPDATE_AUDIO_MODELS](state, action) {
    const {id, sourceId, model, result} = action.payload;
    return {
      ...state,
      models: {
        ...state.models,
        [sourceId + '|' + model]: {
          sourceId,
          id,
          model,
          result,
        }
      },
    };
  }

  [AudioAction.UPDATE_AUDIO_SUMMARY](state, action) {
    const {field, data} = action.payload;
    return {
      ...state,
      summary: {
        ...state.summary,
        [field]: data,
      },
    };
  }}
