import React, { useState, useEffect } from "react";
import * as Styled from "./styled";

import {Button, Drawer, Layout, Result} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import SettingsAction from "../../../store/settings/settingsAction";
import {getDrawerOpen} from "../../../store/settings/settingsSelector";
import config from "../../../config/config";
import {EXT_TITLE, VERSION} from "../../../config/consts";
import LogoIcon from "../../../components/UI/Icons/LogoIcon";

const MainDrawer = () => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(getDrawerOpen)

  //state

  //handlers
  const handleClose = () => {
    dispatch(SettingsAction.updateState({drawerOpen: false}))
  }

  //effects

  return (
    <Styled.FullVersionDrawer
      title="Under construction"
      placement="right"
      onClose={handleClose}
      open={drawerOpen}
    >
      <Result
        icon={<LogoIcon
          type={'irbis'}
          title={`${config.appTitle || EXT_TITLE} ${VERSION}`}
          style={{maxHeight: '56px'}}
        />}
        title="The selected function is not yet ready"
        extra={
          <>
            <p>The specified functionality is under development. Spectral audio analysis specialists are involved. As soon as the working version is ready, we will inform you.</p>
            <p>&nbsp;</p>
            <Button type="primary" key="console" onClick={handleClose}>
              Stay tuned
            </Button>
          </>
        }
      />
    </Styled.FullVersionDrawer>
  );
};

export default MainDrawer;