import i18n from "i18next";
import {getFromStorage, saveToStorage} from "../../helpers/gridSaver";
const defaultPrompts = {
  topic: {
    prompt: `make me Title of topick of following conversation. which should provide short but confident understanding the topic of conversation. make title be in %LANG% language: `,
    label: i18n.t("Topic"),
  },
  summary: {
    prompt: `make a summary of one or up to three transcription texts of the same single conversation. try to determine the gender of spoken people, extract keywords which are called entities (entities: places names, persons names, dates currency and money amounts with currency, religion aspects, places hollydays, people intent, locations). make attention and маке description on the following: prison term, criminal record, problems with the law, lawyer, court, police. summary should include all entities extracted with logical explanations how its connected or related. make a point on tone of conversation: calm, tense, aggressive, disappointment, joy, sad. do not apologize or explain why you can't finalize the job, do not explain what you need to do in order to perform the job. make summary be in %LANG%: `,
    label: i18n.t("Summary"),
  },
  summary_with_entities: {
    prompt: `choose the most relevant and extensive conclusion in terms of quality and clarity among the1 or up to 4 submitted conclusions. give preference to the one that indicates names, places, the most connections between these terms, mentions of amounts and money, events, intentions, locations, relation to the law and possible problems with it. do not explain why it was chosen. just quote it. make summary be in %LANG%: `,
    label: i18n.t("Full report"),
  },
  summaries: {
    prompt: `Summarize the main points of Speaker 1 and Speaker 2 from their conversation. make summary be in %LANG% language: `,
    label: i18n.t("Speakers Summaries"),
  },
  psychological_profile_of_the_character: {
    prompt: `Summarize the characters of the speakers in a conversation using these points. make summary be in %LANG% language: `,
    label: i18n.t("Profile of the character"),
  },
}

export const preparePromptList = () => {
  const alreadySaved = getFromStorage('prompts', defaultPrompts);
  return {...alreadySaved}
}

export const prepareUpdatePromptList = (list) => {
  saveToStorage('prompts', list);
  return {...list}
}