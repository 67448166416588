/* tslint:disable */
/* eslint-disable */
/**
 * OsintFooting
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1713783263247
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface APAProfileDTO
 */
export interface APAProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof APAProfileDTO
     */
    'caseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof APAProfileDTO
     */
    'investigationName'?: string;
    /**
     * 
     * @type {Array<SocialProfileData>}
     * @memberof APAProfileDTO
     */
    'profiles'?: Array<SocialProfileData>;
}
/**
 * 
 * @export
 * @interface APAWebintDTO
 */
export interface APAWebintDTO {
    /**
     * 
     * @type {string}
     * @memberof APAWebintDTO
     */
    'caseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof APAWebintDTO
     */
    'investigationName'?: string;
    /**
     * 
     * @type {WebIntEntity}
     * @memberof APAWebintDTO
     */
    'webintEntity'?: WebIntEntity;
}
/**
 * 
 * @export
 * @interface AdditionalFriendStatistic
 */
export interface AdditionalFriendStatistic {
    /**
     * 
     * @type {string}
     * @memberof AdditionalFriendStatistic
     */
    'socialNetwork'?: AdditionalFriendStatisticSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof AdditionalFriendStatistic
     */
    'socialProfileId'?: string;
    /**
     * 
     * @type {Set<LookupRequestItemStat>}
     * @memberof AdditionalFriendStatistic
     */
    'stat'?: Set<LookupRequestItemStat>;
}

/**
    * @export
    * @enum {string}
    */
export enum AdditionalFriendStatisticSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface AdditionalFriendsResp
 */
export interface AdditionalFriendsResp {
    /**
     * 
     * @type {Set<CrawlerResponseDTO>}
     * @memberof AdditionalFriendsResp
     */
    'content'?: Set<CrawlerResponseDTO>;
    /**
     * 
     * @type {Array<AdditionalFriendStatistic>}
     * @memberof AdditionalFriendsResp
     */
    'statistics'?: Array<AdditionalFriendStatistic>;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'poBox'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'house'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'apartment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'display'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'searchable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'viableAddressSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'validCountry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'validState'?: boolean;
}
/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'targetId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'createdTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'contentDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'content'?: string;
    /**
     * 
     * @type {AlertCriteria}
     * @memberof Alert
     */
    'alertCriteria'?: AlertCriteria;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'socialNetwork'?: AlertSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'module'?: AlertModuleEnum;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'alertType'?: AlertAlertTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'subType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'referenceUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'postIdItem'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'postUrl'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AlertSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum AlertModuleEnum {
    Profiler = 'PROFILER',
    Webint = 'WEBINT'
}
/**
    * @export
    * @enum {string}
    */
export enum AlertAlertTypeEnum {
    Keyword = 'KEYWORD',
    FaceImage = 'FACE_IMAGE',
    PostAuditoryInGroup = 'POST_AUDITORY_IN_GROUP',
    GroupMembers = 'GROUP_MEMBERS',
    UserActivityInGroup = 'USER_ACTIVITY_IN_GROUP',
    GroupPublicationsBurst = 'GROUP_PUBLICATIONS_BURST',
    GroupCommentsBurst = 'GROUP_COMMENTS_BURST',
    SystemAlert = 'SYSTEM_ALERT'
}

/**
 * 
 * @export
 * @interface AlertCriteria
 */
export interface AlertCriteria {
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'alertType': AlertCriteriaAlertTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'ownedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'faceImageURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'faceImageMatchScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'targetID'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'membersThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'auditoryThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'publicationBurstThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'commentsBurstThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertCriteria
     */
    'userActivityInGroupThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'userSocialNetworkID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertCriteria
     */
    'activeForProfiler': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AlertCriteria
     */
    'activeForWebint': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertCriteria
     */
    'userSocialNetworkType'?: string;
    /**
     * 
     * @type {TelegramIntegration}
     * @memberof AlertCriteria
     */
    'telegramIntegration'?: TelegramIntegration;
}

/**
    * @export
    * @enum {string}
    */
export enum AlertCriteriaAlertTypeEnum {
    Keyword = 'KEYWORD',
    FaceImage = 'FACE_IMAGE',
    PostAuditoryInGroup = 'POST_AUDITORY_IN_GROUP',
    GroupMembers = 'GROUP_MEMBERS',
    UserActivityInGroup = 'USER_ACTIVITY_IN_GROUP',
    GroupPublicationsBurst = 'GROUP_PUBLICATIONS_BURST',
    GroupCommentsBurst = 'GROUP_COMMENTS_BURST',
    SystemAlert = 'SYSTEM_ALERT'
}

/**
 * 
 * @export
 * @interface AlertFilter
 */
export interface AlertFilter {
    /**
     * 
     * @type {number}
     * @memberof AlertFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertFilter
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertFilter
     */
    'startTimestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertFilter
     */
    'endTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertFilter
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertFilter
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertFilter
     */
    'alertContent'?: string;
    /**
     * 
     * @type {AlertCriteria}
     * @memberof AlertFilter
     */
    'alertCriteria'?: AlertCriteria;
    /**
     * 
     * @type {string}
     * @memberof AlertFilter
     */
    'alertType'?: AlertFilterAlertTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AlertFilterAlertTypeEnum {
    Keyword = 'KEYWORD',
    FaceImage = 'FACE_IMAGE',
    PostAuditoryInGroup = 'POST_AUDITORY_IN_GROUP',
    GroupMembers = 'GROUP_MEMBERS',
    UserActivityInGroup = 'USER_ACTIVITY_IN_GROUP',
    GroupPublicationsBurst = 'GROUP_PUBLICATIONS_BURST',
    GroupCommentsBurst = 'GROUP_COMMENTS_BURST',
    SystemAlert = 'SYSTEM_ALERT'
}

/**
 * 
 * @export
 * @interface AnalyticItemsRequest
 */
export interface AnalyticItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof AnalyticItemsRequest
     */
    'items'?: AnalyticItemsRequestItemsEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticItemsRequest
     */
    'itemsIds'?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AnalyticItemsRequestItemsEnum {
    Targets = 'targets',
    Cases = 'cases',
    Investigations = 'investigations'
}

/**
 * 
 * @export
 * @interface AnalyticItemsResponse
 */
export interface AnalyticItemsResponse {
    /**
     * 
     * @type {Set<FriendNode | GameNode | GroupNode | ProfileNode>}
     * @memberof AnalyticItemsResponse
     */
    'nodes'?: Set<FriendNode | GameNode | GroupNode | ProfileNode>;
    /**
     * 
     * @type {Set<Link>}
     * @memberof AnalyticItemsResponse
     */
    'links'?: Set<Link>;
}
/**
 * 
 * @export
 * @interface AsyncTaskDTO
 */
export interface AsyncTaskDTO {
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskDTO
     */
    'asyncTaskID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskDTO
     */
    'status'?: AsyncTaskDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AsyncTaskDTOStatusEnum {
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface AuditActionDTO
 */
export interface AuditActionDTO {
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'actionType'?: AuditActionDTOActionTypeEnum;
    /**
     * 
     * @type {Array<object>}
     * @memberof AuditActionDTO
     */
    'args'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof AuditActionDTO
     */
    'createdTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'status'?: AuditActionDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'activityType'?: AuditActionDTOActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditActionDTO
     */
    'domain'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuditActionDTOActionTypeEnum {
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    CreateJob = 'CREATE_JOB',
    CreateRangeJob = 'CREATE_RANGE_JOB',
    Job = 'JOB',
    Ping = 'PING',
    Image = 'IMAGE',
    ImageClearStorage = 'IMAGE_CLEAR_STORAGE',
    Audit = 'AUDIT',
    User = 'USER',
    Profile = 'PROFILE',
    HealthStatus = 'HEALTH_STATUS',
    Delete = 'DELETE',
    Search4Faces = 'SEARCH_4_FACES',
    System = 'SYSTEM',
    Alerts = 'ALERTS',
    Profiler = 'PROFILER',
    Webint = 'WEBINT',
    Avatars = 'AVATARS',
    Activities = 'ACTIVITIES',
    AuditEp = 'AUDIT_EP'
}
/**
    * @export
    * @enum {string}
    */
export enum AuditActionDTOStatusEnum {
    Scheduled = 'SCHEDULED',
    Sent = 'SENT',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Success = 'SUCCESS'
}
/**
    * @export
    * @enum {string}
    */
export enum AuditActionDTOActivityTypeEnum {
    Autologin = 'AUTOLOGIN',
    Post = 'POST',
    LikePost = 'LIKE_POST',
    CommentPost = 'COMMENT_POST',
    Url = 'URL',
    GroupPost = 'GROUP_POST',
    UserPost = 'USER_POST'
}

/**
 * 
 * @export
 * @interface AuditCreationReq
 */
export interface AuditCreationReq {
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'details'?: AuditCreationReqDetailsEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'actionType'?: AuditCreationReqActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'logType'?: AuditCreationReqLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'fileName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditCreationReq
     */
    'phoneNumbers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreationReq
     */
    'role'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuditCreationReqDetailsEnum {
    UserNotFound = 'USER_NOT_FOUND',
    GetAuditsLog = 'GET_AUDITS_LOG',
    SuccessLogin = 'SUCCESS_LOGIN',
    SuccessLogout = 'SUCCESS_LOGOUT',
    Ping = 'PING',
    GetAllStatuses = 'GET_ALL_STATUSES',
    GetServiceStatus = 'GET_SERVICE_STATUS',
    GetImage = 'GET_IMAGE',
    ClearStorageInRekognition = 'CLEAR_STORAGE_IN_REKOGNITION',
    CreateAdditionalJob = 'CREATE_ADDITIONAL_JOB',
    ChangeJobStatus = 'CHANGE_JOB_STATUS',
    GetMainJob = 'GET_MAIN_JOB',
    SearchProfiles = 'SEARCH_PROFILES',
    ExportProfiles = 'EXPORT_PROFILES',
    ExportProfilesJson = 'EXPORT_PROFILES_JSON',
    SearchProfileByImage = 'SEARCH_PROFILE_BY_IMAGE',
    SearchProfilesByPhone = 'SEARCH_PROFILES_BY_PHONE',
    GetProfileByImageIds = 'GET_PROFILE_BY_IMAGE_IDS',
    RegisterNewUser = 'REGISTER_NEW_USER',
    SearchUsers = 'SEARCH_USERS',
    CouldNotFindPhone = 'COULD_NOT_FIND_PHONE',
    CouldNotFindIteration = 'COULD_NOT_FIND_ITERATION',
    CouldNotFindBatch = 'COULD_NOT_FIND_BATCH',
    CouldNotFindJob = 'COULD_NOT_FIND_JOB',
    UserDoesNotExist = 'USER_DOES_NOT_EXIST',
    UsernameAddressAlreadyInUse = 'USERNAME_ADDRESS_ALREADY_IN_USE',
    CouldNotDeleteItself = 'COULD_NOT_DELETE_ITSELF',
    JobDoesNotExist = 'JOB_DOES_NOT_EXIST',
    InappropriateJobStatus = 'INAPPROPRIATE_JOB_STATUS',
    JobSentAllPhonesToLookup = 'JOB_SENT_ALL_PHONES_TO_LOOKUP',
    MainJobCouldNotBeCanceled = 'MAIN_JOB_COULD_NOT_BE_CANCELED',
    ImportProfile = 'IMPORT_PROFILE',
    DetectFaces = 'DETECT_FACES',
    SearchFace = 'SEARCH_FACE',
    JobList = 'JOB_LIST',
    JobProfiles = 'JOB_PROFILES',
    Update = 'UPDATE',
    GetCaseProfiles = 'GET_CASE_PROFILES',
    QuotaObjectNotSet = 'QUOTA_OBJECT_NOT_SET',
    QuotaMaxCrawlerPerUserInvalid = 'QUOTA_MAX_CRAWLER_PER_USER_INVALID',
    QuotaMaxCasesPerUserInvalid = 'QUOTA_MAX_CASES_PER_USER_INVALID',
    QuotaMaxLookupPerUserInvalid = 'QUOTA_MAX_LOOKUP_PER_USER_INVALID',
    QuotaMaxPeriodicalopsPerUserInvalid = 'QUOTA_MAX_PERIODICALOPS_PER_USER_INVALID',
    UndefinedCaseType = 'UNDEFINED_CASE_TYPE',
    InsufficientQuotaToPerformOperation = 'INSUFFICIENT_QUOTA_TO_PERFORM_OPERATION',
    InsufficientDailyQuotaToPerformOperation = 'INSUFFICIENT_DAILY_QUOTA_TO_PERFORM_OPERATION',
    QuotaIsExpired = 'QUOTA_IS_EXPIRED',
    QuotaIsNotStartedYet = 'QUOTA_IS_NOT_STARTED_YET',
    SendAvatarActivityToCrawler = 'SEND_AVATAR_ACTIVITY_TO_CRAWLER',
    InvalidOrForbiddenUsername = 'INVALID_OR_FORBIDDEN_USERNAME',
    AddTargetInformation = 'ADD_TARGET_INFORMATION',
    Status = 'STATUS',
    WebintTargetMonitoringLimitExceeded = 'WEBINT_TARGET_MONITORING_LIMIT_EXCEEDED',
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    AddUser = 'ADD_USER',
    DeleteUser = 'DELETE_USER',
    UpdateUser = 'UPDATE_USER',
    RenameUser = 'RENAME_USER',
    Export = 'EXPORT',
    Import = 'IMPORT',
    MonitoringOn = 'MONITORING_ON',
    MonitoringOff = 'MONITORING_OFF',
    Audit = 'AUDIT',
    CreateCase = 'CREATE_CASE',
    DeleteCase = 'DELETE_CASE',
    ArchivedCase = 'ARCHIVED_CASE',
    UnarchivedCase = 'UNARCHIVED_CASE',
    CreateInvestigation = 'CREATE_INVESTIGATION',
    ArchivedInvestigation = 'ARCHIVED_INVESTIGATION',
    UnarchivedInvestigation = 'UNARCHIVED_INVESTIGATION',
    DeleteInvestigation = 'DELETE_INVESTIGATION',
    MonitoringIterationComplete = 'MONITORING_ITERATION_COMPLETE',
    AvatarNotFound = 'AVATAR_NOT_FOUND',
    MonitoringEnded = 'MONITORING_ENDED',
    AvatarBlocked = 'AVATAR_BLOCKED',
    AvatarAutoQuarantined = 'AVATAR_AUTO_QUARANTINED',
    AvatarInProgressLongTime = 'AVATAR_IN_PROGRESS_LONG_TIME',
    CreateAlert = 'CREATE_ALERT',
    DeleteAlert = 'DELETE_ALERT',
    CreateProfile = 'CREATE_PROFILE',
    DeleteProfile = 'DELETE_PROFILE',
    GetProfile = 'GET_PROFILE',
    UpdateProfile = 'UPDATE_PROFILE',
    CrawlProfile = 'CRAWL_PROFILE',
    FaceRecognition = 'FACE_RECOGNITION',
    DeleteSource = 'DELETE_SOURCE',
    LinkAnalysis = 'LINK_ANALYSIS',
    CreateTask = 'CREATE_TASK',
    DeleteTask = 'DELETE_TASK',
    GetTask = 'GET_TASK',
    UpdateTask = 'UPDATE_TASK',
    CrawlTask = 'CRAWL_TASK',
    CreateAvatar = 'CREATE_AVATAR',
    DeleteAvatar = 'DELETE_AVATAR',
    GetAvatar = 'GET_AVATAR',
    UpdateAvatar = 'UPDATE_AVATAR',
    EditAvatar = 'EDIT_AVATAR',
    ManualQuarantine = 'MANUAL_QUARANTINE',
    AutoQuarantine = 'AUTO_QUARANTINE',
    TemporalQuarantine = 'TEMPORAL_QUARANTINE',
    EditQuarantineScheme = 'EDIT_QUARANTINE_SCHEME',
    UpdateAvatarModule = 'UPDATE_AVATAR_MODULE',
    TaskLinkUpdate = 'TASK_LINK_UPDATE',
    ExportLogs = 'EXPORT_LOGS',
    CreateProxy = 'CREATE_PROXY',
    EditProxy = 'EDIT_PROXY',
    AddProfile = 'ADD_PROFILE',
    EditProfile = 'EDIT_PROFILE',
    ApaAvatarInteraction = 'APA_AVATAR_INTERACTION',
    CreateActivity = 'CREATE_ACTIVITY',
    UpdateActivity = 'UPDATE_ACTIVITY',
    DeleteActivity = 'DELETE_ACTIVITY',
    Autologin = 'AUTOLOGIN',
    Url = 'URL',
    UserPost = 'USER_POST',
    GroupPost = 'GROUP_POST',
    Like = 'LIKE',
    Comment = 'COMMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum AuditCreationReqActionTypeEnum {
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    CreateJob = 'CREATE_JOB',
    CreateRangeJob = 'CREATE_RANGE_JOB',
    Job = 'JOB',
    Ping = 'PING',
    Image = 'IMAGE',
    ImageClearStorage = 'IMAGE_CLEAR_STORAGE',
    Audit = 'AUDIT',
    User = 'USER',
    Profile = 'PROFILE',
    HealthStatus = 'HEALTH_STATUS',
    Delete = 'DELETE',
    Search4Faces = 'SEARCH_4_FACES',
    System = 'SYSTEM',
    Alerts = 'ALERTS',
    Profiler = 'PROFILER',
    Webint = 'WEBINT',
    Avatars = 'AVATARS',
    Activities = 'ACTIVITIES',
    AuditEp = 'AUDIT_EP'
}
/**
    * @export
    * @enum {string}
    */
export enum AuditCreationReqLogTypeEnum {
    Logs = 'Logs',
    Activities = 'Activities',
    BlockedAvatars = 'Blocked_avatars'
}

/**
 * 
 * @export
 * @interface AuditFilter
 */
export interface AuditFilter {
    /**
     * 
     * @type {number}
     * @memberof AuditFilter
     */
    'pagenum'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditFilter
     */
    'pagesize'?: number;
    /**
     * 
     * @type {Filter}
     * @memberof AuditFilter
     */
    'filter'?: Filter;
}
/**
 * 
 * @export
 * @interface AuditSearchFilter
 */
export interface AuditSearchFilter {
    /**
     * 
     * @type {string}
     * @memberof AuditSearchFilter
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditSearchFilter
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditSearchFilter
     */
    'actionType'?: AuditSearchFilterActionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AuditSearchFilter
     */
    'timestampFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditSearchFilter
     */
    'timestampTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditSearchFilter
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditSearchFilter
     */
    'role'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuditSearchFilterActionTypeEnum {
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    CreateJob = 'CREATE_JOB',
    CreateRangeJob = 'CREATE_RANGE_JOB',
    Job = 'JOB',
    Ping = 'PING',
    Image = 'IMAGE',
    ImageClearStorage = 'IMAGE_CLEAR_STORAGE',
    Audit = 'AUDIT',
    User = 'USER',
    Profile = 'PROFILE',
    HealthStatus = 'HEALTH_STATUS',
    Delete = 'DELETE',
    Search4Faces = 'SEARCH_4_FACES',
    System = 'SYSTEM',
    Alerts = 'ALERTS',
    Profiler = 'PROFILER',
    Webint = 'WEBINT',
    Avatars = 'AVATARS',
    Activities = 'ACTIVITIES',
    AuditEp = 'AUDIT_EP'
}

/**
 * 
 * @export
 * @interface AvatarActivityDTO
 */
export interface AvatarActivityDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'activity'?: AvatarActivityDTOActivityEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'cron'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'base64photo'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarActivityDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDTO
     */
    'role'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarActivityDTOActivityEnum {
    Autologin = 'AUTOLOGIN',
    Post = 'POST',
    LikePost = 'LIKE_POST',
    CommentPost = 'COMMENT_POST',
    Url = 'URL',
    GroupPost = 'GROUP_POST',
    UserPost = 'USER_POST'
}

/**
 * 
 * @export
 * @interface AvatarActivityDeleteResponse
 */
export interface AvatarActivityDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AvatarActivityDeleteResponse
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvatarActivityDeleteResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AvatarDto}
     * @memberof AvatarActivityDeleteResponse
     */
    'avatar'?: AvatarDto;
}
/**
 * 
 * @export
 * @interface AvatarAdditionalInfo
 */
export interface AvatarAdditionalInfo {
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiAccessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiAccessTokenSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiAppId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiRefreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAdditionalInfo
     */
    'apiSecretKey'?: string;
}
/**
 * 
 * @export
 * @interface AvatarAlertDTO
 */
export interface AvatarAlertDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarAlertDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAlertDTO
     */
    'status'?: AvatarAlertDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarAlertDTO
     */
    'metaAvatarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAlertDTO
     */
    'domain'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarAlertDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarAlertDTO
     */
    'isResolved'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarAlertDTOStatusEnum {
    Scheduled = 'SCHEDULED',
    Sent = 'SENT',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
    Success = 'SUCCESS'
}

/**
 * 
 * @export
 * @interface AvatarAuditDTO
 */
export interface AvatarAuditDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'avatarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarAuditDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'domain'?: AvatarAuditDTODomainEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'auditType'?: AvatarAuditDTOAuditTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'activity'?: AvatarAuditDTOActivityEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarAuditDTO
     */
    'scheduledTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof AvatarAuditDTO
     */
    'failedMessage'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarAuditDTODomainEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum AvatarAuditDTOAuditTypeEnum {
    AvatarInteraction = 'AVATAR_INTERACTION',
    AvatarCreated = 'AVATAR_CREATED',
    AvatarCreating = 'AVATAR_CREATING',
    AvatarScheduleInteraction = 'AVATAR_SCHEDULE_INTERACTION',
    AvatarDeleted = 'AVATAR_DELETED',
    AvatarScheduleSuccess = 'AVATAR_SCHEDULE_SUCCESS',
    AvatarScheduleFailed = 'AVATAR_SCHEDULE_FAILED',
    AvatarBlocked = 'AVATAR_BLOCKED',
    AvatarStatusUpdated = 'AVATAR_STATUS_UPDATED',
    Undefinded = 'UNDEFINDED'
}
/**
    * @export
    * @enum {string}
    */
export enum AvatarAuditDTOActivityEnum {
    Autologin = 'AUTOLOGIN',
    Post = 'POST',
    LikePost = 'LIKE_POST',
    CommentPost = 'COMMENT_POST',
    Url = 'URL',
    GroupPost = 'GROUP_POST',
    UserPost = 'USER_POST'
}

/**
 * 
 * @export
 * @interface AvatarCreationMessageDTO
 */
export interface AvatarCreationMessageDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'base64Photo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'bDay'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'gender'?: AvatarCreationMessageDTOGenderEnum;
    /**
     * 
     * @type {ProxyServer}
     * @memberof AvatarCreationMessageDTO
     */
    'proxy'?: ProxyServer;
    /**
     * 
     * @type {Email}
     * @memberof AvatarCreationMessageDTO
     */
    'email'?: Email;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'usedByUser'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarCreationMessageDTO
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AvatarCreationMessageDTO
     */
    'bday'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarCreationMessageDTOGenderEnum {
    Male = 'MALE',
    Female = 'FEMALE',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface AvatarDTOResponse
 */
export interface AvatarDTOResponse {
    /**
     * 
     * @type {AvatarDto}
     * @memberof AvatarDTOResponse
     */
    'avatar'?: AvatarDto;
    /**
     * 
     * @type {string}
     * @memberof AvatarDTOResponse
     */
    'status'?: AvatarDTOResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarDTOResponse
     */
    'domain'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarDTOResponseStatusEnum {
    Created = 'CREATED',
    AvatarAlreadyExists = 'AVATAR_ALREADY_EXISTS',
    Updated = 'UPDATED'
}

/**
 * 
 * @export
 * @interface AvatarDeleteResponse
 */
export interface AvatarDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AvatarDeleteResponse
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvatarDeleteResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ScheduleInfoDTO>}
     * @memberof AvatarDeleteResponse
     */
    'schedules'?: Array<ScheduleInfoDTO>;
    /**
     * 
     * @type {AvatarDto}
     * @memberof AvatarDeleteResponse
     */
    'avatar'?: AvatarDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvatarDeleteResponse
     */
    'webintIDs'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AvatarDto
 */
export interface AvatarDto {
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'domain'?: AvatarDtoDomainEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'status'?: AvatarDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'statusMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'blockedReason'?: string;
    /**
     * 
     * @type {ProxyServer}
     * @memberof AvatarDto
     */
    'proxy'?: ProxyServer;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'mobileUserAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'desktopUserAgent'?: string;
    /**
     * 
     * @type {AvatarAdditionalInfo}
     * @memberof AvatarDto
     */
    'additionalInfo'?: AvatarAdditionalInfo;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarDto
     */
    'useAlerts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarDto
     */
    'useQuarantine'?: boolean;
    /**
     * 
     * @type {QuarantineInfo}
     * @memberof AvatarDto
     */
    'quarantineInfo'?: QuarantineInfo;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'updatedStatus'?: AvatarDtoUpdatedStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'module'?: AvatarDtoModuleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvatarDto
     */
    'assignedTaskIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvatarDto
     */
    'assignedUsers'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'startQuarantineDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'endQuarantineDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'startUseDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarDto
     */
    'temporaryQuarantine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvatarDto
     */
    'updatedDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarDtoDomainEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum AvatarDtoStatusEnum {
    Valid = 'VALID',
    Blocked = 'BLOCKED',
    RequiresValidation = 'REQUIRES_VALIDATION',
    Disabled = 'DISABLED',
    Quarantine = 'QUARANTINE',
    AvatarNotExists = 'AVATAR_NOT_EXISTS',
    InProgress = 'IN_PROGRESS',
    TemporaryQuarantine = 'TEMPORARY_QUARANTINE'
}
/**
    * @export
    * @enum {string}
    */
export enum AvatarDtoUpdatedStatusEnum {
    Auto = 'AUTO',
    Manual = 'MANUAL'
}
/**
    * @export
    * @enum {string}
    */
export enum AvatarDtoModuleEnum {
    Profiler = 'PROFILER',
    Webint = 'WEBINT'
}

/**
 * 
 * @export
 * @interface AvatarInfo
 */
export interface AvatarInfo {
    /**
     * 
     * @type {string}
     * @memberof AvatarInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarInfo
     */
    'profileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarInfo
     */
    'socialNetwork'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarInfo
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvatarInfo
     */
    'assignedTasks'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AvatarInfo
     */
    'metaAvatarId'?: string;
}
/**
 * 
 * @export
 * @interface AvatarRequest
 */
export interface AvatarRequest {
    /**
     * 
     * @type {string}
     * @memberof AvatarRequest
     */
    'socialNetwork': AvatarRequestSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarRequest
     */
    'instanceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarRequest
     */
    'targetId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarRequestSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface AvatarResponse
 */
export interface AvatarResponse {
    /**
     * 
     * @type {AvatarDto}
     * @memberof AvatarResponse
     */
    'avatar'?: AvatarDto;
    /**
     * 
     * @type {string}
     * @memberof AvatarResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface AvatarReturnRequest
 */
export interface AvatarReturnRequest {
    /**
     * 
     * @type {string}
     * @memberof AvatarReturnRequest
     */
    'status': AvatarReturnRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarReturnRequest
     */
    'avatarId': string;
    /**
     * 
     * @type {string}
     * @memberof AvatarReturnRequest
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarReturnRequestStatusEnum {
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface AvatarReturnResponse
 */
export interface AvatarReturnResponse {
    /**
     * 
     * @type {string}
     * @memberof AvatarReturnResponse
     */
    'status'?: AvatarReturnResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarReturnResponse
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarReturnResponseStatusEnum {
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface AvatarStatisticDTO
 */
export interface AvatarStatisticDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarStatisticDTO
     */
    'avatarId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AvatarStatisticDTO
     */
    'posts'?: number;
    /**
     * 
     * @type {number}
     * @memberof AvatarStatisticDTO
     */
    'likes'?: number;
    /**
     * 
     * @type {number}
     * @memberof AvatarStatisticDTO
     */
    'lastActivity'?: number;
}
/**
 * 
 * @export
 * @interface AvatarStatusDTO
 */
export interface AvatarStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'status'?: AvatarStatusDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'targetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvatarStatusDTO
     */
    'onlyStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarStatusDTO
     */
    'requestId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AvatarStatusDTOStatusEnum {
    Valid = 'VALID',
    Blocked = 'BLOCKED',
    RequiresValidation = 'REQUIRES_VALIDATION',
    Disabled = 'DISABLED',
    Quarantine = 'QUARANTINE',
    AvatarNotExists = 'AVATAR_NOT_EXISTS',
    InProgress = 'IN_PROGRESS',
    TemporaryQuarantine = 'TEMPORARY_QUARANTINE'
}

/**
 * 
 * @export
 * @interface AvatarUsageDTO
 */
export interface AvatarUsageDTO {
    /**
     * 
     * @type {AvatarDto}
     * @memberof AvatarUsageDTO
     */
    'avatarDto'?: AvatarDto;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AvatarUsageDTO
     */
    'dailyUsage'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AvatarUsageDTO
     */
    'moduleUsage'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AvatarVerificationDTO
 */
export interface AvatarVerificationDTO {
    /**
     * 
     * @type {string}
     * @memberof AvatarVerificationDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarVerificationDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvatarVerificationDTO
     */
    'sn'?: string;
}
/**
 * 
 * @export
 * @interface BlockedAvatarDTO
 */
export interface BlockedAvatarDTO {
    /**
     * 
     * @type {number}
     * @memberof BlockedAvatarDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockedAvatarDTO
     */
    'socialNetwork'?: BlockedAvatarDTOSocialNetworkEnum;
    /**
     * 
     * @type {AvatarDto}
     * @memberof BlockedAvatarDTO
     */
    'avatar'?: AvatarDto;
    /**
     * 
     * @type {string}
     * @memberof BlockedAvatarDTO
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockedAvatarDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockedAvatarDTO
     */
    'activity'?: BlockedAvatarDTOActivityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BlockedAvatarDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum BlockedAvatarDTOActivityEnum {
    Autologin = 'AUTOLOGIN',
    Post = 'POST',
    LikePost = 'LIKE_POST',
    CommentPost = 'COMMENT_POST',
    Url = 'URL',
    GroupPost = 'GROUP_POST',
    UserPost = 'USER_POST'
}

/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'caseName': string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    'caseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'caseType': CaseCaseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    'targetCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'status'?: CaseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CaseCaseTypeEnum {
    Profiler = 'PROFILER',
    Webint = 'WEBINT',
    Geomc = 'GEOMC',
    Darkweb = 'DARKWEB',
    Ship = 'SHIP',
    Container = 'CONTAINER'
}
/**
    * @export
    * @enum {string}
    */
export enum CaseStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface CaseCreateDTO
 */
export interface CaseCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDTO
     */
    'invID'?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDTO
     */
    'caseType'?: CaseCreateDTOCaseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CaseCreateDTOCaseTypeEnum {
    Profiler = 'PROFILER',
    Webint = 'WEBINT',
    Geomc = 'GEOMC',
    Darkweb = 'DARKWEB',
    Ship = 'SHIP',
    Container = 'CONTAINER'
}

/**
 * 
 * @export
 * @interface CaseResponseDTO
 */
export interface CaseResponseDTO {
    /**
     * 
     * @type {Case}
     * @memberof CaseResponseDTO
     */
    'caze'?: Case;
    /**
     * 
     * @type {number}
     * @memberof CaseResponseDTO
     */
    'invID'?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseResponseDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'other'?: string;
}
/**
 * 
 * @export
 * @interface CrawlerItemFilterDTO
 */
export interface CrawlerItemFilterDTO {
    /**
     * 
     * @type {Set<string>}
     * @memberof CrawlerItemFilterDTO
     */
    'socialNetwork'?: Set<CrawlerItemFilterDTOSocialNetworkEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CrawlerItemFilterDTO
     */
    'type'?: Set<CrawlerItemFilterDTOTypeEnum>;
    /**
     * 
     * @type {number}
     * @memberof CrawlerItemFilterDTO
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerItemFilterDTO
     */
    'batch'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CrawlerItemFilterDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum CrawlerItemFilterDTOTypeEnum {
    GroupOwnerItem = 'GROUP_OWNER_ITEM',
    GroupMostCommentedProfileItem = 'GROUP_MOST_COMMENTED_PROFILE_ITEM',
    GroupMostPostedProfileItem = 'GROUP_MOST_POSTED_PROFILE_ITEM',
    GroupMostInfluencingProfileItem = 'GROUP_MOST_INFLUENCING_PROFILE_ITEM',
    GroupBotProfileItem = 'GROUP_BOT_PROFILE_ITEM',
    SearchEngineResultsItem = 'SEARCH_ENGINE_RESULTS_ITEM',
    GroupProfileItem = 'GROUP_PROFILE_ITEM',
    PageAlbumItem = 'PAGE_ALBUM_ITEM',
    LocationItem = 'LOCATION_ITEM',
    PostItem = 'POST_ITEM',
    MostActiveProfileItem = 'MOST_ACTIVE_PROFILE_ITEM',
    GroupNewMemberProfileItem = 'GROUP_NEW_MEMBER_PROFILE_ITEM',
    ProfileItem = 'PROFILE_ITEM',
    FriendItem = 'FRIEND_ITEM',
    GroupMostActiveProfileItem = 'GROUP_MOST_ACTIVE_PROFILE_ITEM',
    SearchEngineVideosItem = 'SEARCH_ENGINE_VIDEOS_ITEM',
    GroupAdminProfileItem = 'GROUP_ADMIN_PROFILE_ITEM',
    SearchEngineImagesItem = 'SEARCH_ENGINE_IMAGES_ITEM',
    PagePostItem = 'PAGE_POST_ITEM',
    GoogleMapsItem = 'GOOGLE_MAPS_ITEM',
    GroupMostLikedProfileItem = 'GROUP_MOST_LIKED_PROFILE_ITEM',
    PageMostActiveProfileItem = 'PAGE_MOST_ACTIVE_PROFILE_ITEM',
    PageMostLikedProfileItem = 'PAGE_MOST_LIKED_PROFILE_ITEM',
    GroupPostItem = 'GROUP_POST_ITEM',
    GroupAlbumItem = 'GROUP_ALBUM_ITEM',
    PageProfileItem = 'PAGE_PROFILE_ITEM',
    ProfileAlbumItem = 'PROFILE_ALBUM_ITEM',
    PageMostCommentedProfileItem = 'PAGE_MOST_COMMENTED_PROFILE_ITEM',
    StolenCarItem = 'STOLEN_CAR_ITEM',
    AvatarValidatorItem = 'AVATAR_VALIDATOR_ITEM',
    GroupModeratorProfileItem = 'GROUP_MODERATOR_PROFILE_ITEM',
    GroupMembersItem = 'GROUP_MEMBERS_ITEM',
    GroupBannedItem = 'GROUP_BANNED_ITEM',
    GroupCreatorItem = 'GROUP_CREATOR_ITEM',
    GroupLeftItem = 'GROUP_LEFT_ITEM',
    GroupRestrictedItem = 'GROUP_RESTRICTED_ITEM',
    GroupUnknownItem = 'GROUP_UNKNOWN_ITEM',
    HydraProfileItem = 'HYDRA_PROFILE_ITEM',
    Sniper = 'SNIPER'
}

/**
 * 
 * @export
 * @interface CrawlerProgressDTO
 */
export interface CrawlerProgressDTO {
    /**
     * 
     * @type {number}
     * @memberof CrawlerProgressDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'socialNetwork'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'serviceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'socialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'imageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerProgressDTO
     */
    'progress'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'errorReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'status'?: CrawlerProgressDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerProgressDTO
     */
    'password'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CrawlerProgressDTOStatusEnum {
    Started = 'STARTED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Schedule = 'SCHEDULE',
    Added = 'ADDED',
    Failed = 'FAILED',
    TimeoutError = 'TIMEOUT_ERROR'
}

/**
 * 
 * @export
 * @interface CrawlerResponseDTO
 */
export interface CrawlerResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'assignedEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'socialNetwork'?: CrawlerResponseDTOSocialNetworkEnum;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'resonanceScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'relatedEntityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'relatedLookupRequestItemId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'isProfileDataAdded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'hasHistory'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'type'?: CrawlerResponseDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'customRequestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'confidence'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'friendName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'friendProfileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'friendOf'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'friendImageUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'fullSizeImageUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'imageUrls'?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CrawlerResponseDTO
     */
    'imagesUrlIds'?: Set<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'imagesUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'actualMainUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'thumbnailUrls'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'places'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'cities'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'recent'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'albumName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'externalLinkUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'videosUrl'?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CrawlerResponseDTO
     */
    'imageIds'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileCoverPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileEducation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileBirthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileAge'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileSex'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileWork'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'originalPostUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'referenceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'referenceUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'originalPostUserImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'postedByProfileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'originalPostUserName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'totalReactionsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'likeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'niceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'hahaCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'loveCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'wowCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'sadCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'angryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'commentsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'sharesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'careCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'celebrateCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'curiousCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'insightfulCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'appreciationCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'postUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'postLikeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'viewsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'referenceUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'referenceUserImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'referenceContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'groupImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'groupCoverPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'groupName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'isSharedPost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'pageImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'resultPreview'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'transactions'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneOwnerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'resultTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'totalSent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneCarrier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneOwnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneOwnerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'noTransactions'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'finalBalance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneSearchEngine'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneOwnerAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'searchEngine'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'hashAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'linkedinUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'linkedinProfileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'contextUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'resultLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'qrcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'pageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'pageCoverPhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'totalReceived'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'itemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'pageName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'resultType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'gmailResponse'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'lookupDataNewFormat'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'carid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileImageBase64'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'internalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'internalMessageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'profileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'lastSeenTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'sharedText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'likedText'?: string;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'love'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'like'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'likes'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'nice'?: Array<Interaction>;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'text'?: string;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'wow'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'haha'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'comments'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'sad'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'angry'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'care'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'celebrate'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'curious'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'appreciation'?: Array<Interaction>;
    /**
     * 
     * @type {Array<Interaction>}
     * @memberof CrawlerResponseDTO
     */
    'insightful'?: Array<Interaction>;
    /**
     * 
     * @type {ProfileData}
     * @memberof CrawlerResponseDTO
     */
    'profileData'?: ProfileData;
    /**
     * 
     * @type {Sentiment}
     * @memberof CrawlerResponseDTO
     */
    'sentiment'?: Sentiment;
    /**
     * 
     * @type {SentimentStat}
     * @memberof CrawlerResponseDTO
     */
    'sentimentStat'?: SentimentStat;
    /**
     * 
     * @type {GroupData}
     * @memberof CrawlerResponseDTO
     */
    'groupData'?: GroupData;
    /**
     * 
     * @type {Array<CrawlerResponseDTO>}
     * @memberof CrawlerResponseDTO
     */
    'posts'?: Array<CrawlerResponseDTO>;
    /**
     * 
     * @type {PageData}
     * @memberof CrawlerResponseDTO
     */
    'pageData'?: PageData;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CrawlerResponseDTO
     */
    'postStats'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CrawlerResponseDTO
     */
    'ageStats'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CrawlerResponseDTO
     */
    'locationStats'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<InteractionGiven>}
     * @memberof CrawlerResponseDTO
     */
    'likesGiven'?: Array<InteractionGiven>;
    /**
     * 
     * @type {Array<InteractionGiven>}
     * @memberof CrawlerResponseDTO
     */
    'commentsGiven'?: Array<InteractionGiven>;
    /**
     * 
     * @type {Array<InteractionGiven>}
     * @memberof CrawlerResponseDTO
     */
    'likesReceived'?: Array<InteractionGiven>;
    /**
     * 
     * @type {Array<InteractionGiven>}
     * @memberof CrawlerResponseDTO
     */
    'commentsReceived'?: Array<InteractionGiven>;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'location'?: object;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'messageId'?: number;
    /**
     * 
     * @type {PostAnalyze}
     * @memberof CrawlerResponseDTO
     */
    'postAnalyze'?: PostAnalyze;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'albums'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'friends'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'locations'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'postsLiked'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'postsCommented'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'youtubeResultsItem'?: object;
    /**
     * 
     * @type {object}
     * @memberof CrawlerResponseDTO
     */
    'youtubeVideoDetails'?: object;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'videoCommentsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'videoViewsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'videoPublishedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'videoDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'videoDislikesCount'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CrawlerResponseDTO
     */
    'videoCommentsDetails'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'thumbnailLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'videoLikesCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Replies}
     * @memberof CrawlerResponseDTO
     */
    'replies'?: Replies;
    /**
     * 
     * @type {number}
     * @memberof CrawlerResponseDTO
     */
    'postAuditory'?: number;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'lastNameT'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'firstNameT'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerResponseDTO
     */
    'previousType'?: CrawlerResponseDTOPreviousTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'sharedImagesUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'sharedVideosUrl'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CrawlerResponseDTO
     */
    'sharedExternalLinkUrl'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'isLastPost'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerResponseDTO
     */
    'isLastIteration'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CrawlerResponseDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum CrawlerResponseDTOTypeEnum {
    GroupOwnerItem = 'GROUP_OWNER_ITEM',
    GroupMostCommentedProfileItem = 'GROUP_MOST_COMMENTED_PROFILE_ITEM',
    GroupMostPostedProfileItem = 'GROUP_MOST_POSTED_PROFILE_ITEM',
    GroupMostInfluencingProfileItem = 'GROUP_MOST_INFLUENCING_PROFILE_ITEM',
    GroupBotProfileItem = 'GROUP_BOT_PROFILE_ITEM',
    SearchEngineResultsItem = 'SEARCH_ENGINE_RESULTS_ITEM',
    GroupProfileItem = 'GROUP_PROFILE_ITEM',
    PageAlbumItem = 'PAGE_ALBUM_ITEM',
    LocationItem = 'LOCATION_ITEM',
    PostItem = 'POST_ITEM',
    MostActiveProfileItem = 'MOST_ACTIVE_PROFILE_ITEM',
    GroupNewMemberProfileItem = 'GROUP_NEW_MEMBER_PROFILE_ITEM',
    ProfileItem = 'PROFILE_ITEM',
    FriendItem = 'FRIEND_ITEM',
    GroupMostActiveProfileItem = 'GROUP_MOST_ACTIVE_PROFILE_ITEM',
    SearchEngineVideosItem = 'SEARCH_ENGINE_VIDEOS_ITEM',
    GroupAdminProfileItem = 'GROUP_ADMIN_PROFILE_ITEM',
    SearchEngineImagesItem = 'SEARCH_ENGINE_IMAGES_ITEM',
    PagePostItem = 'PAGE_POST_ITEM',
    GoogleMapsItem = 'GOOGLE_MAPS_ITEM',
    GroupMostLikedProfileItem = 'GROUP_MOST_LIKED_PROFILE_ITEM',
    PageMostActiveProfileItem = 'PAGE_MOST_ACTIVE_PROFILE_ITEM',
    PageMostLikedProfileItem = 'PAGE_MOST_LIKED_PROFILE_ITEM',
    GroupPostItem = 'GROUP_POST_ITEM',
    GroupAlbumItem = 'GROUP_ALBUM_ITEM',
    PageProfileItem = 'PAGE_PROFILE_ITEM',
    ProfileAlbumItem = 'PROFILE_ALBUM_ITEM',
    PageMostCommentedProfileItem = 'PAGE_MOST_COMMENTED_PROFILE_ITEM',
    StolenCarItem = 'STOLEN_CAR_ITEM',
    AvatarValidatorItem = 'AVATAR_VALIDATOR_ITEM',
    GroupModeratorProfileItem = 'GROUP_MODERATOR_PROFILE_ITEM',
    GroupMembersItem = 'GROUP_MEMBERS_ITEM',
    GroupBannedItem = 'GROUP_BANNED_ITEM',
    GroupCreatorItem = 'GROUP_CREATOR_ITEM',
    GroupLeftItem = 'GROUP_LEFT_ITEM',
    GroupRestrictedItem = 'GROUP_RESTRICTED_ITEM',
    GroupUnknownItem = 'GROUP_UNKNOWN_ITEM',
    HydraProfileItem = 'HYDRA_PROFILE_ITEM',
    Sniper = 'SNIPER'
}
/**
    * @export
    * @enum {string}
    */
export enum CrawlerResponseDTOPreviousTypeEnum {
    GroupOwnerItem = 'GROUP_OWNER_ITEM',
    GroupMostCommentedProfileItem = 'GROUP_MOST_COMMENTED_PROFILE_ITEM',
    GroupMostPostedProfileItem = 'GROUP_MOST_POSTED_PROFILE_ITEM',
    GroupMostInfluencingProfileItem = 'GROUP_MOST_INFLUENCING_PROFILE_ITEM',
    GroupBotProfileItem = 'GROUP_BOT_PROFILE_ITEM',
    SearchEngineResultsItem = 'SEARCH_ENGINE_RESULTS_ITEM',
    GroupProfileItem = 'GROUP_PROFILE_ITEM',
    PageAlbumItem = 'PAGE_ALBUM_ITEM',
    LocationItem = 'LOCATION_ITEM',
    PostItem = 'POST_ITEM',
    MostActiveProfileItem = 'MOST_ACTIVE_PROFILE_ITEM',
    GroupNewMemberProfileItem = 'GROUP_NEW_MEMBER_PROFILE_ITEM',
    ProfileItem = 'PROFILE_ITEM',
    FriendItem = 'FRIEND_ITEM',
    GroupMostActiveProfileItem = 'GROUP_MOST_ACTIVE_PROFILE_ITEM',
    SearchEngineVideosItem = 'SEARCH_ENGINE_VIDEOS_ITEM',
    GroupAdminProfileItem = 'GROUP_ADMIN_PROFILE_ITEM',
    SearchEngineImagesItem = 'SEARCH_ENGINE_IMAGES_ITEM',
    PagePostItem = 'PAGE_POST_ITEM',
    GoogleMapsItem = 'GOOGLE_MAPS_ITEM',
    GroupMostLikedProfileItem = 'GROUP_MOST_LIKED_PROFILE_ITEM',
    PageMostActiveProfileItem = 'PAGE_MOST_ACTIVE_PROFILE_ITEM',
    PageMostLikedProfileItem = 'PAGE_MOST_LIKED_PROFILE_ITEM',
    GroupPostItem = 'GROUP_POST_ITEM',
    GroupAlbumItem = 'GROUP_ALBUM_ITEM',
    PageProfileItem = 'PAGE_PROFILE_ITEM',
    ProfileAlbumItem = 'PROFILE_ALBUM_ITEM',
    PageMostCommentedProfileItem = 'PAGE_MOST_COMMENTED_PROFILE_ITEM',
    StolenCarItem = 'STOLEN_CAR_ITEM',
    AvatarValidatorItem = 'AVATAR_VALIDATOR_ITEM',
    GroupModeratorProfileItem = 'GROUP_MODERATOR_PROFILE_ITEM',
    GroupMembersItem = 'GROUP_MEMBERS_ITEM',
    GroupBannedItem = 'GROUP_BANNED_ITEM',
    GroupCreatorItem = 'GROUP_CREATOR_ITEM',
    GroupLeftItem = 'GROUP_LEFT_ITEM',
    GroupRestrictedItem = 'GROUP_RESTRICTED_ITEM',
    GroupUnknownItem = 'GROUP_UNKNOWN_ITEM',
    HydraProfileItem = 'HYDRA_PROFILE_ITEM',
    Sniper = 'SNIPER'
}

/**
 * 
 * @export
 * @interface CrawlerSearchResultDTO
 */
export interface CrawlerSearchResultDTO {
    /**
     * 
     * @type {string}
     * @memberof CrawlerSearchResultDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerSearchResultDTO
     */
    'profileId'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof CrawlerSearchResultDTO
     */
    'imageIds'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CrawlerSearchResultDTO
     */
    'profileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrawlerSearchResultDTO
     */
    'socialNetwork'?: CrawlerSearchResultDTOSocialNetworkEnum;
    /**
     * 
     * @type {number}
     * @memberof CrawlerSearchResultDTO
     */
    'confidence'?: number;
    /**
     * 
     * @type {number}
     * @memberof CrawlerSearchResultDTO
     */
    'relatedLookupRequestItemId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CrawlerSearchResultDTO
     */
    'isProfileDataAdded'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CrawlerSearchResultDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface DOB
 */
export interface DOB {
    /**
     * 
     * @type {string}
     * @memberof DOB
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof DOB
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DOB
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DOB
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {DateRange}
     * @memberof DOB
     */
    'dateDange'?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof DOB
     */
    'display'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DOB
     */
    'searchable'?: boolean;
    /**
     * 
     * @type {DateRange}
     * @memberof DOB
     */
    'dateRange'?: DateRange;
}
/**
 * 
 * @export
 * @interface DataWithSource
 */
export interface DataWithSource {
    /**
     * 
     * @type {string}
     * @memberof DataWithSource
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataWithSource
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface DataWithSourceAndSN
 */
export interface DataWithSourceAndSN {
    /**
     * 
     * @type {string}
     * @memberof DataWithSourceAndSN
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataWithSourceAndSN
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataWithSourceAndSN
     */
    'socialNetwork'?: string;
}
/**
 * 
 * @export
 * @interface DataWithSources
 */
export interface DataWithSources {
    /**
     * 
     * @type {Set<string>}
     * @memberof DataWithSources
     */
    'sources'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof DataWithSources
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataWithSources
     */
    'socialNetwork'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataWithSources
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     * 
     * @type {number}
     * @memberof DateFilter
     */
    'dateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateFilter
     */
    'dateTo'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateFilter
     */
    'auditType'?: DateFilterAuditTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DateFilterAuditTypeEnum {
    AvatarInteraction = 'AVATAR_INTERACTION',
    AvatarCreated = 'AVATAR_CREATED',
    AvatarCreating = 'AVATAR_CREATING',
    AvatarScheduleInteraction = 'AVATAR_SCHEDULE_INTERACTION',
    AvatarDeleted = 'AVATAR_DELETED',
    AvatarScheduleSuccess = 'AVATAR_SCHEDULE_SUCCESS',
    AvatarScheduleFailed = 'AVATAR_SCHEDULE_FAILED',
    AvatarBlocked = 'AVATAR_BLOCKED',
    AvatarStatusUpdated = 'AVATAR_STATUS_UPDATED',
    Undefinded = 'UNDEFINDED'
}

/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'end'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DateRange
     */
    'exact'?: boolean;
}
/**
 * 
 * @export
 * @interface DateRangeDTO
 */
export interface DateRangeDTO {
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTO
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTO
     */
    'end'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTO
     */
    'offset'?: number;
    /**
     * 
     * @type {DateRangeDTOZoneId}
     * @memberof DateRangeDTO
     */
    'zoneId'?: DateRangeDTOZoneId;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneId
 */
export interface DateRangeDTOZoneId {
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneId
     */
    'id'?: string;
    /**
     * 
     * @type {DateRangeDTOZoneIdRules}
     * @memberof DateRangeDTOZoneId
     */
    'rules'?: DateRangeDTOZoneIdRules;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRules
 */
export interface DateRangeDTOZoneIdRules {
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRules
     */
    'fixedOffset'?: boolean;
    /**
     * 
     * @type {Array<DateRangeDTOZoneIdRulesTransitions>}
     * @memberof DateRangeDTOZoneIdRules
     */
    'transitions'?: Array<DateRangeDTOZoneIdRulesTransitions>;
    /**
     * 
     * @type {Array<DateRangeDTOZoneIdRulesTransitionRules>}
     * @memberof DateRangeDTOZoneIdRules
     */
    'transitionRules'?: Array<DateRangeDTOZoneIdRulesTransitionRules>;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRulesDuration
 */
export interface DateRangeDTOZoneIdRulesDuration {
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTOZoneIdRulesDuration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTOZoneIdRulesDuration
     */
    'nano'?: number;
    /**
     * 
     * @type {Array<DateRangeDTOZoneIdRulesDurationUnits>}
     * @memberof DateRangeDTOZoneIdRulesDuration
     */
    'units'?: Array<DateRangeDTOZoneIdRulesDurationUnits>;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesDuration
     */
    'zero'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesDuration
     */
    'negative'?: boolean;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRulesDurationUnits
 */
export interface DateRangeDTOZoneIdRulesDurationUnits {
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesDurationUnits
     */
    'dateBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesDurationUnits
     */
    'timeBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesDurationUnits
     */
    'durationEstimated'?: boolean;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRulesOffsetBefore
 */
export interface DateRangeDTOZoneIdRulesOffsetBefore {
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTOZoneIdRulesOffsetBefore
     */
    'totalSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesOffsetBefore
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRulesTransitionRules
 */
export interface DateRangeDTOZoneIdRulesTransitionRules {
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'month'?: DateRangeDTOZoneIdRulesTransitionRulesMonthEnum;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'timeDefinition'?: DateRangeDTOZoneIdRulesTransitionRulesTimeDefinitionEnum;
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesOffsetBefore}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'standardOffset'?: DateRangeDTOZoneIdRulesOffsetBefore;
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesOffsetBefore}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'offsetBefore'?: DateRangeDTOZoneIdRulesOffsetBefore;
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesOffsetBefore}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'offsetAfter'?: DateRangeDTOZoneIdRulesOffsetBefore;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'dayOfWeek'?: DateRangeDTOZoneIdRulesTransitionRulesDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'dayOfMonthIndicator'?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'localTime'?: LocalTime;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesTransitionRules
     */
    'midnightEndOfDay'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DateRangeDTOZoneIdRulesTransitionRulesMonthEnum {
    January = 'JANUARY',
    February = 'FEBRUARY',
    March = 'MARCH',
    April = 'APRIL',
    May = 'MAY',
    June = 'JUNE',
    July = 'JULY',
    August = 'AUGUST',
    September = 'SEPTEMBER',
    October = 'OCTOBER',
    November = 'NOVEMBER',
    December = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum DateRangeDTOZoneIdRulesTransitionRulesTimeDefinitionEnum {
    Utc = 'UTC',
    Wall = 'WALL',
    Standard = 'STANDARD'
}
/**
    * @export
    * @enum {string}
    */
export enum DateRangeDTOZoneIdRulesTransitionRulesDayOfWeekEnum {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY'
}

/**
 * 
 * @export
 * @interface DateRangeDTOZoneIdRulesTransitions
 */
export interface DateRangeDTOZoneIdRulesTransitions {
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesOffsetBefore}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'offsetBefore'?: DateRangeDTOZoneIdRulesOffsetBefore;
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesOffsetBefore}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'offsetAfter'?: DateRangeDTOZoneIdRulesOffsetBefore;
    /**
     * 
     * @type {DateRangeDTOZoneIdRulesDuration}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'duration'?: DateRangeDTOZoneIdRulesDuration;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'gap'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'dateTimeBefore'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'dateTimeAfter'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'instant'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DateRangeDTOZoneIdRulesTransitions
     */
    'overlap'?: boolean;
}
/**
 * 
 * @export
 * @interface DateWithSourceAndSN
 */
export interface DateWithSourceAndSN {
    /**
     * 
     * @type {string}
     * @memberof DateWithSourceAndSN
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateWithSourceAndSN
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateWithSourceAndSN
     */
    'socialNetwork'?: string;
}
/**
 * 
 * @export
 * @interface DeepWebDTOView
 */
export interface DeepWebDTOView {
    /**
     * 
     * @type {Array<Person>}
     * @memberof DeepWebDTOView
     */
    'persons'?: Array<Person>;
    /**
     * 
     * @type {Array<Person>}
     * @memberof DeepWebDTOView
     */
    'possiblePersons'?: Array<Person>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof DeepWebDTOView
     */
    'sources'?: Array<Source>;
    /**
     * 
     * @type {Array<HydraItemDTOView>}
     * @memberof DeepWebDTOView
     */
    'hydraItems'?: Array<HydraItemDTOView>;
}
/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * 
     * @type {Array<string>}
     * @memberof Education
     */
    'otherInfo'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'schoolImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'educationLevel'?: string;
}
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'incomingServer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'outgoingServer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'emailProtocol'?: EmailEmailProtocolEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailEmailProtocolEnum {
    Undefined = 'UNDEFINED',
    Pop3 = 'POP3',
    Imap = 'IMAP'
}

/**
 * 
 * @export
 * @interface Ethnicity
 */
export interface Ethnicity {
    /**
     * 
     * @type {string}
     * @memberof Ethnicity
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ethnicity
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ethnicity
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Ethnicity
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Ethnicity
     */
    'content'?: EthnicityContentEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Ethnicity
     */
    'searchable'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum EthnicityContentEnum {
    White = 'white',
    Black = 'black',
    AmericanIndian = 'american_indian',
    AlaskaNative = 'alaska_native',
    AsianIndian = 'asian_indian',
    Chinese = 'chinese',
    Filipino = 'filipino',
    OtherAsian = 'other_asian',
    Japanese = 'japanese',
    Korean = 'korean',
    Viatnamese = 'viatnamese',
    NativeHawaiian = 'native_hawaiian',
    Guamanian = 'guamanian',
    Chamorro = 'chamorro',
    Samoan = 'samoan',
    OtherPacificIslander = 'other_pacific_islander',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface FamilyMember
 */
export interface FamilyMember {
    /**
     * 
     * @type {string}
     * @memberof FamilyMember
     */
    'linkUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMember
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMember
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMember
     */
    'relationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMember
     */
    'fbId'?: string;
}
/**
 * 
 * @export
 * @interface FetchMoreFriendsReq
 */
export interface FetchMoreFriendsReq {
    /**
     * 
     * @type {number}
     * @memberof FetchMoreFriendsReq
     */
    'profileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchMoreFriendsReq
     */
    'socialNetwork'?: FetchMoreFriendsReqSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchMoreFriendsReq
     */
    'socialProfileId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FetchMoreFriendsReq
     */
    'numberFriends'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FetchMoreFriendsReqSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'dateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'dateTo'?: number;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'sn'?: FilterSnEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterSnEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface FilterTreeInv
 */
export interface FilterTreeInv {
    /**
     * 
     * @type {string}
     * @memberof FilterTreeInv
     */
    'keyword'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilterTreeInv
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterTreeInv
     */
    'to'?: number;
}
/**
 * 
 * @export
 * @interface FindCloneResponseData
 */
export interface FindCloneResponseData {
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseData
     */
    'city'?: string;
    /**
     * 
     * @type {Array<FindCloneResponseDetails>}
     * @memberof FindCloneResponseData
     */
    'details'?: Array<FindCloneResponseDetails>;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseData
     */
    'firstname'?: string;
    /**
     * 
     * @type {number}
     * @memberof FindCloneResponseData
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseData
     */
    'thumbnail'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseData
     */
    'userid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseData
     */
    'item_id'?: string;
}
/**
 * 
 * @export
 * @interface FindCloneResponseDetails
 */
export interface FindCloneResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseDetails
     */
    'photoid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseDetails
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseDetails
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCloneResponseDetails
     */
    'userid'?: string;
    /**
     * 
     * @type {number}
     * @memberof FindCloneResponseDetails
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof FindCloneResponseDetails
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface FriendNode
 */
export interface FriendNode extends Node {
    /**
     * 
     * @type {string}
     * @memberof FriendNode
     */
    'socialNetwork'?: FriendNodeSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof FriendNode
     */
    'profileId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FriendNodeSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface FriendNodeAllOf
 */
export interface FriendNodeAllOf {
    /**
     * 
     * @type {string}
     * @memberof FriendNodeAllOf
     */
    'socialNetwork'?: FriendNodeAllOfSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof FriendNodeAllOf
     */
    'profileId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FriendNodeAllOfSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface GameNode
 */
export interface GameNode extends Node {
    /**
     * 
     * @type {string}
     * @memberof GameNode
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameNode
     */
    'socialNetwork'?: GameNodeSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GameNodeSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface GameNodeAllOf
 */
export interface GameNodeAllOf {
    /**
     * 
     * @type {string}
     * @memberof GameNodeAllOf
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameNodeAllOf
     */
    'socialNetwork'?: GameNodeAllOfSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GameNodeAllOfSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface Gender
 */
export interface Gender {
    /**
     * 
     * @type {string}
     * @memberof Gender
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Gender
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Gender
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Gender
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Gender
     */
    'content'?: GenderContentEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Gender
     */
    'searchable'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GenderContentEnum {
    Male = 'male',
    Female = 'female'
}

/**
 * 
 * @export
 * @interface GlobalStatisticFilter
 */
export interface GlobalStatisticFilter {
    /**
     * 
     * @type {string}
     * @memberof GlobalStatisticFilter
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalStatisticFilter
     */
    'year'?: string;
}
/**
 * 
 * @export
 * @interface GroupActivityFilterDTO
 */
export interface GroupActivityFilterDTO {
    /**
     * 
     * @type {Set<number>}
     * @memberof GroupActivityFilterDTO
     */
    'webintIds'?: Set<number>;
    /**
     * 
     * @type {number}
     * @memberof GroupActivityFilterDTO
     */
    'startTimestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupActivityFilterDTO
     */
    'endTimestamp'?: number;
}
/**
 * 
 * @export
 * @interface GroupDTO
 */
export interface GroupDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    'socialNetwork'?: GroupDTOSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    'avatarId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface GroupData
 */
export interface GroupData {
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupData
     */
    'membersCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'history'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'groupType'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'activity'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupData
     */
    'wikiPage'?: string;
    /**
     * 
     * @type {Array<GroupMember>}
     * @memberof GroupData
     */
    'members'?: Array<GroupMember>;
}
/**
 * 
 * @export
 * @interface GroupMember
 */
export interface GroupMember {
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    'memberLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    'memberImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    'memberName'?: string;
}
/**
 * 
 * @export
 * @interface GroupNode
 */
export interface GroupNode extends Node {
    /**
     * 
     * @type {string}
     * @memberof GroupNode
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupNode
     */
    'socialNetwork'?: GroupNodeSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupNodeSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface HydraItemDTOView
 */
export interface HydraItemDTOView {
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof HydraItemDTOView
     */
    'prId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'customRequestId'?: string;
    /**
     * 
     * @type {number}
     * @memberof HydraItemDTOView
     */
    'relatedEntityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof HydraItemDTOView
     */
    'relatedLookupRequestItemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'socialNetwork'?: HydraItemDTOViewSocialNetworkEnum;
    /**
     * 
     * @type {boolean}
     * @memberof HydraItemDTOView
     */
    'isProfileDataAdded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'type'?: HydraItemDTOViewTypeEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof HydraItemDTOView
     */
    'imageIds'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof HydraItemDTOView
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<SocialUrl>}
     * @memberof HydraItemDTOView
     */
    'socialUrls'?: Array<SocialUrl>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof HydraItemDTOView
     */
    'socialNetworks'?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof HydraItemDTOView
     */
    'properties'?: { [key: string]: object; };
}

/**
    * @export
    * @enum {string}
    */
export enum HydraItemDTOViewSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}
/**
    * @export
    * @enum {string}
    */
export enum HydraItemDTOViewTypeEnum {
    GroupOwnerItem = 'GROUP_OWNER_ITEM',
    GroupMostCommentedProfileItem = 'GROUP_MOST_COMMENTED_PROFILE_ITEM',
    GroupMostPostedProfileItem = 'GROUP_MOST_POSTED_PROFILE_ITEM',
    GroupMostInfluencingProfileItem = 'GROUP_MOST_INFLUENCING_PROFILE_ITEM',
    GroupBotProfileItem = 'GROUP_BOT_PROFILE_ITEM',
    SearchEngineResultsItem = 'SEARCH_ENGINE_RESULTS_ITEM',
    GroupProfileItem = 'GROUP_PROFILE_ITEM',
    PageAlbumItem = 'PAGE_ALBUM_ITEM',
    LocationItem = 'LOCATION_ITEM',
    PostItem = 'POST_ITEM',
    MostActiveProfileItem = 'MOST_ACTIVE_PROFILE_ITEM',
    GroupNewMemberProfileItem = 'GROUP_NEW_MEMBER_PROFILE_ITEM',
    ProfileItem = 'PROFILE_ITEM',
    FriendItem = 'FRIEND_ITEM',
    GroupMostActiveProfileItem = 'GROUP_MOST_ACTIVE_PROFILE_ITEM',
    SearchEngineVideosItem = 'SEARCH_ENGINE_VIDEOS_ITEM',
    GroupAdminProfileItem = 'GROUP_ADMIN_PROFILE_ITEM',
    SearchEngineImagesItem = 'SEARCH_ENGINE_IMAGES_ITEM',
    PagePostItem = 'PAGE_POST_ITEM',
    GoogleMapsItem = 'GOOGLE_MAPS_ITEM',
    GroupMostLikedProfileItem = 'GROUP_MOST_LIKED_PROFILE_ITEM',
    PageMostActiveProfileItem = 'PAGE_MOST_ACTIVE_PROFILE_ITEM',
    PageMostLikedProfileItem = 'PAGE_MOST_LIKED_PROFILE_ITEM',
    GroupPostItem = 'GROUP_POST_ITEM',
    GroupAlbumItem = 'GROUP_ALBUM_ITEM',
    PageProfileItem = 'PAGE_PROFILE_ITEM',
    ProfileAlbumItem = 'PROFILE_ALBUM_ITEM',
    PageMostCommentedProfileItem = 'PAGE_MOST_COMMENTED_PROFILE_ITEM',
    StolenCarItem = 'STOLEN_CAR_ITEM',
    AvatarValidatorItem = 'AVATAR_VALIDATOR_ITEM',
    GroupModeratorProfileItem = 'GROUP_MODERATOR_PROFILE_ITEM',
    GroupMembersItem = 'GROUP_MEMBERS_ITEM',
    GroupBannedItem = 'GROUP_BANNED_ITEM',
    GroupCreatorItem = 'GROUP_CREATOR_ITEM',
    GroupLeftItem = 'GROUP_LEFT_ITEM',
    GroupRestrictedItem = 'GROUP_RESTRICTED_ITEM',
    GroupUnknownItem = 'GROUP_UNKNOWN_ITEM',
    HydraProfileItem = 'HYDRA_PROFILE_ITEM',
    Sniper = 'SNIPER'
}

/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Image
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Image
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Image
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface ImageSearchResultDTO
 */
export interface ImageSearchResultDTO {
    /**
     * 
     * @type {number}
     * @memberof ImageSearchResultDTO
     */
    'id'?: number;
    /**
     * 
     * @type {MMImageResponseDTO}
     * @memberof ImageSearchResultDTO
     */
    'mmData'?: MMImageResponseDTO;
    /**
     * 
     * @type {FindCloneResponseData}
     * @memberof ImageSearchResultDTO
     */
    'findCloneData'?: FindCloneResponseData;
    /**
     * 
     * @type {Search4FaceDTO}
     * @memberof ImageSearchResultDTO
     */
    'search4Face'?: Search4FaceDTO;
    /**
     * 
     * @type {Array<SniperItemDTO>}
     * @memberof ImageSearchResultDTO
     */
    'sniper'?: Array<SniperItemDTO>;
    /**
     * 
     * @type {object}
     * @memberof ImageSearchResultDTO
     */
    'profileDTO'?: object;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    'image'?: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'data'?: string;
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    'image'?: any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'data'?: string;
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    'image'?: any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    'data'?: string;
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    'image'?: any;
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    'userImage'?: any;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject4
     */
    'file'?: any;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject5
     */
    'file'?: any;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    'gPoint'?: string;
}
/**
 * 
 * @export
 * @interface Interaction
 */
export interface Interaction {
    /**
     * 
     * @type {number}
     * @memberof Interaction
     */
    'resonanceScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'originalPostUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'socialNetwork'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'referenceUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'referenceContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'referenceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'postUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'referenceUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'referenceUserImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Interaction
     */
    'customrequestid'?: string;
    /**
     * 
     * @type {number}
     * @memberof Interaction
     */
    'postLikeCount'?: number;
}
/**
 * 
 * @export
 * @interface InteractionGiven
 */
export interface InteractionGiven {
    /**
     * 
     * @type {string}
     * @memberof InteractionGiven
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractionGiven
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof InteractionGiven
     */
    'likesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractionGiven
     */
    'commentsCount'?: number;
}
/**
 * 
 * @export
 * @interface Investigation
 */
export interface Investigation {
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Investigation
     */
    'invNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'invName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Investigation
     */
    'status'?: InvestigationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InvestigationStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface InvestigationDTO
 */
export interface InvestigationDTO {
    /**
     * 
     * @type {string}
     * @memberof InvestigationDTO
     */
    'invName'?: string;
}
/**
 * 
 * @export
 * @interface InvestigationInfoDTO
 */
export interface InvestigationInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof InvestigationInfoDTO
     */
    'invNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'invName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestigationInfoDTO
     */
    'status'?: InvestigationInfoDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InvestigationInfoDTO
     */
    'casesCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvestigationInfoDTO
     */
    'isArchived'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum InvestigationInfoDTOStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface InvestigationResponseDTO
 */
export interface InvestigationResponseDTO {
    /**
     * 
     * @type {Investigation}
     * @memberof InvestigationResponseDTO
     */
    'investigation'?: Investigation;
    /**
     * 
     * @type {string}
     * @memberof InvestigationResponseDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface InvestigationTreeNodeDTO
 */
export interface InvestigationTreeNodeDTO {
    /**
     * 
     * @type {UserInfoDTO}
     * @memberof InvestigationTreeNodeDTO
     */
    'userInfo'?: UserInfoDTO;
    /**
     * 
     * @type {Array<InvestigationInfoDTO>}
     * @memberof InvestigationTreeNodeDTO
     */
    'invList'?: Array<InvestigationInfoDTO>;
}
/**
 * 
 * @export
 * @interface InvestigationUserNameReq
 */
export interface InvestigationUserNameReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof InvestigationUserNameReq
     */
    'invIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof InvestigationUserNameReq
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'industry'?: string;
    /**
     * 
     * @type {DateRange}
     * @memberof Job
     */
    'dateRange'?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'display'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface JwtAuthenticationDto
 */
export interface JwtAuthenticationDto {
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationDto
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationDto
     */
    'tokenType'?: string;
}
/**
 * 
 * @export
 * @interface KeywordDTO
 */
export interface KeywordDTO {
    /**
     * 
     * @type {string}
     * @memberof KeywordDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeywordDTO
     */
    'keywordJoin'?: KeywordDTOKeywordJoinEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum KeywordDTOKeywordJoinEnum {
    Or = 'OR',
    And = 'AND'
}

/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'display'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface LanguageDTO
 */
export interface LanguageDTO {
    /**
     * 
     * @type {string}
     * @memberof LanguageDTO
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDTO
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDTO
     */
    'dialect'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDTO
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'hreflang'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'deprecation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    'templated'?: boolean;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface LocationDTO
 */
export interface LocationDTO {
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LookupRequestItemStat
 */
export interface LookupRequestItemStat {
    /**
     * 
     * @type {number}
     * @memberof LookupRequestItemStat
     */
    'lookupRequestItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LookupRequestItemStat
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupRequestItemStat
     */
    'status'?: LookupRequestItemStatStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LookupRequestItemStat
     */
    'createdDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LookupRequestItemStatStatusEnum {
    Started = 'STARTED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Schedule = 'SCHEDULE',
    Added = 'ADDED',
    Failed = 'FAILED',
    TimeoutError = 'TIMEOUT_ERROR'
}

/**
 * 
 * @export
 * @interface LookupResponseDTO
 */
export interface LookupResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LookupResponseDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupResponseDTO
     */
    'status'?: LookupResponseDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LookupResponseDTOStatusEnum {
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface LookupResult
 */
export interface LookupResult {
    /**
     * 
     * @type {Set<string>}
     * @memberof LookupResult
     */
    'names'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof LookupResult
     */
    'phones'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof LookupResult
     */
    'images'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof LookupResult
     */
    'operator'?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupResult
     */
    'lastOnline'?: string;
}
/**
 * 
 * @export
 * @interface MMImageResponseDTO
 */
export interface MMImageResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MMImageResponseDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MMImageResponseDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof MMImageResponseDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof MMImageResponseDTO
     */
    'faces'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MMImageResponseDTO
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MMImageResponseDTO
     */
    'names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MMImageResponseDTO
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MMImageResponseDTO
     */
    'sources'?: Array<MMImageResponseDTOSourcesEnum>;
    /**
     * 
     * @type {Array<SocialNetworkWrapper>}
     * @memberof MMImageResponseDTO
     */
    'socialNetworks'?: Array<SocialNetworkWrapper>;
    /**
     * 
     * @type {string}
     * @memberof MMImageResponseDTO
     */
    'imsi'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MMImageResponseDTO
     */
    'mappedImages'?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum MMImageResponseDTOSourcesEnum {
    Tc = 'TC',
    Whatsapp = 'WHATSAPP',
    Syncme = 'SYNCME',
    Viber = 'VIBER',
    Line = 'LINE',
    Wechat = 'WECHAT',
    Imo = 'IMO',
    Getcontact = 'GETCONTACT',
    Telegram = 'TELEGRAM',
    Skype = 'SKYPE',
    Vkontakte = 'VKONTAKTE',
    Eyecon = 'EYECON',
    Callapp = 'CALLAPP',
    Tamtam = 'TAMTAM',
    Zalo = 'ZALO',
    Drupe = 'DRUPE',
    Hiya = 'HIYA',
    Deepweb = 'DEEPWEB',
    Hydra = 'HYDRA',
    Showcaller = 'SHOWCALLER',
    PiplPerson = 'PIPL_PERSON',
    PiplPossible = 'PIPL_POSSIBLE',
    PiplSource = 'PIPL_SOURCE',
    Swarmapp = 'SWARMAPP',
    Crawler = 'CRAWLER',
    Manual = 'MANUAL',
    MmImage = 'MM_IMAGE',
    ProfileImage = 'PROFILE_IMAGE',
    FindCloneImage = 'FIND_CLONE_IMAGE',
    Search4FaceImage = 'SEARCH_4_FACE_IMAGE',
    SniperImage = 'SNIPER_IMAGE',
    PsychoProfile = 'PSYCHO_PROFILE',
    Pipl = 'PIPL',
    Botim = 'BOTIM',
    Numbuster = 'NUMBUSTER',
    Me = 'ME'
}

/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MetaAvatarDTO
 */
export interface MetaAvatarDTO {
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'id'?: string;
    /**
     * 
     * @type {Email}
     * @memberof MetaAvatarDTO
     */
    'email'?: Email;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'username'?: string;
    /**
     * 
     * @type {ProxyServer}
     * @memberof MetaAvatarDTO
     */
    'proxy'?: ProxyServer;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'gender'?: MetaAvatarDTOGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'base64Photo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'details'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetaAvatarDTO
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetaAvatarDTO
     */
    'base64Photos'?: Array<string>;
    /**
     * 
     * @type {Array<AvatarDto>}
     * @memberof MetaAvatarDTO
     */
    'socialNetworks'?: Array<AvatarDto>;
    /**
     * 
     * @type {number}
     * @memberof MetaAvatarDTO
     */
    'metaAvatarID'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarDTO
     */
    'bday'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MetaAvatarDTOGenderEnum {
    Male = 'MALE',
    Female = 'FEMALE',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface MetaAvatarFilter
 */
export interface MetaAvatarFilter {
    /**
     * 
     * @type {number}
     * @memberof MetaAvatarFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaAvatarFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Filter}
     * @memberof MetaAvatarFilter
     */
    'filter'?: Filter;
}
/**
 * 
 * @export
 * @interface MetaAvatarResponse
 */
export interface MetaAvatarResponse {
    /**
     * 
     * @type {MetaAvatarDTO}
     * @memberof MetaAvatarResponse
     */
    'metaAvatar'?: MetaAvatarDTO;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarResponse
     */
    'status'?: MetaAvatarResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarResponse
     */
    'domain'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MetaAvatarResponseStatusEnum {
    Created = 'CREATED',
    AvatarAlreadyExists = 'AVATAR_ALREADY_EXISTS',
    Updated = 'UPDATED'
}

/**
 * 
 * @export
 * @interface MetaAvatarUserName
 */
export interface MetaAvatarUserName {
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarUserName
     */
    'metaAvatarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAvatarUserName
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface MonitorStat
 */
export interface MonitorStat {
    /**
     * 
     * @type {boolean}
     * @memberof MonitorStat
     */
    'monitor'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitorStat
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface MoreInfo
 */
export interface MoreInfo {
    /**
     * 
     * @type {string}
     * @memberof MoreInfo
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoreInfo
     */
    'bookingAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoreInfo
     */
    'pressContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoreInfo
     */
    'generalManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoreInfo
     */
    'biography'?: string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Name
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Name
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'middle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'last'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Name
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface OriginCountry
 */
export interface OriginCountry {
    /**
     * 
     * @type {string}
     * @memberof OriginCountry
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginCountry
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginCountry
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OriginCountry
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginCountry
     */
    'country'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginCountry
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface PageAlert
 */
export interface PageAlert {
    /**
     * 
     * @type {number}
     * @memberof PageAlert
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAlert
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAlert
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Alert>}
     * @memberof PageAlert
     */
    'content'?: Array<Alert>;
    /**
     * 
     * @type {number}
     * @memberof PageAlert
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAlert
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageAlert
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAlert
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAlert
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAlert
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAlert
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageAuditActionDTO
 */
export interface PageAuditActionDTO {
    /**
     * 
     * @type {number}
     * @memberof PageAuditActionDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAuditActionDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAuditActionDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<AuditActionDTO>}
     * @memberof PageAuditActionDTO
     */
    'content'?: Array<AuditActionDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageAuditActionDTO
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAuditActionDTO
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditActionDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAuditActionDTO
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditActionDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAuditActionDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAuditActionDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageData
 */
export interface PageData {
    /**
     * 
     * @type {string}
     * @memberof PageData
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageData
     */
    'followersCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageData
     */
    'likesCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageData
     */
    'pageId'?: string;
    /**
     * 
     * @type {MoreInfo}
     * @memberof PageData
     */
    'moreInfo'?: MoreInfo;
    /**
     * 
     * @type {ContactInfo}
     * @memberof PageData
     */
    'contactInfo'?: ContactInfo;
    /**
     * 
     * @type {TeamMembers}
     * @memberof PageData
     */
    'teamMembers'?: TeamMembers;
    /**
     * 
     * @type {Array<Video>}
     * @memberof PageData
     */
    'videos'?: Array<Video>;
}
/**
 * 
 * @export
 * @interface PageFilter
 */
export interface PageFilter {
    /**
     * 
     * @type {number}
     * @memberof PageFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFilter
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFilter
     */
    'startTimestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFilter
     */
    'endTimestamp'?: number;
}
/**
 * 
 * @export
 * @interface PageProfileInfo
 */
export interface PageProfileInfo {
    /**
     * 
     * @type {number}
     * @memberof PageProfileInfo
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProfileInfo
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProfileInfo
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ProfileInfo>}
     * @memberof PageProfileInfo
     */
    'content'?: Array<ProfileInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageProfileInfo
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProfileInfo
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageProfileInfo
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProfileInfo
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageProfileInfo
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProfileInfo
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageProfileInfo
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProxyServer
 */
export interface PageProxyServer {
    /**
     * 
     * @type {number}
     * @memberof PageProxyServer
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProxyServer
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProxyServer
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ProxyServer>}
     * @memberof PageProxyServer
     */
    'content'?: Array<ProxyServer>;
    /**
     * 
     * @type {number}
     * @memberof PageProxyServer
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProxyServer
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageProxyServer
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProxyServer
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageProxyServer
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProxyServer
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageProxyServer
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageUser
 */
export interface PageUser {
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'size'?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof PageUser
     */
    'content'?: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUser
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageUser
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageWebintDTO
 */
export interface PageWebintDTO {
    /**
     * 
     * @type {number}
     * @memberof PageWebintDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWebintDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWebintDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<WebintDTO>}
     * @memberof PageWebintDTO
     */
    'content'?: Array<WebintDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageWebintDTO
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageWebintDTO
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageWebintDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageWebintDTO
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageWebintDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageWebintDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageWebintDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PasswordBody
 */
export interface PasswordBody {
    /**
     * 
     * @type {string}
     * @memberof PasswordBody
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {Array<Name>}
     * @memberof Person
     */
    'names'?: Array<Name>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Person
     */
    'addresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Phone>}
     * @memberof Person
     */
    'phones'?: Array<Phone>;
    /**
     * 
     * @type {Array<Email>}
     * @memberof Person
     */
    'emails'?: Array<Email>;
    /**
     * 
     * @type {Array<Job>}
     * @memberof Person
     */
    'jobs'?: Array<Job>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof Person
     */
    'educations'?: Array<Education>;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Person
     */
    'images'?: Array<Image>;
    /**
     * 
     * @type {Array<Username>}
     * @memberof Person
     */
    'usernames'?: Array<Username>;
    /**
     * 
     * @type {Array<UserID>}
     * @memberof Person
     */
    'userIds'?: Array<UserID>;
    /**
     * 
     * @type {Array<Language>}
     * @memberof Person
     */
    'languages'?: Array<Language>;
    /**
     * 
     * @type {Array<Ethnicity>}
     * @memberof Person
     */
    'ethnicities'?: Array<Ethnicity>;
    /**
     * 
     * @type {Array<OriginCountry>}
     * @memberof Person
     */
    'originCountries'?: Array<OriginCountry>;
    /**
     * 
     * @type {Array<Url>}
     * @memberof Person
     */
    'urls'?: Array<Url>;
    /**
     * 
     * @type {Array<Relationship>}
     * @memberof Person
     */
    'relationships'?: Array<Relationship>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Person
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {DOB}
     * @memberof Person
     */
    'dob'?: DOB;
    /**
     * 
     * @type {Gender}
     * @memberof Person
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    'match'?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'searchPointer'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface PersonalInfoDTO
 */
export interface PersonalInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof PersonalInfoDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfoDTO
     */
    'carrier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfoDTO
     */
    'imsi'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalInfoDTO
     */
    'countryCode'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof PersonalInfoDTO
     */
    'phones'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof PersonalInfoDTO
     */
    'passwords'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof PersonalInfoDTO
     */
    'hydraTelegramIds'?: Set<string>;
    /**
     * 
     * @type {Set<DataWithSource>}
     * @memberof PersonalInfoDTO
     */
    'emails'?: Set<DataWithSource>;
    /**
     * 
     * @type {Array<SourceInfoDTO>}
     * @memberof PersonalInfoDTO
     */
    'genders'?: Array<SourceInfoDTO>;
    /**
     * 
     * @type {Array<DataWithSource>}
     * @memberof PersonalInfoDTO
     */
    'addresses'?: Array<DataWithSource>;
    /**
     * 
     * @type {Array<DataWithSources>}
     * @memberof PersonalInfoDTO
     */
    'networks'?: Array<DataWithSources>;
    /**
     * 
     * @type {Array<SourceInfoDTO>}
     * @memberof PersonalInfoDTO
     */
    'sourceInfo'?: Array<SourceInfoDTO>;
    /**
     * 
     * @type {Array<DateWithSourceAndSN>}
     * @memberof PersonalInfoDTO
     */
    'lastOnlineStatuses'?: Array<DateWithSourceAndSN>;
    /**
     * 
     * @type {Array<DataWithSourceAndSN>}
     * @memberof PersonalInfoDTO
     */
    'names'?: Array<DataWithSourceAndSN>;
}
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Phone
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Phone
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Phone
     */
    'countryCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof Phone
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof Phone
     */
    'extension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'displayInternational'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Phone
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface PhotoDTO
 */
export interface PhotoDTO {
    /**
     * 
     * @type {string}
     * @memberof PhotoDTO
     */
    'source'?: PhotoDTOSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PhotoDTO
     */
    'imageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoDTO
     */
    'socialId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoDTO
     */
    'socialNetwork'?: PhotoDTOSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof PhotoDTO
     */
    'socialNetworkId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PhotoDTOSourceEnum {
    Tc = 'TC',
    Whatsapp = 'WHATSAPP',
    Syncme = 'SYNCME',
    Viber = 'VIBER',
    Line = 'LINE',
    Wechat = 'WECHAT',
    Imo = 'IMO',
    Getcontact = 'GETCONTACT',
    Telegram = 'TELEGRAM',
    Skype = 'SKYPE',
    Vkontakte = 'VKONTAKTE',
    Eyecon = 'EYECON',
    Callapp = 'CALLAPP',
    Tamtam = 'TAMTAM',
    Zalo = 'ZALO',
    Drupe = 'DRUPE',
    Hiya = 'HIYA',
    Deepweb = 'DEEPWEB',
    Hydra = 'HYDRA',
    Showcaller = 'SHOWCALLER',
    PiplPerson = 'PIPL_PERSON',
    PiplPossible = 'PIPL_POSSIBLE',
    PiplSource = 'PIPL_SOURCE',
    Swarmapp = 'SWARMAPP',
    Crawler = 'CRAWLER',
    Manual = 'MANUAL',
    MmImage = 'MM_IMAGE',
    ProfileImage = 'PROFILE_IMAGE',
    FindCloneImage = 'FIND_CLONE_IMAGE',
    Search4FaceImage = 'SEARCH_4_FACE_IMAGE',
    SniperImage = 'SNIPER_IMAGE',
    PsychoProfile = 'PSYCHO_PROFILE',
    Pipl = 'PIPL',
    Botim = 'BOTIM',
    Numbuster = 'NUMBUSTER',
    Me = 'ME'
}
/**
    * @export
    * @enum {string}
    */
export enum PhotoDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface PostAnalyze
 */
export interface PostAnalyze {
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'sentiment'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'entity'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostAnalyze
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'errorReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'inProgress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAnalyze
     */
    'failedResponse'?: string;
}
/**
 * 
 * @export
 * @interface PostData
 */
export interface PostData {
    /**
     * 
     * @type {number}
     * @memberof PostData
     */
    'numbersPost'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostData
     */
    'sentiment'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostData
     */
    'percent'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostData
     */
    'topic'?: string;
}
/**
 * 
 * @export
 * @interface PostFilterDTO
 */
export interface PostFilterDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof PostFilterDTO
     */
    'socialNetworks'?: Array<PostFilterDTOSocialNetworksEnum>;
    /**
     * 
     * @type {string}
     * @memberof PostFilterDTO
     */
    'text'?: string;
    /**
     * 
     * @type {PostSort}
     * @memberof PostFilterDTO
     */
    'sort'?: PostSort;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'batch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'publishedDateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'publishedDateTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'collectedDateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFilterDTO
     */
    'collectedDateTo'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PostFilterDTOSocialNetworksEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface PostGenerateRequest
 */
export interface PostGenerateRequest {
    /**
     * 
     * @type {string}
     * @memberof PostGenerateRequest
     */
    'lang'?: string;
    /**
     * 
     * @type {Array<PostData>}
     * @memberof PostGenerateRequest
     */
    'postsData'?: Array<PostData>;
    /**
     * 
     * @type {number}
     * @memberof PostGenerateRequest
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostGenerateRequest
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostGenerateRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostGenerateRequest
     */
    'openAIModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostGenerateRequest
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostGenerateRequest
     */
    'avatarId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostGenerateRequest
     */
    'requestTimeStamp'?: number;
}
/**
 * 
 * @export
 * @interface PostLocationDTO
 */
export interface PostLocationDTO {
    /**
     * 
     * @type {string}
     * @memberof PostLocationDTO
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostLocationDTO
     */
    'postUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostLocationDTO
     */
    'socialNetwork'?: PostLocationDTOSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PostLocationDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface PostPsychoFilterDTO
 */
export interface PostPsychoFilterDTO {
    /**
     * 
     * @type {number}
     * @memberof PostPsychoFilterDTO
     */
    'publishedDateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostPsychoFilterDTO
     */
    'publishedDateTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostPsychoFilterDTO
     */
    'collectedDateFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostPsychoFilterDTO
     */
    'collectedDateTo'?: number;
}
/**
 * 
 * @export
 * @interface PostSort
 */
export interface PostSort {
    /**
     * 
     * @type {string}
     * @memberof PostSort
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSort
     */
    'direction'?: string;
}
/**
 * 
 * @export
 * @interface ProfileActivityStatDTO
 */
export interface ProfileActivityStatDTO {
    /**
     * 
     * @type {number}
     * @memberof ProfileActivityStatDTO
     */
    'likes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileActivityStatDTO
     */
    'friends'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileActivityStatDTO
     */
    'mentions'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileActivityStatDTO
     */
    'posts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileActivityStatDTO
     */
    'comments'?: number;
}
/**
 * 
 * @export
 * @interface ProfileContactInfoDTO
 */
export interface ProfileContactInfoDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileContactInfoDTO
     */
    'professionalCategory'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'relationship2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'heading'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'basicInfo'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'contactInfo'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<Education>}
     * @memberof ProfileContactInfoDTO
     */
    'education'?: Array<Education>;
    /**
     * 
     * @type {Array<FamilyMember>}
     * @memberof ProfileContactInfoDTO
     */
    'familyMembers'?: Array<FamilyMember>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'introduction'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'lifeEvents'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'otherNames'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'placesLived'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'relationship'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'tvShows'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'events'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<Work>}
     * @memberof ProfileContactInfoDTO
     */
    'work'?: Array<Work>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'socialNetwork'?: ProfileContactInfoDTOSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileHeadline'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileContactInfoDTO
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'highlights'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'descriptions'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'reviews'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'movies'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'following'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'sports'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'checkIns'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'books'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {number}
     * @memberof ProfileContactInfoDTO
     */
    'followingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileContactInfoDTO
     */
    'followersCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileContactInfoDTO
     */
    'likesCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileContactInfoDTO
     */
    'internalGroupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileCreatedAt'?: string;
    /**
     * 
     * @type {object}
     * @memberof ProfileContactInfoDTO
     */
    'career'?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'contact_information'?: { [key: string]: object; };
    /**
     * 
     * @type {object}
     * @memberof ProfileContactInfoDTO
     */
    'o_education'?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'beliefs'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'personal_info'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'counters'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'achievements'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'url'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'holidays'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileContactInfoDTO
     */
    'forums'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'birth_place'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'marital_status'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'family'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'information'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'organizations'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'certifications'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'language'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profile_summary'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'experience'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'project'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'courses'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'awards'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'test_score'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'connections'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'volunteer_experience'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'favorite_quotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileAge'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'hometown'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileContactInfoDTO
     */
    'profileBirthday'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'address'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileContactInfoDTO
     */
    'army'?: Array<{ [key: string]: object; }>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileContactInfoDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface ProfileData
 */
export interface ProfileData {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileData
     */
    'professionalCategory'?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'stories'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {StatsLastPosts}
     * @memberof ProfileData
     */
    'statsLastPosts'?: StatsLastPosts;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'heading'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'profileHeadline'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileData
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'tvShows'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'contactInfo'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'placesLived'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'music'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof ProfileData
     */
    'education'?: Array<Education>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'events'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'introduction'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'otherNames'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'groups'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<FamilyMember>}
     * @memberof ProfileData
     */
    'familyMembers'?: Array<FamilyMember>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'relationship'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'notes'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'lifeEvents'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'reviews'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'movies'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'games'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'following'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {object}
     * @memberof ProfileData
     */
    'basicInfo'?: object;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'sports'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'videos'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<Work>}
     * @memberof ProfileData
     */
    'work'?: Array<Work>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'checkIns'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'books'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'highlights'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'descriptions'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'favouritesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'followingCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'profileCreatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'followersCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'likesCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'profileDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'statusesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'listedCount'?: number;
    /**
     * 
     * @type {Array<ProfileDataList>}
     * @memberof ProfileData
     */
    'lists'?: Array<ProfileDataList>;
    /**
     * 
     * @type {Array<ProfileDataItem>}
     * @memberof ProfileData
     */
    'likes'?: Array<ProfileDataItem>;
    /**
     * 
     * @type {number}
     * @memberof ProfileData
     */
    'internalGroupId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'career'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'gifts'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'followers'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'contact_information'?: { [key: string]: object; };
    /**
     * 
     * @type {object}
     * @memberof ProfileData
     */
    'o_education'?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'beliefs'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'personal_info'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'counters'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'achievements'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'subscribers'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'url'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'holidays'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileData
     */
    'forums'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'birth_place'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'marital_status'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'family'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'organizations'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'certifications'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'language'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'profile_summary'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'experience'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'project'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'courses'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'awards'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'test_score'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'connections'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfileData
     */
    'volunteer_experience'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfileData
     */
    'favorite_quotes'?: string;
}
/**
 * 
 * @export
 * @interface ProfileDataItem
 */
export interface ProfileDataItem {
    /**
     * 
     * @type {string}
     * @memberof ProfileDataItem
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataItem
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataItem
     */
    'linkUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataItem
     */
    'verified'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataItem
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProfileDataList
 */
export interface ProfileDataList {
    /**
     * 
     * @type {string}
     * @memberof ProfileDataList
     */
    'listName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataList
     */
    'listUrl'?: string;
}
/**
 * 
 * @export
 * @interface ProfileGeneralStatDTO
 */
export interface ProfileGeneralStatDTO {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProfileGeneralStatDTO
     */
    'sentiment'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProfileGeneralStatDTO
     */
    'socialNetwork'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProfileGeneralStatDTO
     */
    'post'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ProfileInfo
 */
export interface ProfileInfo {
    /**
     * 
     * @type {number}
     * @memberof ProfileInfo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileInfo
     */
    'createdDate'?: number;
    /**
     * 
     * @type {Set<string>}
     * @memberof ProfileInfo
     */
    'statuses'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ProfileInfo
     */
    'errors'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileInfo
     */
    'sources'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    'photo'?: string;
    /**
     * 
     * @type {Array<SocialProfileInfo>}
     * @memberof ProfileInfo
     */
    'socialNetworks'?: Array<SocialProfileInfo>;
    /**
     * 
     * @type {number}
     * @memberof ProfileInfo
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface ProfileLocationStatDTO
 */
export interface ProfileLocationStatDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileLocationStatDTO
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileLocationStatDTO
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileLocationStatDTO
     */
    'city'?: string;
    /**
     * 
     * @type {Array<PostLocationDTO>}
     * @memberof ProfileLocationStatDTO
     */
    'postLocations'?: Array<PostLocationDTO>;
}
/**
 * 
 * @export
 * @interface ProfileModificationDTO
 */
export interface ProfileModificationDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileModificationDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModificationDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModificationDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModificationDTO
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface ProfileNode
 */
export interface ProfileNode extends Node {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNode
     */
    'cases'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNode
     */
    'investigations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNode
     */
    'allNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNode
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileNode
     */
    'phones'?: string;
}
/**
 * 
 * @export
 * @interface ProfileNodeAllOf
 */
export interface ProfileNodeAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNodeAllOf
     */
    'cases'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNodeAllOf
     */
    'investigations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNodeAllOf
     */
    'allNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileNodeAllOf
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileNodeAllOf
     */
    'phones'?: string;
}
/**
 * 
 * @export
 * @interface ProfilePreferenceDTO
 */
export interface ProfilePreferenceDTO {
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'tvShows'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'books'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'checkIns'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'events'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'following'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'games'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'groups'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'gifts'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'likes'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'movies'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'music'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'musics'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'notes'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'reviews'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'sports'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'videos'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof ProfilePreferenceDTO
     */
    'socialNetwork'?: ProfilePreferenceDTOSocialNetworkEnum;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'highlights'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'followers'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ProfilePreferenceDTO
     */
    'subscribers'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<ProfileDataList>}
     * @memberof ProfilePreferenceDTO
     */
    'lists'?: Array<ProfileDataList>;
    /**
     * 
     * @type {string}
     * @memberof ProfilePreferenceDTO
     */
    'profileId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfilePreferenceDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface ProfileSearchProgressDTO
 */
export interface ProfileSearchProgressDTO {
    /**
     * 
     * @type {Set<string>}
     * @memberof ProfileSearchProgressDTO
     */
    'sources'?: Set<ProfileSearchProgressDTOSourcesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchProgressDTO
     */
    'lastLookupDate'?: string;
    /**
     * 
     * @type {Set<CrawlerProgressDTO>}
     * @memberof ProfileSearchProgressDTO
     */
    'nameCrawlers'?: Set<CrawlerProgressDTO>;
    /**
     * 
     * @type {Set<CrawlerProgressDTO>}
     * @memberof ProfileSearchProgressDTO
     */
    'passwordCrawlers'?: Set<CrawlerProgressDTO>;
    /**
     * 
     * @type {Set<CrawlerProgressDTO>}
     * @memberof ProfileSearchProgressDTO
     */
    'lookups'?: Set<CrawlerProgressDTO>;
    /**
     * 
     * @type {Set<Name>}
     * @memberof ProfileSearchProgressDTO
     */
    'names'?: Set<Name>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ProfileSearchProgressDTO
     */
    'passwords'?: Set<string>;
    /**
     * 
     * @type {Set<CrawlerProgressDTO>}
     * @memberof ProfileSearchProgressDTO
     */
    'socialCrawlers'?: Set<CrawlerProgressDTO>;
    /**
     * 
     * @type {Set<CrawlerSearchResultDTO>}
     * @memberof ProfileSearchProgressDTO
     */
    'searchResults'?: Set<CrawlerSearchResultDTO>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileSearchProgressDTOSourcesEnum {
    Tc = 'TC',
    Whatsapp = 'WHATSAPP',
    Syncme = 'SYNCME',
    Viber = 'VIBER',
    Line = 'LINE',
    Wechat = 'WECHAT',
    Imo = 'IMO',
    Getcontact = 'GETCONTACT',
    Telegram = 'TELEGRAM',
    Skype = 'SKYPE',
    Vkontakte = 'VKONTAKTE',
    Eyecon = 'EYECON',
    Callapp = 'CALLAPP',
    Tamtam = 'TAMTAM',
    Zalo = 'ZALO',
    Drupe = 'DRUPE',
    Hiya = 'HIYA',
    Deepweb = 'DEEPWEB',
    Hydra = 'HYDRA',
    Showcaller = 'SHOWCALLER',
    PiplPerson = 'PIPL_PERSON',
    PiplPossible = 'PIPL_POSSIBLE',
    PiplSource = 'PIPL_SOURCE',
    Swarmapp = 'SWARMAPP',
    Crawler = 'CRAWLER',
    Manual = 'MANUAL',
    MmImage = 'MM_IMAGE',
    ProfileImage = 'PROFILE_IMAGE',
    FindCloneImage = 'FIND_CLONE_IMAGE',
    Search4FaceImage = 'SEARCH_4_FACE_IMAGE',
    SniperImage = 'SNIPER_IMAGE',
    PsychoProfile = 'PSYCHO_PROFILE',
    Pipl = 'PIPL',
    Botim = 'BOTIM',
    Numbuster = 'NUMBUSTER',
    Me = 'ME'
}

/**
 * 
 * @export
 * @interface PromtAnalyzeRequest
 */
export interface PromtAnalyzeRequest {
    /**
     * 
     * @type {string}
     * @memberof PromtAnalyzeRequest
     */
    'requestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromtAnalyzeRequest
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromtAnalyzeRequest
     */
    'promt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromtAnalyzeRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromtAnalyzeRequest
     */
    'openAIModel'?: string;
}
/**
 * 
 * @export
 * @interface ProxyServer
 */
export interface ProxyServer {
    /**
     * 
     * @type {boolean}
     * @memberof ProxyServer
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'port'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServer
     */
    'proxyType'?: ProxyServerProxyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProxyServerProxyTypeEnum {
    Https = 'HTTPS',
    Socks5 = 'SOCKS5'
}

/**
 * 
 * @export
 * @interface ProxyServerDTO
 */
export interface ProxyServerDTO {
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProxyServerDTO
     */
    'proxyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'proxyName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProxyServerDTO
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'port'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyServerDTO
     */
    'proxyType'?: ProxyServerDTOProxyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProxyServerDTOProxyTypeEnum {
    Https = 'HTTPS',
    Socks5 = 'SOCKS5'
}

/**
 * 
 * @export
 * @interface QuarantineInfo
 */
export interface QuarantineInfo {
    /**
     * 
     * @type {number}
     * @memberof QuarantineInfo
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuarantineInfo
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuarantineInfo
     */
    'numberOfUses'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuarantineInfo
     */
    'currentCounterOfUses'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuarantineInfo
     */
    'daysInQuarantine'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuarantineInfo
     */
    'startedQuarantine'?: string;
}
/**
 * 
 * @export
 * @interface QuarantineModeDTO
 */
export interface QuarantineModeDTO {
    /**
     * 
     * @type {string}
     * @memberof QuarantineModeDTO
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuarantineModeDTO
     */
    'useQuarantine'?: boolean;
}
/**
 * 
 * @export
 * @interface Quota
 */
export interface Quota {
    /**
     * 
     * @type {boolean}
     * @memberof Quota
     */
    'isDaily'?: boolean;
    /**
     * 
     * @type {QuotaModule}
     * @memberof Quota
     */
    'quotaProfiler'?: QuotaModule;
    /**
     * 
     * @type {QuotaModule}
     * @memberof Quota
     */
    'quotaWebint'?: QuotaModule;
    /**
     * 
     * @type {number}
     * @memberof Quota
     */
    'dailyQuota'?: number;
}
/**
 * 
 * @export
 * @interface QuotaEntity
 */
export interface QuotaEntity {
    /**
     * 
     * @type {number}
     * @memberof QuotaEntity
     */
    'usedQuota'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotaEntity
     */
    'maxQuota'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotaEntity
     */
    'maxDailyQuota'?: number;
}
/**
 * 
 * @export
 * @interface QuotaModule
 */
export interface QuotaModule {
    /**
     * 
     * @type {QuotaEntity}
     * @memberof QuotaModule
     */
    'lookup'?: QuotaEntity;
    /**
     * 
     * @type {QuotaEntity}
     * @memberof QuotaModule
     */
    'crawler'?: QuotaEntity;
    /**
     * 
     * @type {QuotaEntity}
     * @memberof QuotaModule
     */
    'cases'?: QuotaEntity;
    /**
     * 
     * @type {QuotaEntity}
     * @memberof QuotaModule
     */
    'periodic'?: QuotaEntity;
    /**
     * 
     * @type {QuotaEntity}
     * @memberof QuotaModule
     */
    'monitoring'?: QuotaEntity;
    /**
     * 
     * @type {string}
     * @memberof QuotaModule
     */
    'expireBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaModule
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaModule
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuotaModule
     */
    'isForever'?: boolean;
}
/**
 * 
 * @export
 * @interface RecentRepliers
 */
export interface RecentRepliers {
    /**
     * 
     * @type {string}
     * @memberof RecentRepliers
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface RekognitionDetectRequest
 */
export interface RekognitionDetectRequest {
    /**
     * 
     * @type {string}
     * @memberof RekognitionDetectRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionDetectRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionDetectRequest
     */
    'collectionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RekognitionDetectRequest
     */
    'profileId'?: number;
}
/**
 * 
 * @export
 * @interface RekognitionImageEnroll
 */
export interface RekognitionImageEnroll {
    /**
     * 
     * @type {string}
     * @memberof RekognitionImageEnroll
     */
    'imageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionImageEnroll
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionImageEnroll
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionImageEnroll
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RekognitionImageEnroll
     */
    'collectionId'?: string;
}
/**
 * 
 * @export
 * @interface Relationship
 */
export interface Relationship {
    /**
     * 
     * @type {Array<Name>}
     * @memberof Relationship
     */
    'names'?: Array<Name>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Relationship
     */
    'addresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Phone>}
     * @memberof Relationship
     */
    'phones'?: Array<Phone>;
    /**
     * 
     * @type {Array<Email>}
     * @memberof Relationship
     */
    'emails'?: Array<Email>;
    /**
     * 
     * @type {Array<Job>}
     * @memberof Relationship
     */
    'jobs'?: Array<Job>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof Relationship
     */
    'educations'?: Array<Education>;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Relationship
     */
    'images'?: Array<Image>;
    /**
     * 
     * @type {Array<Username>}
     * @memberof Relationship
     */
    'usernames'?: Array<Username>;
    /**
     * 
     * @type {Array<UserID>}
     * @memberof Relationship
     */
    'userIds'?: Array<UserID>;
    /**
     * 
     * @type {Array<Language>}
     * @memberof Relationship
     */
    'languages'?: Array<Language>;
    /**
     * 
     * @type {Array<Ethnicity>}
     * @memberof Relationship
     */
    'ethnicities'?: Array<Ethnicity>;
    /**
     * 
     * @type {Array<OriginCountry>}
     * @memberof Relationship
     */
    'originCountries'?: Array<OriginCountry>;
    /**
     * 
     * @type {Array<Url>}
     * @memberof Relationship
     */
    'urls'?: Array<Url>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Relationship
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {DOB}
     * @memberof Relationship
     */
    'dob'?: DOB;
    /**
     * 
     * @type {Gender}
     * @memberof Relationship
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof Relationship
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Relationship
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Relationship
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Relationship
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Relationship
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Relationship
     */
    'subtype'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Relationship
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface Replies
 */
export interface Replies {
    /**
     * 
     * @type {string}
     * @memberof Replies
     */
    'replies'?: string;
    /**
     * 
     * @type {string}
     * @memberof Replies
     */
    'repliesPts'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Replies
     */
    'isComments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Replies
     */
    'channelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Replies
     */
    'maxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Replies
     */
    'readMaxId'?: string;
    /**
     * 
     * @type {Array<RecentRepliers>}
     * @memberof Replies
     */
    'recentRepliers'?: Array<RecentRepliers>;
}
/**
 * 
 * @export
 * @interface RerunSearchCrawlerStatusDTO
 */
export interface RerunSearchCrawlerStatusDTO {
    /**
     * 
     * @type {number}
     * @memberof RerunSearchCrawlerStatusDTO
     */
    'profileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RerunSearchCrawlerStatusDTO
     */
    'lriId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RerunSearchCrawlerStatusDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof RerunSearchCrawlerStatusDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RerunSearchCrawlerStatusDTO
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ScheduleInfoDTO
 */
export interface ScheduleInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'activity'?: ScheduleInfoDTOActivityEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'base64photo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleInfoDTO
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'domain'?: ScheduleInfoDTODomainEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'login'?: string;
    /**
     * 
     * @type {AvatarDto}
     * @memberof ScheduleInfoDTO
     */
    'avatarDto'?: AvatarDto;
    /**
     * 
     * @type {string}
     * @memberof ScheduleInfoDTO
     */
    'postId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ScheduleInfoDTOActivityEnum {
    Autologin = 'AUTOLOGIN',
    Post = 'POST',
    LikePost = 'LIKE_POST',
    CommentPost = 'COMMENT_POST',
    Url = 'URL',
    GroupPost = 'GROUP_POST',
    UserPost = 'USER_POST'
}
/**
    * @export
    * @enum {string}
    */
export enum ScheduleInfoDTODomainEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface SchedulerSetting
 */
export interface SchedulerSetting {
    /**
     * 
     * @type {number}
     * @memberof SchedulerSetting
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof SchedulerSetting
     */
    'end'?: number;
    /**
     * 
     * @type {string}
     * @memberof SchedulerSetting
     */
    'collectPeriod'?: SchedulerSettingCollectPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof SchedulerSetting
     */
    'lastMonitoringDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SchedulerSetting
     */
    'collectPeriodInHour'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SchedulerSettingCollectPeriodEnum {
    H1 = 'H1',
    H3 = 'H3',
    H5 = 'H5',
    D1 = 'D1'
}

/**
 * 
 * @export
 * @interface Search4FaceDTO
 */
export interface Search4FaceDTO {
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'requestId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'face'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'photo'?: string;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'photoX'?: number;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'photoY'?: number;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'photoWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'photoHeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof Search4FaceDTO
     */
    'age'?: number;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'messageError'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Search4FaceDTO
     */
    'isFailed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'statusError'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Search4FaceDTO
     */
    'maiden_name'?: string;
}
/**
 * 
 * @export
 * @interface SearchHistoryDTO
 */
export interface SearchHistoryDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchHistoryDTO
     */
    'timeStamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchHistoryDTO
     */
    'id'?: number;
    /**
     * 
     * @type {LookupResult}
     * @memberof SearchHistoryDTO
     */
    'lookupResult'?: LookupResult;
    /**
     * 
     * @type {Array<ImageSearchResultDTO>}
     * @memberof SearchHistoryDTO
     */
    'imageResult'?: Array<ImageSearchResultDTO>;
    /**
     * 
     * @type {Array<CrawlerResponseDTO>}
     * @memberof SearchHistoryDTO
     */
    'webResults'?: Array<CrawlerResponseDTO>;
    /**
     * 
     * @type {Array<CrawlerResponseDTO>}
     * @memberof SearchHistoryDTO
     */
    'crawlerResults'?: Array<CrawlerResponseDTO>;
}
/**
 * 
 * @export
 * @interface Sentiment
 */
export interface Sentiment {
    /**
     * 
     * @type {number}
     * @memberof Sentiment
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof Sentiment
     */
    'magnitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Sentiment
     */
    'sentiment'?: SentimentSentimentEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SentimentSentimentEnum {
    Negative = 'NEGATIVE',
    Positive = 'POSITIVE',
    Neutral = 'NEUTRAL'
}

/**
 * 
 * @export
 * @interface SentimentStat
 */
export interface SentimentStat {
    /**
     * 
     * @type {number}
     * @memberof SentimentStat
     */
    'negative'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentimentStat
     */
    'positive'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentimentStat
     */
    'neutral'?: number;
}
/**
 * 
 * @export
 * @interface SmsBody
 */
export interface SmsBody {
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'incoming_sms_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'port'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'imsi'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsBody
     */
    'smsc'?: string;
}
/**
 * 
 * @export
 * @interface SmsDTO
 */
export interface SmsDTO {
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'imsi'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'smsc'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'ccid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'msg_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'port_num'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'unicode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'card_add'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'simNum'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SmsDinstarDTO
 */
export interface SmsDinstarDTO {
    /**
     * 
     * @type {Array<SmsBody>}
     * @memberof SmsDinstarDTO
     */
    'sms'?: Array<SmsBody>;
    /**
     * 
     * @type {string}
     * @memberof SmsDinstarDTO
     */
    'sn'?: string;
}
/**
 * 
 * @export
 * @interface SniperItemDTO
 */
export interface SniperItemDTO {
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'profileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'customRequestId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'relatedLookupRequestItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'relatedLookupRequestId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'relatedLookupResponseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SniperItemDTO
     */
    'isProfileDataAdded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'indexDB'?: number;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'base64'?: string;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'guid'?: string;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof SniperItemDTO
     */
    'url'?: string;
    /**
     * 
     * @type {object}
     * @memberof SniperItemDTO
     */
    'seen'?: object;
    /**
     * 
     * @type {number}
     * @memberof SniperItemDTO
     */
    'group'?: number;
}
/**
 * 
 * @export
 * @interface SocialNetworkWrapper
 */
export interface SocialNetworkWrapper {
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkWrapper
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkWrapper
     */
    'socialNetwork'?: SocialNetworkWrapperSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SocialNetworkWrapperSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface SocialProfileData
 */
export interface SocialProfileData {
    /**
     * 
     * @type {string}
     * @memberof SocialProfileData
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialProfileData
     */
    'socialNetwork'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialProfileData
     */
    'avatarId'?: string;
}
/**
 * 
 * @export
 * @interface SocialProfileInfo
 */
export interface SocialProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof SocialProfileInfo
     */
    'socialNetwork'?: SocialProfileInfoSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialProfileInfo
     */
    'profile'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SocialProfileInfoSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface SocialUrl
 */
export interface SocialUrl {
    /**
     * 
     * @type {string}
     * @memberof SocialUrl
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialUrl
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {Array<Name>}
     * @memberof Source
     */
    'names'?: Array<Name>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Source
     */
    'addresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Phone>}
     * @memberof Source
     */
    'phones'?: Array<Phone>;
    /**
     * 
     * @type {Array<Email>}
     * @memberof Source
     */
    'emails'?: Array<Email>;
    /**
     * 
     * @type {Array<Job>}
     * @memberof Source
     */
    'jobs'?: Array<Job>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof Source
     */
    'educations'?: Array<Education>;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Source
     */
    'images'?: Array<Image>;
    /**
     * 
     * @type {Array<Username>}
     * @memberof Source
     */
    'usernames'?: Array<Username>;
    /**
     * 
     * @type {Array<UserID>}
     * @memberof Source
     */
    'userIds'?: Array<UserID>;
    /**
     * 
     * @type {Array<Language>}
     * @memberof Source
     */
    'languages'?: Array<Language>;
    /**
     * 
     * @type {Array<Ethnicity>}
     * @memberof Source
     */
    'ethnicities'?: Array<Ethnicity>;
    /**
     * 
     * @type {Array<OriginCountry>}
     * @memberof Source
     */
    'originCountries'?: Array<OriginCountry>;
    /**
     * 
     * @type {Array<Url>}
     * @memberof Source
     */
    'urls'?: Array<Url>;
    /**
     * 
     * @type {Array<Relationship>}
     * @memberof Source
     */
    'relationships'?: Array<Relationship>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Source
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {DOB}
     * @memberof Source
     */
    'dob'?: DOB;
    /**
     * 
     * @type {Gender}
     * @memberof Source
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'originUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'personId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    'match'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Source
     */
    'sponsored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Source
     */
    'premium'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'validSince'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Source
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface SourceInfoDTO
 */
export interface SourceInfoDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceInfoDTO
     */
    'names'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceInfoDTO
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'source'?: SourceInfoDTOSourceEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceInfoDTO
     */
    'socialNetworkIDs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'statusDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'lastOnlineTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceInfoDTO
     */
    'bio'?: string;
    /**
     * 
     * @type {Array<LocationDTO>}
     * @memberof SourceInfoDTO
     */
    'locationInfo'?: Array<LocationDTO>;
}

/**
    * @export
    * @enum {string}
    */
export enum SourceInfoDTOSourceEnum {
    Tc = 'TC',
    Whatsapp = 'WHATSAPP',
    Syncme = 'SYNCME',
    Viber = 'VIBER',
    Line = 'LINE',
    Wechat = 'WECHAT',
    Imo = 'IMO',
    Getcontact = 'GETCONTACT',
    Telegram = 'TELEGRAM',
    Skype = 'SKYPE',
    Vkontakte = 'VKONTAKTE',
    Eyecon = 'EYECON',
    Callapp = 'CALLAPP',
    Tamtam = 'TAMTAM',
    Zalo = 'ZALO',
    Drupe = 'DRUPE',
    Hiya = 'HIYA',
    Deepweb = 'DEEPWEB',
    Hydra = 'HYDRA',
    Showcaller = 'SHOWCALLER',
    PiplPerson = 'PIPL_PERSON',
    PiplPossible = 'PIPL_POSSIBLE',
    PiplSource = 'PIPL_SOURCE',
    Swarmapp = 'SWARMAPP',
    Crawler = 'CRAWLER',
    Manual = 'MANUAL',
    MmImage = 'MM_IMAGE',
    ProfileImage = 'PROFILE_IMAGE',
    FindCloneImage = 'FIND_CLONE_IMAGE',
    Search4FaceImage = 'SEARCH_4_FACE_IMAGE',
    SniperImage = 'SNIPER_IMAGE',
    PsychoProfile = 'PSYCHO_PROFILE',
    Pipl = 'PIPL',
    Botim = 'BOTIM',
    Numbuster = 'NUMBUSTER',
    Me = 'ME'
}

/**
 * 
 * @export
 * @interface SpeechSearchFilter
 */
export interface SpeechSearchFilter {
    /**
     * 
     * @type {string}
     * @memberof SpeechSearchFilter
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SpeechSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpeechSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpeechSearchFilter
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface StatsLastPosts
 */
export interface StatsLastPosts {
    /**
     * 
     * @type {number}
     * @memberof StatsLastPosts
     */
    'commentsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatsLastPosts
     */
    'likesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatsLastPosts
     */
    'averageCountOfReceivedLikesPerPost'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatsLastPosts
     */
    'averageCountOfReceivedCommentsPerPost'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatsLastPosts
     */
    'averageTimeBetweenPosts'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatsLastPosts
     */
    'followersPercentageWhoLikesThePublishedPosts'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatsLastPosts
     */
    'followersPercentageWhoWriteACommentForPosts'?: string;
}
/**
 * 
 * @export
 * @interface SynopsisDTO
 */
export interface SynopsisDTO {
    /**
     * 
     * @type {string}
     * @memberof SynopsisDTO
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'classification'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface TeamMembers
 */
export interface TeamMembers {
    /**
     * 
     * @type {string}
     * @memberof TeamMembers
     */
    'other'?: string;
}
/**
 * 
 * @export
 * @interface TelegramIntegration
 */
export interface TelegramIntegration {
    /**
     * 
     * @type {string}
     * @memberof TelegramIntegration
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramIntegration
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface Url
 */
export interface Url {
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Url
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Url
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'category'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Url
     */
    'sponsored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    'sourceId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Url
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {Set<string>}
     * @memberof User
     */
    'roles': Set<UserRolesEnum>;
    /**
     * 
     * @type {Quota}
     * @memberof User
     */
    'quota'?: Quota;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'webintIsActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'profilerIsActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'topPostsIsActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'invCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'createdDate'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRolesEnum {
    Admin = 'ADMIN',
    User = 'USER',
    System = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'userName'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserDetails
     */
    'roles'?: Set<UserDetailsRolesEnum>;
    /**
     * 
     * @type {Quota}
     * @memberof UserDetails
     */
    'quota'?: Quota;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    'webintIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    'profilerIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    'topPostsIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    'invCount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDetailsRolesEnum {
    Admin = 'ADMIN',
    User = 'USER',
    System = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface UserFilter
 */
export interface UserFilter {
    /**
     * 
     * @type {PageFilter}
     * @memberof UserFilter
     */
    'pageFilter'?: PageFilter;
    /**
     * 
     * @type {boolean}
     * @memberof UserFilter
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {UserSort}
     * @memberof UserFilter
     */
    'sort'?: UserSort;
    /**
     * 
     * @type {string}
     * @memberof UserFilter
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface UserID
 */
export interface UserID {
    /**
     * 
     * @type {string}
     * @memberof UserID
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserID
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserID
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserID
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserID
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserID
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'webintIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'profilerIsActive'?: boolean;
    /**
     * 
     * @type {Quota}
     * @memberof UserInfo
     */
    'quota'?: Quota;
}
/**
 * 
 * @export
 * @interface UserInfoDTO
 */
export interface UserInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoDTO
     */
    'roles'?: Array<UserInfoDTORolesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserInfoDTORolesEnum {
    Admin = 'ADMIN',
    User = 'USER',
    System = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface UserNameDTO
 */
export interface UserNameDTO {
    /**
     * 
     * @type {string}
     * @memberof UserNameDTO
     */
    'newName': string;
    /**
     * 
     * @type {string}
     * @memberof UserNameDTO
     */
    'oldName'?: string;
}
/**
 * 
 * @export
 * @interface UserSort
 */
export interface UserSort {
    /**
     * 
     * @type {string}
     * @memberof UserSort
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSort
     */
    'direction'?: UserSortDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface UserStat
 */
export interface UserStat {
    /**
     * 
     * @type {string}
     * @memberof UserStat
     */
    'user'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserStat
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface Username
 */
export interface Username {
    /**
     * 
     * @type {string}
     * @memberof Username
     */
    'validSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Username
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Username
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Username
     */
    'inferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Username
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Username
     */
    'searchable'?: boolean;
}
/**
 * 
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    'duration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Video
     */
    'isTaggedVideo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    'linkUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WebIntEntity
 */
export interface WebIntEntity {
    /**
     * 
     * @type {Array<GroupDTO>}
     * @memberof WebIntEntity
     */
    'groups'?: Array<GroupDTO>;
}
/**
 * 
 * @export
 * @interface WebintDTO
 */
export interface WebintDTO {
    /**
     * 
     * @type {string}
     * @memberof WebintDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebintDTO
     */
    'caseNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebintDTO
     */
    'isMonitoring'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebintDTO
     */
    'collectLikes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebintDTO
     */
    'collectComments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebintDTO
     */
    'imageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebintDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebintDTO
     */
    'createdDate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebintDTO
     */
    'avatars'?: Array<string>;
    /**
     * 
     * @type {Array<AvatarInfo>}
     * @memberof WebintDTO
     */
    'avatarInfos'?: Array<AvatarInfo>;
    /**
     * 
     * @type {string}
     * @memberof WebintDTO
     */
    'createdBy'?: string;
    /**
     * 
     * @type {Array<KeywordDTO>}
     * @memberof WebintDTO
     */
    'keywords'?: Array<KeywordDTO>;
    /**
     * 
     * @type {Array<WebintGroupDTO>}
     * @memberof WebintDTO
     */
    'groups'?: Array<WebintGroupDTO>;
    /**
     * 
     * @type {WebintProfileDTO}
     * @memberof WebintDTO
     */
    'profile'?: WebintProfileDTO;
    /**
     * 
     * @type {string}
     * @memberof WebintDTO
     */
    'type'?: WebintDTOTypeEnum;
    /**
     * 
     * @type {SchedulerSetting}
     * @memberof WebintDTO
     */
    'schedulerSetting'?: SchedulerSetting;
}

/**
    * @export
    * @enum {string}
    */
export enum WebintDTOTypeEnum {
    GroupChannel = 'GROUP_CHANNEL',
    Keywords = 'KEYWORDS',
    ProfilePosts = 'PROFILE_POSTS',
    GroupKeywords = 'GROUP_KEYWORDS'
}

/**
 * 
 * @export
 * @interface WebintGeneralStatDTO
 */
export interface WebintGeneralStatDTO {
    /**
     * 
     * @type {Array<UserStat>}
     * @memberof WebintGeneralStatDTO
     */
    'users'?: Array<UserStat>;
    /**
     * 
     * @type {Array<MonitorStat>}
     * @memberof WebintGeneralStatDTO
     */
    'monitors'?: Array<MonitorStat>;
}
/**
 * 
 * @export
 * @interface WebintGroupDTO
 */
export interface WebintGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof WebintGroupDTO
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebintGroupDTO
     */
    'socialNetwork'?: WebintGroupDTOSocialNetworkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WebintGroupDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface WebintInfoDTO
 */
export interface WebintInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof WebintInfoDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebintInfoDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WebintProfileDTO
 */
export interface WebintProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof WebintProfileDTO
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebintProfileDTO
     */
    'socialNetwork'?: WebintProfileDTOSocialNetworkEnum;
    /**
     * 
     * @type {string}
     * @memberof WebintProfileDTO
     */
    'socialProfileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebintProfileDTO
     */
    'imageId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WebintProfileDTOSocialNetworkEnum {
    Fb = 'FB',
    Facebook = 'FACEBOOK',
    Vk = 'VK',
    Vkontakte = 'VKONTAKTE',
    Ok = 'OK',
    Odnoklassniki = 'ODNOKLASSNIKI',
    Linkedin = 'LINKEDIN',
    Tumblr = 'TUMBLR',
    Youtube = 'YOUTUBE',
    Jobzella = 'JOBZELLA',
    Twitter = 'TWITTER',
    Foursquare = 'FOURSQUARE',
    Angellist = 'ANGELLIST',
    Instagram = 'INSTAGRAM',
    MyMailRu = 'MY_MAIL_RU',
    Gplus = 'GPLUS',
    GooglePlus = 'GOOGLE_PLUS',
    Gab = 'GAB',
    Snapchat = 'SNAPCHAT',
    Badoo = 'BADOO',
    Fling = 'FLING',
    Tpersona = 'TPERSONA',
    PhNewId = 'PH_NEW_ID',
    Blackhatworld = 'BLACKHATWORLD',
    Dubsmash = 'DUBSMASH',
    GeorgiaCitizenship = 'GEORGIA_CITIZENSHIP',
    Telegram = 'TELEGRAM',
    Search4Faces = 'SEARCH4FACES',
    Findclone = 'FINDCLONE',
    WebvsPrimary = 'WEBVS_PRIMARY',
    Baidu = 'BAIDU',
    Googlesearch = 'GOOGLESEARCH',
    Mymail = 'MYMAIL',
    Yandex = 'YANDEX',
    Yahoo = 'YAHOO',
    Skype = 'SKYPE',
    Massmapper = 'MASSMAPPER',
    Mm = 'MM',
    Profiler = 'PROFILER',
    Pipl = 'PIPL',
    Hydra = 'HYDRA',
    Tiktok = 'TIKTOK',
    Instanavigation = 'INSTANAVIGATION',
    Sniper = 'SNIPER',
    Psychoprofile = 'PSYCHOPROFILE'
}

/**
 * 
 * @export
 * @interface WebintUserStatFilterDTO
 */
export interface WebintUserStatFilterDTO {
    /**
     * 
     * @type {string}
     * @memberof WebintUserStatFilterDTO
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebintUserStatFilterDTO
     */
    'isMonitoring'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebintUserStatFilterDTO
     */
    'startDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebintUserStatFilterDTO
     */
    'endDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebintUserStatFilterDTO
     */
    'skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebintUserStatFilterDTO
     */
    'batch'?: number;
}
/**
 * 
 * @export
 * @interface Work
 */
export interface Work {
    /**
     * 
     * @type {Array<string>}
     * @memberof Work
     */
    'otherInfo'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    'jobTitle'?: string;
}

/**
 * ApiCRUDToCreateAlertCriteriaApi - axios parameter creator
 * @export
 */
export const ApiCRUDToCreateAlertCriteriaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertC: async (alertCriteria: AlertCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertCriteria' is not null or undefined
            assertParamExists('createAlertC', 'alertCriteria', alertCriteria)
            const localVarPath = `/api/alert/criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertC: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('deleteAlertC', 'alertId', alertId)
            const localVarPath = `/api/alert/criteria/{alertId}`
                .replace(`{${"alertId"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertC: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('getAlertC', 'alertId', alertId)
            const localVarPath = `/api/alert/criteria/{alertId}`
                .replace(`{${"alertId"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAlertC: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alert/criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} alertId 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertC: async (alertId: string, alertCriteria: AlertCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('updateAlertC', 'alertId', alertId)
            // verify required parameter 'alertCriteria' is not null or undefined
            assertParamExists('updateAlertC', 'alertCriteria', alertCriteria)
            const localVarPath = `/api/alert/criteria/{alertId}`
                .replace(`{${"alertId"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiCRUDToCreateAlertCriteriaApi - functional programming interface
 * @export
 */
export const ApiCRUDToCreateAlertCriteriaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiCRUDToCreateAlertCriteriaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAlertC(alertCriteria: AlertCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertCriteria>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAlertC(alertCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlertC(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertCriteria>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlertC(alertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertC(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertCriteria>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertC(alertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAlertC(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlertCriteria>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAlertC(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} alertId 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAlertC(alertId: string, alertCriteria: AlertCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAlertC(alertId, alertCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiCRUDToCreateAlertCriteriaApi - factory interface
 * @export
 */
export const ApiCRUDToCreateAlertCriteriaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiCRUDToCreateAlertCriteriaApiFp(configuration)
    return {
        /**
         * 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertC(alertCriteria: AlertCriteria, options?: any): AxiosPromise<AlertCriteria> {
            return localVarFp.createAlertC(alertCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertC(alertId: string, options?: any): AxiosPromise<AlertCriteria> {
            return localVarFp.deleteAlertC(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertC(alertId: string, options?: any): AxiosPromise<AlertCriteria> {
            return localVarFp.getAlertC(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAlertC(options?: any): AxiosPromise<Array<AlertCriteria>> {
            return localVarFp.getAllAlertC(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} alertId 
         * @param {AlertCriteria} alertCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertC(alertId: string, alertCriteria: AlertCriteria, options?: any): AxiosPromise<object> {
            return localVarFp.updateAlertC(alertId, alertCriteria, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiCRUDToCreateAlertCriteriaApi - object-oriented interface
 * @export
 * @class ApiCRUDToCreateAlertCriteriaApi
 * @extends {BaseAPI}
 */
export class ApiCRUDToCreateAlertCriteriaApi extends BaseAPI {
    /**
     * 
     * @param {AlertCriteria} alertCriteria 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiCRUDToCreateAlertCriteriaApi
     */
    public createAlertC(alertCriteria: AlertCriteria, options?: AxiosRequestConfig) {
        return ApiCRUDToCreateAlertCriteriaApiFp(this.configuration).createAlertC(alertCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiCRUDToCreateAlertCriteriaApi
     */
    public deleteAlertC(alertId: string, options?: AxiosRequestConfig) {
        return ApiCRUDToCreateAlertCriteriaApiFp(this.configuration).deleteAlertC(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiCRUDToCreateAlertCriteriaApi
     */
    public getAlertC(alertId: string, options?: AxiosRequestConfig) {
        return ApiCRUDToCreateAlertCriteriaApiFp(this.configuration).getAlertC(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiCRUDToCreateAlertCriteriaApi
     */
    public getAllAlertC(options?: AxiosRequestConfig) {
        return ApiCRUDToCreateAlertCriteriaApiFp(this.configuration).getAllAlertC(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} alertId 
     * @param {AlertCriteria} alertCriteria 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiCRUDToCreateAlertCriteriaApi
     */
    public updateAlertC(alertId: string, alertCriteria: AlertCriteria, options?: AxiosRequestConfig) {
        return ApiCRUDToCreateAlertCriteriaApiFp(this.configuration).updateAlertC(alertId, alertCriteria, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToCheckHealthStatusOfAppApi - axios parameter creator
 * @export
 */
export const ApiToCheckHealthStatusOfAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health-checker/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'IMAGESERVICE' | 'MONGODB'} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceStatus: async (service: 'IMAGESERVICE' | 'MONGODB', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'service' is not null or undefined
            assertParamExists('getServiceStatus', 'service', service)
            const localVarPath = `/api/health-checker/{service}`
                .replace(`{${"service"}}`, encodeURIComponent(String(service)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health-checker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToCheckHealthStatusOfAppApi - functional programming interface
 * @export
 */
export const ApiToCheckHealthStatusOfAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToCheckHealthStatusOfAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async all(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.all(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'IMAGESERVICE' | 'MONGODB'} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceStatus(service: 'IMAGESERVICE' | 'MONGODB', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceStatus(service, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToCheckHealthStatusOfAppApi - factory interface
 * @export
 */
export const ApiToCheckHealthStatusOfAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToCheckHealthStatusOfAppApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.all(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'IMAGESERVICE' | 'MONGODB'} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceStatus(service: 'IMAGESERVICE' | 'MONGODB', options?: any): AxiosPromise<string> {
            return localVarFp.getServiceStatus(service, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<string> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToCheckHealthStatusOfAppApi - object-oriented interface
 * @export
 * @class ApiToCheckHealthStatusOfAppApi
 * @extends {BaseAPI}
 */
export class ApiToCheckHealthStatusOfAppApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToCheckHealthStatusOfAppApi
     */
    public all(options?: AxiosRequestConfig) {
        return ApiToCheckHealthStatusOfAppApiFp(this.configuration).all(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'IMAGESERVICE' | 'MONGODB'} service 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToCheckHealthStatusOfAppApi
     */
    public getServiceStatus(service: 'IMAGESERVICE' | 'MONGODB', options?: AxiosRequestConfig) {
        return ApiToCheckHealthStatusOfAppApiFp(this.configuration).getServiceStatus(service, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToCheckHealthStatusOfAppApi
     */
    public ping(options?: AxiosRequestConfig) {
        return ApiToCheckHealthStatusOfAppApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToGetAuditApi - axios parameter creator
 * @export
 */
export const ApiToGetAuditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq'} [kEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apaAudit: async (auditCreationReq: AuditCreationReq, kEY?: 'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditCreationReq' is not null or undefined
            assertParamExists('apaAudit', 'auditCreationReq', auditCreationReq)
            const localVarPath = `/api/audit/apa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kEY !== undefined && kEY !== null) {
                localVarHeaderParameter['KEY'] = String(kEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditCreationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudit: async (auditCreationReq: AuditCreationReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditCreationReq' is not null or undefined
            assertParamExists('createAudit', 'auditCreationReq', auditCreationReq)
            const localVarPath = `/api/audit/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditCreationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAudits: async (type: string, auditSearchFilter: AuditSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('exportAudits', 'type', type)
            // verify required parameter 'auditSearchFilter' is not null or undefined
            assertParamExists('exportAudits', 'auditSearchFilter', auditSearchFilter)
            const localVarPath = `/api/audit/export/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GlobalStatisticFilter} [globalStatisticFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalStatistic: async (globalStatisticFilter?: GlobalStatisticFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audit/globalStatistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalStatisticFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAudits: async (auditSearchFilter: AuditSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditSearchFilter' is not null or undefined
            assertParamExists('searchAudits', 'auditSearchFilter', auditSearchFilter)
            const localVarPath = `/api/audit/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToGetAuditApi - functional programming interface
 * @export
 */
export const ApiToGetAuditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToGetAuditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq'} [kEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apaAudit(auditCreationReq: AuditCreationReq, kEY?: 'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apaAudit(auditCreationReq, kEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAudit(auditCreationReq: AuditCreationReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAudit(auditCreationReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAudits(type: string, auditSearchFilter: AuditSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAuditActionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAudits(type, auditSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GlobalStatisticFilter} [globalStatisticFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalStatistic(globalStatisticFilter?: GlobalStatisticFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalStatistic(globalStatisticFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAudits(auditSearchFilter: AuditSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAuditActionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAudits(auditSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToGetAuditApi - factory interface
 * @export
 */
export const ApiToGetAuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToGetAuditApiFp(configuration)
    return {
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq'} [kEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apaAudit(auditCreationReq: AuditCreationReq, kEY?: 'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq', options?: any): AxiosPromise<object> {
            return localVarFp.apaAudit(auditCreationReq, kEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditCreationReq} auditCreationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudit(auditCreationReq: AuditCreationReq, options?: any): AxiosPromise<object> {
            return localVarFp.createAudit(auditCreationReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAudits(type: string, auditSearchFilter: AuditSearchFilter, options?: any): AxiosPromise<PageAuditActionDTO> {
            return localVarFp.exportAudits(type, auditSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GlobalStatisticFilter} [globalStatisticFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalStatistic(globalStatisticFilter?: GlobalStatisticFilter, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getGlobalStatistic(globalStatisticFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditSearchFilter} auditSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAudits(auditSearchFilter: AuditSearchFilter, options?: any): AxiosPromise<PageAuditActionDTO> {
            return localVarFp.searchAudits(auditSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToGetAuditApi - object-oriented interface
 * @export
 * @class ApiToGetAuditApi
 * @extends {BaseAPI}
 */
export class ApiToGetAuditApi extends BaseAPI {
    /**
     * 
     * @param {AuditCreationReq} auditCreationReq 
     * @param {'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq'} [kEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetAuditApi
     */
    public apaAudit(auditCreationReq: AuditCreationReq, kEY?: 'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq', options?: AxiosRequestConfig) {
        return ApiToGetAuditApiFp(this.configuration).apaAudit(auditCreationReq, kEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditCreationReq} auditCreationReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetAuditApi
     */
    public createAudit(auditCreationReq: AuditCreationReq, options?: AxiosRequestConfig) {
        return ApiToGetAuditApiFp(this.configuration).createAudit(auditCreationReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {AuditSearchFilter} auditSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetAuditApi
     */
    public exportAudits(type: string, auditSearchFilter: AuditSearchFilter, options?: AxiosRequestConfig) {
        return ApiToGetAuditApiFp(this.configuration).exportAudits(type, auditSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalStatisticFilter} [globalStatisticFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetAuditApi
     */
    public getGlobalStatistic(globalStatisticFilter?: GlobalStatisticFilter, options?: AxiosRequestConfig) {
        return ApiToGetAuditApiFp(this.configuration).getGlobalStatistic(globalStatisticFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditSearchFilter} auditSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetAuditApi
     */
    public searchAudits(auditSearchFilter: AuditSearchFilter, options?: AxiosRequestConfig) {
        return ApiToGetAuditApiFp(this.configuration).searchAudits(auditSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToGetImageFileApi - axios parameter creator
 * @export
 */
export const ApiToGetImageFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImage', 'id', id)
            const localVarPath = `/api/image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageProxy: async (image: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('getImageProxy', 'image', image)
            const localVarPath = `/api/image/proxy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (image !== undefined) {
                localVarQueryParameter['image'] = image;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToGetImageFileApi - functional programming interface
 * @export
 */
export const ApiToGetImageFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToGetImageFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageProxy(image: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageProxy(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToGetImageFileApi - factory interface
 * @export
 */
export const ApiToGetImageFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToGetImageFileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageProxy(image: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getImageProxy(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToGetImageFileApi - object-oriented interface
 * @export
 * @class ApiToGetImageFileApi
 * @extends {BaseAPI}
 */
export class ApiToGetImageFileApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetImageFileApi
     */
    public getImage(id: string, options?: AxiosRequestConfig) {
        return ApiToGetImageFileApiFp(this.configuration).getImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToGetImageFileApi
     */
    public getImageProxy(image: string, options?: AxiosRequestConfig) {
        return ApiToGetImageFileApiFp(this.configuration).getImageProxy(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToViewAndSearchInAlertsApi - axios parameter creator
 * @export
 */
export const ApiToViewAndSearchInAlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Alert} alert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert: async (alert: Alert, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alert' is not null or undefined
            assertParamExists('addAlert', 'alert', alert)
            const localVarPath = `/api/alert/addAlert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertFilter} alertFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertSearch: async (alertFilter: AlertFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertFilter' is not null or undefined
            assertParamExists('alertSearch', 'alertFilter', alertFilter)
            const localVarPath = `/api/alert/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageFilter} pageFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts: async (pageFilter: PageFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageFilter' is not null or undefined
            assertParamExists('getAlerts', 'pageFilter', pageFilter)
            const localVarPath = `/api/alert/view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToViewAndSearchInAlertsApi - functional programming interface
 * @export
 */
export const ApiToViewAndSearchInAlertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToViewAndSearchInAlertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Alert} alert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAlert(alert: Alert, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAlert(alert, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AlertFilter} alertFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertSearch(alertFilter: AlertFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertSearch(alertFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageFilter} pageFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlerts(pageFilter: PageFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlerts(pageFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToViewAndSearchInAlertsApi - factory interface
 * @export
 */
export const ApiToViewAndSearchInAlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToViewAndSearchInAlertsApiFp(configuration)
    return {
        /**
         * 
         * @param {Alert} alert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert(alert: Alert, options?: any): AxiosPromise<void> {
            return localVarFp.addAlert(alert, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AlertFilter} alertFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertSearch(alertFilter: AlertFilter, options?: any): AxiosPromise<PageAlert> {
            return localVarFp.alertSearch(alertFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageFilter} pageFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(pageFilter: PageFilter, options?: any): AxiosPromise<PageAlert> {
            return localVarFp.getAlerts(pageFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToViewAndSearchInAlertsApi - object-oriented interface
 * @export
 * @class ApiToViewAndSearchInAlertsApi
 * @extends {BaseAPI}
 */
export class ApiToViewAndSearchInAlertsApi extends BaseAPI {
    /**
     * 
     * @param {Alert} alert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToViewAndSearchInAlertsApi
     */
    public addAlert(alert: Alert, options?: AxiosRequestConfig) {
        return ApiToViewAndSearchInAlertsApiFp(this.configuration).addAlert(alert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AlertFilter} alertFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToViewAndSearchInAlertsApi
     */
    public alertSearch(alertFilter: AlertFilter, options?: AxiosRequestConfig) {
        return ApiToViewAndSearchInAlertsApiFp(this.configuration).alertSearch(alertFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageFilter} pageFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToViewAndSearchInAlertsApi
     */
    public getAlerts(pageFilter: PageFilter, options?: AxiosRequestConfig) {
        return ApiToViewAndSearchInAlertsApiFp(this.configuration).getAlerts(pageFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithAmmApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithAmmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} username 
         * @param {string} avatarId 
         * @param {AvatarAuditDTO} avatarAuditDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuditLogs: async (username: string, avatarId: string, avatarAuditDTO: AvatarAuditDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addAuditLogs', 'username', username)
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('addAuditLogs', 'avatarId', avatarId)
            // verify required parameter 'avatarAuditDTO' is not null or undefined
            assertParamExists('addAuditLogs', 'avatarAuditDTO', avatarAuditDTO)
            const localVarPath = `/api/amm/avatar-audit/{username}/{avatarId}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarAuditDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {APAProfileDTO} aPAProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSyntheticTargetForAPA: async (username: string, aPAProfileDTO: APAProfileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('createSyntheticTargetForAPA', 'username', username)
            // verify required parameter 'aPAProfileDTO' is not null or undefined
            assertParamExists('createSyntheticTargetForAPA', 'aPAProfileDTO', aPAProfileDTO)
            const localVarPath = `/api/amm/create/syntheticTarget/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPAProfileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {APAWebintDTO} aPAWebintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebintTargetForAPA: async (username: string, aPAWebintDTO: APAWebintDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('createWebintTargetForAPA', 'username', username)
            // verify required parameter 'aPAWebintDTO' is not null or undefined
            assertParamExists('createWebintTargetForAPA', 'aPAWebintDTO', aPAWebintDTO)
            const localVarPath = `/api/amm/create/webintentity/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPAWebintDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} socialNetwork 
         * @param {string} module 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarsPerSocialNetwork: async (socialNetwork: string, module: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'socialNetwork' is not null or undefined
            assertParamExists('getAvatarsPerSocialNetwork', 'socialNetwork', socialNetwork)
            // verify required parameter 'module' is not null or undefined
            assertParamExists('getAvatarsPerSocialNetwork', 'module', module)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getAvatarsPerSocialNetwork', 'username', username)
            const localVarPath = `/api/amm/getAvatarList/{socialNetwork}/{module}/{username}`
                .replace(`{${"socialNetwork"}}`, encodeURIComponent(String(socialNetwork)))
                .replace(`{${"module"}}`, encodeURIComponent(String(module)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithAmmApi - functional programming interface
 * @export
 */
export const ApiToWorkWithAmmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithAmmApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {string} avatarId 
         * @param {AvatarAuditDTO} avatarAuditDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAuditLogs(username: string, avatarId: string, avatarAuditDTO: AvatarAuditDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAuditLogs(username, avatarId, avatarAuditDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {APAProfileDTO} aPAProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSyntheticTargetForAPA(username: string, aPAProfileDTO: APAProfileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSyntheticTargetForAPA(username, aPAProfileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {APAWebintDTO} aPAWebintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebintTargetForAPA(username: string, aPAWebintDTO: APAWebintDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebintTargetForAPA(username, aPAWebintDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} socialNetwork 
         * @param {string} module 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarsPerSocialNetwork(socialNetwork: string, module: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarsPerSocialNetwork(socialNetwork, module, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithAmmApi - factory interface
 * @export
 */
export const ApiToWorkWithAmmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithAmmApiFp(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {string} avatarId 
         * @param {AvatarAuditDTO} avatarAuditDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuditLogs(username: string, avatarId: string, avatarAuditDTO: AvatarAuditDTO, options?: any): AxiosPromise<object> {
            return localVarFp.addAuditLogs(username, avatarId, avatarAuditDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {APAProfileDTO} aPAProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSyntheticTargetForAPA(username: string, aPAProfileDTO: APAProfileDTO, options?: any): AxiosPromise<Array<ProfileInfo>> {
            return localVarFp.createSyntheticTargetForAPA(username, aPAProfileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {APAWebintDTO} aPAWebintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebintTargetForAPA(username: string, aPAWebintDTO: APAWebintDTO, options?: any): AxiosPromise<WebintDTO> {
            return localVarFp.createWebintTargetForAPA(username, aPAWebintDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} socialNetwork 
         * @param {string} module 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarsPerSocialNetwork(socialNetwork: string, module: string, username: string, options?: any): AxiosPromise<Array<AvatarInfo>> {
            return localVarFp.getAvatarsPerSocialNetwork(socialNetwork, module, username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithAmmApi - object-oriented interface
 * @export
 * @class ApiToWorkWithAmmApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithAmmApi extends BaseAPI {
    /**
     * 
     * @param {string} username 
     * @param {string} avatarId 
     * @param {AvatarAuditDTO} avatarAuditDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAmmApi
     */
    public addAuditLogs(username: string, avatarId: string, avatarAuditDTO: AvatarAuditDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAmmApiFp(this.configuration).addAuditLogs(username, avatarId, avatarAuditDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {APAProfileDTO} aPAProfileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAmmApi
     */
    public createSyntheticTargetForAPA(username: string, aPAProfileDTO: APAProfileDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAmmApiFp(this.configuration).createSyntheticTargetForAPA(username, aPAProfileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {APAWebintDTO} aPAWebintDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAmmApi
     */
    public createWebintTargetForAPA(username: string, aPAWebintDTO: APAWebintDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAmmApiFp(this.configuration).createWebintTargetForAPA(username, aPAWebintDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} socialNetwork 
     * @param {string} module 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAmmApi
     */
    public getAvatarsPerSocialNetwork(socialNetwork: string, module: string, username: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAmmApiFp(this.configuration).getAvatarsPerSocialNetwork(socialNetwork, module, username, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithAnalyticsApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AnalyticItemsRequest} analyticItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connection: async (analyticItemsRequest: AnalyticItemsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analyticItemsRequest' is not null or undefined
            assertParamExists('connection', 'analyticItemsRequest', analyticItemsRequest)
            const localVarPath = `/api/analytics/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyticItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithAnalyticsApi - functional programming interface
 * @export
 */
export const ApiToWorkWithAnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithAnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AnalyticItemsRequest} analyticItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connection(analyticItemsRequest: AnalyticItemsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connection(analyticItemsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithAnalyticsApi - factory interface
 * @export
 */
export const ApiToWorkWithAnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithAnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {AnalyticItemsRequest} analyticItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connection(analyticItemsRequest: AnalyticItemsRequest, options?: any): AxiosPromise<AnalyticItemsResponse> {
            return localVarFp.connection(analyticItemsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithAnalyticsApi - object-oriented interface
 * @export
 * @class ApiToWorkWithAnalyticsApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithAnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {AnalyticItemsRequest} analyticItemsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAnalyticsApi
     */
    public connection(analyticItemsRequest: AnalyticItemsRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithAnalyticsApiFp(this.configuration).connection(analyticItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithAvatarActivityApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithAvatarActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {Array<AvatarActivityDTO>} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivities: async (avatarId: string, avatarActivityDTO: Array<AvatarActivityDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('createActivities', 'avatarId', avatarId)
            // verify required parameter 'avatarActivityDTO' is not null or undefined
            assertParamExists('createActivities', 'avatarActivityDTO', avatarActivityDTO)
            const localVarPath = `/api/avatar-activity/avatar/{avatarId}/bulk`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarActivityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (avatarId: string, avatarActivityDTO: AvatarActivityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('createActivity', 'avatarId', avatarId)
            // verify required parameter 'avatarActivityDTO' is not null or undefined
            assertParamExists('createActivity', 'avatarActivityDTO', avatarActivityDTO)
            const localVarPath = `/api/avatar-activity/avatar/{avatarId}`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarActivityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (avatarActivityDTO: AvatarActivityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarActivityDTO' is not null or undefined
            assertParamExists('deleteActivity', 'avatarActivityDTO', avatarActivityDTO)
            const localVarPath = `/api/avatar-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarActivityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {PostGenerateRequest} postGenerateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePosts: async (avatarId: string, postGenerateRequest: PostGenerateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('generatePosts', 'avatarId', avatarId)
            // verify required parameter 'postGenerateRequest' is not null or undefined
            assertParamExists('generatePosts', 'postGenerateRequest', postGenerateRequest)
            const localVarPath = `/api/avatar-activity/avatar/{avatarId}/post-generate`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postGenerateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleById: async (id: string, type: 'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScheduleById', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getScheduleById', 'type', type)
            const localVarPath = `/api/avatar-activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} startPeriod 
         * @param {number} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByMetaAvatarId: async (id: string, startPeriod: number, endPeriod: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScheduleByMetaAvatarId', 'id', id)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('getScheduleByMetaAvatarId', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('getScheduleByMetaAvatarId', 'endPeriod', endPeriod)
            const localVarPath = `/api/avatar-activity/meta-avatar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (id: string, avatarActivityDTO: AvatarActivityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivity', 'id', id)
            // verify required parameter 'avatarActivityDTO' is not null or undefined
            assertParamExists('updateActivity', 'avatarActivityDTO', avatarActivityDTO)
            const localVarPath = `/api/avatar-activity/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarActivityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithAvatarActivityApi - functional programming interface
 * @export
 */
export const ApiToWorkWithAvatarActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithAvatarActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {Array<AvatarActivityDTO>} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivities(avatarId: string, avatarActivityDTO: Array<AvatarActivityDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarActivityDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivities(avatarId, avatarActivityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(avatarId: string, avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarActivityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(avatarId, avatarActivityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarActivityDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(avatarActivityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {PostGenerateRequest} postGenerateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePosts(avatarId: string, postGenerateRequest: PostGenerateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePosts(avatarId, postGenerateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleById(id: string, type: 'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleById(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} startPeriod 
         * @param {number} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleByMetaAvatarId(id: string, startPeriod: number, endPeriod: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleByMetaAvatarId(id, startPeriod, endPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(id: string, avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(id, avatarActivityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithAvatarActivityApi - factory interface
 * @export
 */
export const ApiToWorkWithAvatarActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithAvatarActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {Array<AvatarActivityDTO>} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivities(avatarId: string, avatarActivityDTO: Array<AvatarActivityDTO>, options?: any): AxiosPromise<Array<AvatarActivityDTO>> {
            return localVarFp.createActivities(avatarId, avatarActivityDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(avatarId: string, avatarActivityDTO: AvatarActivityDTO, options?: any): AxiosPromise<AvatarActivityDTO> {
            return localVarFp.createActivity(avatarId, avatarActivityDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(avatarActivityDTO: AvatarActivityDTO, options?: any): AxiosPromise<AvatarActivityDeleteResponse> {
            return localVarFp.deleteActivity(avatarActivityDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {PostGenerateRequest} postGenerateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePosts(avatarId: string, postGenerateRequest: PostGenerateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.generatePosts(avatarId, postGenerateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleById(id: string, type: 'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST', options?: any): AxiosPromise<ScheduleInfoDTO> {
            return localVarFp.getScheduleById(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} startPeriod 
         * @param {number} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByMetaAvatarId(id: string, startPeriod: number, endPeriod: number, options?: any): AxiosPromise<Array<ScheduleInfoDTO>> {
            return localVarFp.getScheduleByMetaAvatarId(id, startPeriod, endPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarActivityDTO} avatarActivityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(id: string, avatarActivityDTO: AvatarActivityDTO, options?: any): AxiosPromise<ScheduleInfoDTO> {
            return localVarFp.updateActivity(id, avatarActivityDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithAvatarActivityApi - object-oriented interface
 * @export
 * @class ApiToWorkWithAvatarActivityApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithAvatarActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} avatarId 
     * @param {Array<AvatarActivityDTO>} avatarActivityDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public createActivities(avatarId: string, avatarActivityDTO: Array<AvatarActivityDTO>, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).createActivities(avatarId, avatarActivityDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} avatarId 
     * @param {AvatarActivityDTO} avatarActivityDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public createActivity(avatarId: string, avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).createActivity(avatarId, avatarActivityDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvatarActivityDTO} avatarActivityDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public deleteActivity(avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).deleteActivity(avatarActivityDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} avatarId 
     * @param {PostGenerateRequest} postGenerateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public generatePosts(avatarId: string, postGenerateRequest: PostGenerateRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).generatePosts(avatarId, postGenerateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public getScheduleById(id: string, type: 'AUTOLOGIN' | 'POST' | 'LIKE_POST' | 'COMMENT_POST' | 'URL' | 'GROUP_POST' | 'USER_POST', options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).getScheduleById(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} startPeriod 
     * @param {number} endPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public getScheduleByMetaAvatarId(id: string, startPeriod: number, endPeriod: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).getScheduleByMetaAvatarId(id, startPeriod, endPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AvatarActivityDTO} avatarActivityDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarActivityApi
     */
    public updateActivity(id: string, avatarActivityDTO: AvatarActivityDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarActivityApiFp(this.configuration).updateActivity(id, avatarActivityDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithAvatarCreatingApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithAvatarCreatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acquireAvatar: async (avatarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('acquireAvatar', 'avatarId', avatarId)
            const localVarPath = `/rest-api/avatar-creation/{avatarId}/acquire`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAvatar: async (avatarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('activateAvatar', 'avatarId', avatarId)
            const localVarPath = `/rest-api/avatar-creation/{avatarId}/activate`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAvatar: async (avatarId: string, messageDto: MessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarId' is not null or undefined
            assertParamExists('deactivateAvatar', 'avatarId', avatarId)
            // verify required parameter 'messageDto' is not null or undefined
            assertParamExists('deactivateAvatar', 'messageDto', messageDto)
            const localVarPath = `/rest-api/avatar-creation/{avatarId}/block`
                .replace(`{${"avatarId"}}`, encodeURIComponent(String(avatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest-api/avatar-creation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest-api/avatar-creation/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithAvatarCreatingApi - functional programming interface
 * @export
 */
export const ApiToWorkWithAvatarCreatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithAvatarCreatingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acquireAvatar(avatarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acquireAvatar(avatarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAvatar(avatarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAvatar(avatarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateAvatar(avatarId: string, messageDto: MessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateAvatar(avatarId, messageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarCreationMessageDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SmsDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithAvatarCreatingApi - factory interface
 * @export
 */
export const ApiToWorkWithAvatarCreatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithAvatarCreatingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acquireAvatar(avatarId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.acquireAvatar(avatarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAvatar(avatarId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.activateAvatar(avatarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} avatarId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAvatar(avatarId: string, messageDto: MessageDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.deactivateAvatar(avatarId, messageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<AvatarCreationMessageDTO>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSms(options?: any): AxiosPromise<Array<SmsDTO>> {
            return localVarFp.getSms(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithAvatarCreatingApi - object-oriented interface
 * @export
 * @class ApiToWorkWithAvatarCreatingApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithAvatarCreatingApi extends BaseAPI {
    /**
     * 
     * @param {string} avatarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarCreatingApi
     */
    public acquireAvatar(avatarId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarCreatingApiFp(this.configuration).acquireAvatar(avatarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} avatarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarCreatingApi
     */
    public activateAvatar(avatarId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarCreatingApiFp(this.configuration).activateAvatar(avatarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} avatarId 
     * @param {MessageDto} messageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarCreatingApi
     */
    public deactivateAvatar(avatarId: string, messageDto: MessageDto, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarCreatingApiFp(this.configuration).deactivateAvatar(avatarId, messageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarCreatingApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarCreatingApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarCreatingApi
     */
    public getSms(options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarCreatingApiFp(this.configuration).getSms(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithAvatarsApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithAvatarsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAvatar: async (id: string, avatarDto: AvatarDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAvatar', 'id', id)
            // verify required parameter 'avatarDto' is not null or undefined
            assertParamExists('addAvatar', 'avatarDto', avatarDto)
            const localVarPath = `/api/meta-avatar/{id}/avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSynopsis: async (id: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSynopsis', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/synopsis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSNStatuses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkSNStatuses', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/sn-check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (metaAvatarDTO: MetaAvatarDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metaAvatarDTO' is not null or undefined
            assertParamExists('create', 'metaAvatarDTO', metaAvatarDTO)
            const localVarPath = `/api/meta-avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAvatarDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatarById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAvatarById', 'id', id)
            const localVarPath = `/api/avatars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAvatars: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/avatars/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateFilter} [dateFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAvatarLogs: async (dateFilter?: DateFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/avatar-audit/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvatarRequest} avatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAvatarBySNAndLock: async (avatarRequest: AvatarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarRequest' is not null or undefined
            assertParamExists('getAvailableAvatarBySNAndLock', 'avatarRequest', avatarRequest)
            const localVarPath = `/api/avatars/available-avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [socialNetwork] 
         * @param {string} [module] 
         * @param {string} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAvatars: async (socialNetwork?: string, module?: string, taskId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/avatars/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (socialNetwork !== undefined) {
                localVarQueryParameter['socialNetwork'] = socialNetwork;
            }

            if (module !== undefined) {
                localVarQueryParameter['module'] = module;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogs: async (auditFilter: AuditFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditFilter' is not null or undefined
            assertParamExists('getAvatarLogs', 'auditFilter', auditFilter)
            const localVarPath = `/api/avatar-audit/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} metaAvatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogsByLastDay: async (metaAvatarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metaAvatarId' is not null or undefined
            assertParamExists('getAvatarLogsByLastDay', 'metaAvatarId', metaAvatarId)
            const localVarPath = `/api/avatar-audit/{metaAvatarId}/lastDay`
                .replace(`{${"metaAvatarId"}}`, encodeURIComponent(String(metaAvatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogsWithoutPagination: async (auditFilter: AuditFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditFilter' is not null or undefined
            assertParamExists('getAvatarLogsWithoutPagination', 'auditFilter', auditFilter)
            const localVarPath = `/api/avatar-audit/searchByPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarStatistic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatarStatistic', 'id', id)
            const localVarPath = `/api/avatars/{id}/statistic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarsUsage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatarsUsage', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/avatarsUsage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockedAvatars: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBlockedAvatars', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/blocked`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaAvatarById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMetaAvatarById', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSNStatuses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSNStatuses', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/sn-check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAvatarGetAlerts: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAvatarGetAlerts', 'id', id)
            const localVarPath = `/api/meta-avatar/{id}/alerts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseAvatars: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/avatars/release-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvatarReturnRequest} avatarReturnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnLockedAvatar: async (avatarReturnRequest: AvatarReturnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarReturnRequest' is not null or undefined
            assertParamExists('returnLockedAvatar', 'avatarReturnRequest', avatarReturnRequest)
            const localVarPath = `/api/avatars/return-avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarReturnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetaAvatarFilter} metaAvatarFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (metaAvatarFilter: MetaAvatarFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metaAvatarFilter' is not null or undefined
            assertParamExists('search', 'metaAvatarFilter', metaAvatarFilter)
            const localVarPath = `/api/meta-avatar/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAvatarFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, avatarDto: AvatarDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'avatarDto' is not null or undefined
            assertParamExists('update', 'avatarDto', avatarDto)
            const localVarPath = `/api/avatars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAPAUsage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAPAUsage', 'id', id)
            const localVarPath = `/api/avatars/{id}/updateAPAusage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarAdditionalInfo} avatarAdditionalInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvatarAdditionalInfo: async (id: string, avatarAdditionalInfo: AvatarAdditionalInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAvatarAdditionalInfo', 'id', id)
            // verify required parameter 'avatarAdditionalInfo' is not null or undefined
            assertParamExists('updateAvatarAdditionalInfo', 'avatarAdditionalInfo', avatarAdditionalInfo)
            const localVarPath = `/api/meta-avatar/{id}/additional-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarAdditionalInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarStatusDTO} avatarStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvatarStatus: async (id: string, avatarStatusDTO: AvatarStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAvatarStatus', 'id', id)
            // verify required parameter 'avatarStatusDTO' is not null or undefined
            assertParamExists('updateAvatarStatus', 'avatarStatusDTO', avatarStatusDTO)
            const localVarPath = `/api/avatars/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (id: string, email: Email, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmail', 'id', id)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('updateEmail', 'email', email)
            const localVarPath = `/api/meta-avatar/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(email, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetaAvatar: async (id: string, metaAvatarDTO: MetaAvatarDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMetaAvatar', 'id', id)
            // verify required parameter 'metaAvatarDTO' is not null or undefined
            assertParamExists('updateMetaAvatar', 'metaAvatarDTO', metaAvatarDTO)
            const localVarPath = `/api/meta-avatar/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAvatarDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarUserName} metaAvatarUserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetaAvatarUserName: async (id: string, metaAvatarUserName: MetaAvatarUserName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMetaAvatarUserName', 'id', id)
            // verify required parameter 'metaAvatarUserName' is not null or undefined
            assertParamExists('updateMetaAvatarUserName', 'metaAvatarUserName', metaAvatarUserName)
            const localVarPath = `/api/meta-avatar/{id}/changeUserName`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAvatarUserName, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordBody} passwordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (id: string, passwordBody: PasswordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePassword', 'id', id)
            // verify required parameter 'passwordBody' is not null or undefined
            assertParamExists('updatePassword', 'passwordBody', passwordBody)
            const localVarPath = `/api/meta-avatar/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ProxyServer} proxyServer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProxy: async (id: string, proxyServer: ProxyServer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProxy', 'id', id)
            // verify required parameter 'proxyServer' is not null or undefined
            assertParamExists('updateProxy', 'proxyServer', proxyServer)
            const localVarPath = `/api/meta-avatar/{id}/proxy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proxyServer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {QuarantineModeDTO} quarantineModeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useAutoQuarantine: async (id: string, quarantineModeDTO: QuarantineModeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('useAutoQuarantine', 'id', id)
            // verify required parameter 'quarantineModeDTO' is not null or undefined
            assertParamExists('useAutoQuarantine', 'quarantineModeDTO', quarantineModeDTO)
            const localVarPath = `/api/avatars/{id}/useAutoQuarantine`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quarantineModeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvatarVerificationDTO} avatarVerificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAvatar: async (avatarVerificationDTO: AvatarVerificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'avatarVerificationDTO' is not null or undefined
            assertParamExists('verifyAvatar', 'avatarVerificationDTO', avatarVerificationDTO)
            const localVarPath = `/api/meta-avatar/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarVerificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithAvatarsApi - functional programming interface
 * @export
 */
export const ApiToWorkWithAvatarsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithAvatarsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAvatar(id: string, avatarDto: AvatarDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAvatar(id, avatarDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSynopsis(id: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSynopsis(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkSNStatuses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkSNStatuses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(metaAvatarDTO: MetaAvatarDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAvatarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(metaAvatarDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAvatarById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAvatarById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAvatars(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAvatars(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DateFilter} [dateFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAvatarLogs(dateFilter?: DateFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarAuditDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAvatarLogs(dateFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AvatarRequest} avatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAvatarBySNAndLock(avatarRequest: AvatarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAvatarBySNAndLock(avatarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [socialNetwork] 
         * @param {string} [module] 
         * @param {string} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAvatars(socialNetwork?: string, module?: string, taskId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAvatars(socialNetwork, module, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarLogs(auditFilter: AuditFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarLogs(auditFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} metaAvatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarLogsByLastDay(metaAvatarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarAuditDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarLogsByLastDay(metaAvatarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarLogsWithoutPagination(auditFilter: AuditFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarAuditDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarLogsWithoutPagination(auditFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarStatistic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarStatisticDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarStatistic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarsUsage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarUsageDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarsUsage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockedAvatars(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockedAvatarDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockedAvatars(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetaAvatarById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAvatarDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetaAvatarById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSNStatuses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSNStatuses(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAvatarGetAlerts(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvatarAlertDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAvatarGetAlerts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseAvatars(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releaseAvatars(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AvatarReturnRequest} avatarReturnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnLockedAvatar(avatarReturnRequest: AvatarReturnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarReturnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnLockedAvatar(avatarReturnRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetaAvatarFilter} metaAvatarFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(metaAvatarFilter: MetaAvatarFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(metaAvatarFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, avatarDto: AvatarDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, avatarDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAPAUsage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAPAUsage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarAdditionalInfo} avatarAdditionalInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAvatarAdditionalInfo(id: string, avatarAdditionalInfo: AvatarAdditionalInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarAdditionalInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAvatarAdditionalInfo(id, avatarAdditionalInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarStatusDTO} avatarStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAvatarStatus(id: string, avatarStatusDTO: AvatarStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAvatarStatus(id, avatarStatusDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(id: string, email: Email, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Email>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(id, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetaAvatar(id: string, metaAvatarDTO: MetaAvatarDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAvatarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetaAvatar(id, metaAvatarDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarUserName} metaAvatarUserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetaAvatarUserName(id: string, metaAvatarUserName: MetaAvatarUserName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAvatarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetaAvatarUserName(id, metaAvatarUserName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordBody} passwordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(id: string, passwordBody: PasswordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAvatarDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(id, passwordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ProxyServer} proxyServer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProxy(id: string, proxyServer: ProxyServer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProxyServer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProxy(id, proxyServer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {QuarantineModeDTO} quarantineModeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useAutoQuarantine(id: string, quarantineModeDTO: QuarantineModeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuarantineModeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useAutoQuarantine(id, quarantineModeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AvatarVerificationDTO} avatarVerificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAvatar(avatarVerificationDTO: AvatarVerificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAvatar(avatarVerificationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithAvatarsApi - factory interface
 * @export
 */
export const ApiToWorkWithAvatarsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithAvatarsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAvatar(id: string, avatarDto: AvatarDto, options?: any): AxiosPromise<AvatarDTOResponse> {
            return localVarFp.addAvatar(id, avatarDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSynopsis(id: string, body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.addSynopsis(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSNStatuses(id: string, options?: any): AxiosPromise<Array<AvatarDto>> {
            return localVarFp.checkSNStatuses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(metaAvatarDTO: MetaAvatarDTO, options?: any): AxiosPromise<MetaAvatarResponse> {
            return localVarFp.create(metaAvatarDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatarById(id: string, options?: any): AxiosPromise<AvatarDeleteResponse> {
            return localVarFp.deleteAvatarById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAvatars(options?: any): AxiosPromise<void> {
            return localVarFp.exportAvatars(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DateFilter} [dateFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAvatarLogs(dateFilter?: DateFilter, options?: any): AxiosPromise<Array<AvatarAuditDTO>> {
            return localVarFp.getAllAvatarLogs(dateFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvatarRequest} avatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAvatarBySNAndLock(avatarRequest: AvatarRequest, options?: any): AxiosPromise<AvatarResponse> {
            return localVarFp.getAvailableAvatarBySNAndLock(avatarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [socialNetwork] 
         * @param {string} [module] 
         * @param {string} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAvatars(socialNetwork?: string, module?: string, taskId?: string, options?: any): AxiosPromise<Array<AvatarInfo>> {
            return localVarFp.getAvailableAvatars(socialNetwork, module, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogs(auditFilter: AuditFilter, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getAvatarLogs(auditFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} metaAvatarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogsByLastDay(metaAvatarId: string, options?: any): AxiosPromise<Array<AvatarAuditDTO>> {
            return localVarFp.getAvatarLogsByLastDay(metaAvatarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditFilter} auditFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarLogsWithoutPagination(auditFilter: AuditFilter, options?: any): AxiosPromise<Array<AvatarAuditDTO>> {
            return localVarFp.getAvatarLogsWithoutPagination(auditFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarStatistic(id: string, options?: any): AxiosPromise<AvatarStatisticDTO> {
            return localVarFp.getAvatarStatistic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarsUsage(id: string, options?: any): AxiosPromise<Array<AvatarUsageDTO>> {
            return localVarFp.getAvatarsUsage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockedAvatars(id: string, options?: any): AxiosPromise<Array<BlockedAvatarDTO>> {
            return localVarFp.getBlockedAvatars(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaAvatarById(id: string, options?: any): AxiosPromise<MetaAvatarDTO> {
            return localVarFp.getMetaAvatarById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSNStatuses(id: string, options?: any): AxiosPromise<Array<AvatarDto>> {
            return localVarFp.getSNStatuses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAvatarGetAlerts(id: string, options?: any): AxiosPromise<Array<AvatarAlertDTO>> {
            return localVarFp.metaAvatarGetAlerts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseAvatars(options?: any): AxiosPromise<void> {
            return localVarFp.releaseAvatars(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvatarReturnRequest} avatarReturnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnLockedAvatar(avatarReturnRequest: AvatarReturnRequest, options?: any): AxiosPromise<AvatarReturnResponse> {
            return localVarFp.returnLockedAvatar(avatarReturnRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetaAvatarFilter} metaAvatarFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(metaAvatarFilter: MetaAvatarFilter, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.search(metaAvatarFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarDto} avatarDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, avatarDto: AvatarDto, options?: any): AxiosPromise<AvatarDTOResponse> {
            return localVarFp.update(id, avatarDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAPAUsage(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateAPAUsage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarAdditionalInfo} avatarAdditionalInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvatarAdditionalInfo(id: string, avatarAdditionalInfo: AvatarAdditionalInfo, options?: any): AxiosPromise<AvatarAdditionalInfo> {
            return localVarFp.updateAvatarAdditionalInfo(id, avatarAdditionalInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AvatarStatusDTO} avatarStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvatarStatus(id: string, avatarStatusDTO: AvatarStatusDTO, options?: any): AxiosPromise<AvatarDto> {
            return localVarFp.updateAvatarStatus(id, avatarStatusDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(id: string, email: Email, options?: any): AxiosPromise<Email> {
            return localVarFp.updateEmail(id, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarDTO} metaAvatarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetaAvatar(id: string, metaAvatarDTO: MetaAvatarDTO, options?: any): AxiosPromise<MetaAvatarResponse> {
            return localVarFp.updateMetaAvatar(id, metaAvatarDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MetaAvatarUserName} metaAvatarUserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetaAvatarUserName(id: string, metaAvatarUserName: MetaAvatarUserName, options?: any): AxiosPromise<MetaAvatarResponse> {
            return localVarFp.updateMetaAvatarUserName(id, metaAvatarUserName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordBody} passwordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(id: string, passwordBody: PasswordBody, options?: any): AxiosPromise<MetaAvatarDTO> {
            return localVarFp.updatePassword(id, passwordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ProxyServer} proxyServer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProxy(id: string, proxyServer: ProxyServer, options?: any): AxiosPromise<ProxyServer> {
            return localVarFp.updateProxy(id, proxyServer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {QuarantineModeDTO} quarantineModeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useAutoQuarantine(id: string, quarantineModeDTO: QuarantineModeDTO, options?: any): AxiosPromise<QuarantineModeDTO> {
            return localVarFp.useAutoQuarantine(id, quarantineModeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvatarVerificationDTO} avatarVerificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAvatar(avatarVerificationDTO: AvatarVerificationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.verifyAvatar(avatarVerificationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithAvatarsApi - object-oriented interface
 * @export
 * @class ApiToWorkWithAvatarsApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithAvatarsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AvatarDto} avatarDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public addAvatar(id: string, avatarDto: AvatarDto, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).addAvatar(id, avatarDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public addSynopsis(id: string, body?: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).addSynopsis(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public checkSNStatuses(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).checkSNStatuses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetaAvatarDTO} metaAvatarDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public create(metaAvatarDTO: MetaAvatarDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).create(metaAvatarDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public deleteAvatarById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).deleteAvatarById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public deleteById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).deleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public exportAvatars(options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).exportAvatars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DateFilter} [dateFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAllAvatarLogs(dateFilter?: DateFilter, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAllAvatarLogs(dateFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvatarRequest} avatarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvailableAvatarBySNAndLock(avatarRequest: AvatarRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvailableAvatarBySNAndLock(avatarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [socialNetwork] 
     * @param {string} [module] 
     * @param {string} [taskId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvailableAvatars(socialNetwork?: string, module?: string, taskId?: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvailableAvatars(socialNetwork, module, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditFilter} auditFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvatarLogs(auditFilter: AuditFilter, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvatarLogs(auditFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} metaAvatarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvatarLogsByLastDay(metaAvatarId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvatarLogsByLastDay(metaAvatarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditFilter} auditFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvatarLogsWithoutPagination(auditFilter: AuditFilter, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvatarLogsWithoutPagination(auditFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvatarStatistic(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvatarStatistic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getAvatarsUsage(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getAvatarsUsage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getBlockedAvatars(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getBlockedAvatars(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getMetaAvatarById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getMetaAvatarById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public getSNStatuses(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).getSNStatuses(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public metaAvatarGetAlerts(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).metaAvatarGetAlerts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public releaseAvatars(options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).releaseAvatars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvatarReturnRequest} avatarReturnRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public returnLockedAvatar(avatarReturnRequest: AvatarReturnRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).returnLockedAvatar(avatarReturnRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetaAvatarFilter} metaAvatarFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public search(metaAvatarFilter: MetaAvatarFilter, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).search(metaAvatarFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AvatarDto} avatarDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public update(id: string, avatarDto: AvatarDto, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).update(id, avatarDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateAPAUsage(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateAPAUsage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AvatarAdditionalInfo} avatarAdditionalInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateAvatarAdditionalInfo(id: string, avatarAdditionalInfo: AvatarAdditionalInfo, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateAvatarAdditionalInfo(id, avatarAdditionalInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AvatarStatusDTO} avatarStatusDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateAvatarStatus(id: string, avatarStatusDTO: AvatarStatusDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateAvatarStatus(id, avatarStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Email} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateEmail(id: string, email: Email, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateEmail(id, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MetaAvatarDTO} metaAvatarDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateMetaAvatar(id: string, metaAvatarDTO: MetaAvatarDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateMetaAvatar(id, metaAvatarDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MetaAvatarUserName} metaAvatarUserName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateMetaAvatarUserName(id: string, metaAvatarUserName: MetaAvatarUserName, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateMetaAvatarUserName(id, metaAvatarUserName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PasswordBody} passwordBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updatePassword(id: string, passwordBody: PasswordBody, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updatePassword(id, passwordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ProxyServer} proxyServer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public updateProxy(id: string, proxyServer: ProxyServer, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).updateProxy(id, proxyServer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {QuarantineModeDTO} quarantineModeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public useAutoQuarantine(id: string, quarantineModeDTO: QuarantineModeDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).useAutoQuarantine(id, quarantineModeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvatarVerificationDTO} avatarVerificationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithAvatarsApi
     */
    public verifyAvatar(avatarVerificationDTO: AvatarVerificationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithAvatarsApiFp(this.configuration).verifyAvatar(avatarVerificationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithCasesApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CaseCreateDTO} caseCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCase: async (caseCreateDTO: CaseCreateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseCreateDTO' is not null or undefined
            assertParamExists('createCase', 'caseCreateDTO', caseCreateDTO)
            const localVarPath = `/api/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseCreateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile: async (number: number, inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('createProfile', 'number', number)
            const localVarPath = `/api/case/{number}/profile`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebint: async (number: number, inlineObject3?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('createWebint', 'number', number)
            const localVarPath = `/api/case/{number}/webint`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByCaseNumber: async (caseNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseNumber' is not null or undefined
            assertParamExists('deleteByCaseNumber', 'caseNumber', caseNumber)
            const localVarPath = `/api/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (caseNumber !== undefined) {
                localVarQueryParameter['caseNumber'] = caseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProfileToExcel: async (number: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('exportProfileToExcel', 'number', number)
            const localVarPath = `/api/case/{number}/profile-excel`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCaseNumber: async (caseNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseNumber' is not null or undefined
            assertParamExists('getByCaseNumber', 'caseNumber', caseNumber)
            const localVarPath = `/api/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (caseNumber !== undefined) {
                localVarQueryParameter['caseNumber'] = caseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseProfiles: async (number: number, page: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getCaseProfiles', 'number', number)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCaseProfiles', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getCaseProfiles', 'size', size)
            const localVarPath = `/api/case/{number}/profile`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseWebins: async (number: number, page: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getCaseWebins', 'number', number)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCaseWebins', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getCaseWebins', 'size', size)
            const localVarPath = `/api/case/{number}/webint`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCaseProfiles: async (number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getFiltredCaseProfiles', 'number', number)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getFiltredCaseProfiles', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getFiltredCaseProfiles', 'size', size)
            // verify required parameter 'filterTreeInv' is not null or undefined
            assertParamExists('getFiltredCaseProfiles', 'filterTreeInv', filterTreeInv)
            const localVarPath = `/api/case/{number}/profile/filter`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterTreeInv, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCaseWebins: async (number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getFiltredCaseWebins', 'number', number)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getFiltredCaseWebins', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getFiltredCaseWebins', 'size', size)
            // verify required parameter 'filterTreeInv' is not null or undefined
            assertParamExists('getFiltredCaseWebins', 'filterTreeInv', filterTreeInv)
            const localVarPath = `/api/case/{number}/webint/filter`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterTreeInv, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveStatusCase: async (caseNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseNumber' is not null or undefined
            assertParamExists('setActiveStatusCase', 'caseNumber', caseNumber)
            const localVarPath = `/api/case/{caseNumber}/active`
                .replace(`{${"caseNumber"}}`, encodeURIComponent(String(caseNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchiveStatusCase: async (caseNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseNumber' is not null or undefined
            assertParamExists('setArchiveStatusCase', 'caseNumber', caseNumber)
            const localVarPath = `/api/case/{caseNumber}/archive`
                .replace(`{${"caseNumber"}}`, encodeURIComponent(String(caseNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithCasesApi - functional programming interface
 * @export
 */
export const ApiToWorkWithCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CaseCreateDTO} caseCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCase(caseCreateDTO: CaseCreateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCase(caseCreateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfile(number: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(number, inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebint(number: number, inlineObject3?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebint(number, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByCaseNumber(caseNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByCaseNumber(caseNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProfileToExcel(number: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportProfileToExcel(number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCaseNumber(caseNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCaseNumber(caseNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaseProfiles(number: number, page: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProfileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaseProfiles(number, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaseWebins(number: number, page: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWebintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaseWebins(number, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiltredCaseProfiles(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProfileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiltredCaseProfiles(number, page, size, filterTreeInv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiltredCaseWebins(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWebintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiltredCaseWebins(number, page, size, filterTreeInv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActiveStatusCase(caseNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActiveStatusCase(caseNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArchiveStatusCase(caseNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArchiveStatusCase(caseNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithCasesApi - factory interface
 * @export
 */
export const ApiToWorkWithCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithCasesApiFp(configuration)
    return {
        /**
         * 
         * @param {CaseCreateDTO} caseCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCase(caseCreateDTO: CaseCreateDTO, options?: any): AxiosPromise<CaseResponseDTO> {
            return localVarFp.createCase(caseCreateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile(number: number, inlineObject2?: InlineObject2, options?: any): AxiosPromise<Array<ProfileInfo>> {
            return localVarFp.createProfile(number, inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebint(number: number, inlineObject3?: InlineObject3, options?: any): AxiosPromise<WebintDTO> {
            return localVarFp.createWebint(number, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByCaseNumber(caseNumber: number, options?: any): AxiosPromise<CaseResponseDTO> {
            return localVarFp.deleteByCaseNumber(caseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProfileToExcel(number: number, options?: any): AxiosPromise<void> {
            return localVarFp.exportProfileToExcel(number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCaseNumber(caseNumber: number, options?: any): AxiosPromise<CaseResponseDTO> {
            return localVarFp.getByCaseNumber(caseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseProfiles(number: number, page: number, size: number, options?: any): AxiosPromise<PageProfileInfo> {
            return localVarFp.getCaseProfiles(number, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseWebins(number: number, page: number, size: number, options?: any): AxiosPromise<PageWebintDTO> {
            return localVarFp.getCaseWebins(number, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCaseProfiles(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: any): AxiosPromise<PageProfileInfo> {
            return localVarFp.getFiltredCaseProfiles(number, page, size, filterTreeInv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} number 
         * @param {number} page 
         * @param {number} size 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCaseWebins(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: any): AxiosPromise<PageWebintDTO> {
            return localVarFp.getFiltredCaseWebins(number, page, size, filterTreeInv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveStatusCase(caseNumber: number, options?: any): AxiosPromise<object> {
            return localVarFp.setActiveStatusCase(caseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchiveStatusCase(caseNumber: number, options?: any): AxiosPromise<object> {
            return localVarFp.setArchiveStatusCase(caseNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithCasesApi - object-oriented interface
 * @export
 * @class ApiToWorkWithCasesApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithCasesApi extends BaseAPI {
    /**
     * 
     * @param {CaseCreateDTO} caseCreateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public createCase(caseCreateDTO: CaseCreateDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).createCase(caseCreateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public createProfile(number: number, inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).createProfile(number, inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public createWebint(number: number, inlineObject3?: InlineObject3, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).createWebint(number, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public deleteByCaseNumber(caseNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).deleteByCaseNumber(caseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public exportProfileToExcel(number: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).exportProfileToExcel(number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public getByCaseNumber(caseNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).getByCaseNumber(caseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {number} page 
     * @param {number} size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public getCaseProfiles(number: number, page: number, size: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).getCaseProfiles(number, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {number} page 
     * @param {number} size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public getCaseWebins(number: number, page: number, size: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).getCaseWebins(number, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {number} page 
     * @param {number} size 
     * @param {FilterTreeInv} filterTreeInv 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public getFiltredCaseProfiles(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).getFiltredCaseProfiles(number, page, size, filterTreeInv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} number 
     * @param {number} page 
     * @param {number} size 
     * @param {FilterTreeInv} filterTreeInv 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public getFiltredCaseWebins(number: number, page: number, size: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).getFiltredCaseWebins(number, page, size, filterTreeInv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public setActiveStatusCase(caseNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).setActiveStatusCase(caseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithCasesApi
     */
    public setArchiveStatusCase(caseNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithCasesApiFp(this.configuration).setArchiveStatusCase(caseNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithInvestigationsApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithInvestigationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InvestigationUserNameReq} investigationUserNameReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAssignedUser: async (investigationUserNameReq: InvestigationUserNameReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investigationUserNameReq' is not null or undefined
            assertParamExists('changeAssignedUser', 'investigationUserNameReq', investigationUserNameReq)
            const localVarPath = `/api/inv/changeUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investigationUserNameReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByInvNumber: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('deleteByInvNumber', 'invNumber', invNumber)
            const localVarPath = `/api/inv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invNumber !== undefined) {
                localVarQueryParameter['invNumber'] = invNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInv: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inv/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inv/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByInvNumber: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('getByInvNumber', 'invNumber', invNumber)
            const localVarPath = `/api/inv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invNumber !== undefined) {
                localVarQueryParameter['invNumber'] = invNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasesByInvNumber: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('getCasesByInvNumber', 'invNumber', invNumber)
            const localVarPath = `/api/inv/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invNumber !== undefined) {
                localVarQueryParameter['invNumber'] = invNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredAllInvTree: async (filterTreeInv: FilterTreeInv, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterTreeInv' is not null or undefined
            assertParamExists('getFiltredAllInvTree', 'filterTreeInv', filterTreeInv)
            const localVarPath = `/api/inv/tree/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterTreeInv, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCasesByInvNumber: async (invNumber: number, filterTreeInv: FilterTreeInv, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('getFiltredCasesByInvNumber', 'invNumber', invNumber)
            // verify required parameter 'filterTreeInv' is not null or undefined
            assertParamExists('getFiltredCasesByInvNumber', 'filterTreeInv', filterTreeInv)
            const localVarPath = `/api/inv/cases/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invNumber !== undefined) {
                localVarQueryParameter['invNumber'] = invNumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterTreeInv, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getInvByUsername', 'username', username)
            const localVarPath = `/api/inv/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvsByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getInvsByUsername', 'username', username)
            const localVarPath = `/api/inv/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvestigationDTO} investigationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investigationCreate: async (investigationDTO: InvestigationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investigationDTO' is not null or undefined
            assertParamExists('investigationCreate', 'investigationDTO', investigationDTO)
            const localVarPath = `/api/inv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investigationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveStatusInv: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('setActiveStatusInv', 'invNumber', invNumber)
            const localVarPath = `/api/inv/{invNumber}/active`
                .replace(`{${"invNumber"}}`, encodeURIComponent(String(invNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchiveStatusInv: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('setArchiveStatusInv', 'invNumber', invNumber)
            const localVarPath = `/api/inv/{invNumber}/archive`
                .replace(`{${"invNumber"}}`, encodeURIComponent(String(invNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithInvestigationsApi - functional programming interface
 * @export
 */
export const ApiToWorkWithInvestigationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithInvestigationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InvestigationUserNameReq} investigationUserNameReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAssignedUser(investigationUserNameReq: InvestigationUserNameReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Investigation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAssignedUser(investigationUserNameReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByInvNumber(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestigationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByInvNumber(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInv(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Investigation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInv(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInvTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: InvestigationTreeNodeDTO; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInvTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByInvNumber(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestigationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByInvNumber(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCasesByInvNumber(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Case>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCasesByInvNumber(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiltredAllInvTree(filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: InvestigationTreeNodeDTO; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiltredAllInvTree(filterTreeInv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiltredCasesByInvNumber(invNumber: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Case>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiltredCasesByInvNumber(invNumber, filterTreeInv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Investigation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvsByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestigationInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvsByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvestigationDTO} investigationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investigationCreate(investigationDTO: InvestigationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestigationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investigationCreate(investigationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActiveStatusInv(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActiveStatusInv(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArchiveStatusInv(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArchiveStatusInv(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithInvestigationsApi - factory interface
 * @export
 */
export const ApiToWorkWithInvestigationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithInvestigationsApiFp(configuration)
    return {
        /**
         * 
         * @param {InvestigationUserNameReq} investigationUserNameReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAssignedUser(investigationUserNameReq: InvestigationUserNameReq, options?: any): AxiosPromise<Array<Investigation>> {
            return localVarFp.changeAssignedUser(investigationUserNameReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByInvNumber(invNumber: number, options?: any): AxiosPromise<InvestigationResponseDTO> {
            return localVarFp.deleteByInvNumber(invNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInv(options?: any): AxiosPromise<Array<Investigation>> {
            return localVarFp.getAllInv(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvTree(options?: any): AxiosPromise<{ [key: string]: InvestigationTreeNodeDTO; }> {
            return localVarFp.getAllInvTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByInvNumber(invNumber: number, options?: any): AxiosPromise<InvestigationResponseDTO> {
            return localVarFp.getByInvNumber(invNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasesByInvNumber(invNumber: number, options?: any): AxiosPromise<Array<Case>> {
            return localVarFp.getCasesByInvNumber(invNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredAllInvTree(filterTreeInv: FilterTreeInv, options?: any): AxiosPromise<{ [key: string]: InvestigationTreeNodeDTO; }> {
            return localVarFp.getFiltredAllInvTree(filterTreeInv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {FilterTreeInv} filterTreeInv 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltredCasesByInvNumber(invNumber: number, filterTreeInv: FilterTreeInv, options?: any): AxiosPromise<Array<Case>> {
            return localVarFp.getFiltredCasesByInvNumber(invNumber, filterTreeInv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvByUsername(username: string, options?: any): AxiosPromise<Array<Investigation>> {
            return localVarFp.getInvByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvsByUsername(username: string, options?: any): AxiosPromise<Array<InvestigationInfoDTO>> {
            return localVarFp.getInvsByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvestigationDTO} investigationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investigationCreate(investigationDTO: InvestigationDTO, options?: any): AxiosPromise<InvestigationResponseDTO> {
            return localVarFp.investigationCreate(investigationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveStatusInv(invNumber: number, options?: any): AxiosPromise<object> {
            return localVarFp.setActiveStatusInv(invNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArchiveStatusInv(invNumber: number, options?: any): AxiosPromise<object> {
            return localVarFp.setArchiveStatusInv(invNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithInvestigationsApi - object-oriented interface
 * @export
 * @class ApiToWorkWithInvestigationsApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithInvestigationsApi extends BaseAPI {
    /**
     * 
     * @param {InvestigationUserNameReq} investigationUserNameReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public changeAssignedUser(investigationUserNameReq: InvestigationUserNameReq, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).changeAssignedUser(investigationUserNameReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public deleteByInvNumber(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).deleteByInvNumber(invNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getAllInv(options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getAllInv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getAllInvTree(options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getAllInvTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getByInvNumber(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getByInvNumber(invNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getCasesByInvNumber(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getCasesByInvNumber(invNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterTreeInv} filterTreeInv 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getFiltredAllInvTree(filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getFiltredAllInvTree(filterTreeInv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {FilterTreeInv} filterTreeInv 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getFiltredCasesByInvNumber(invNumber: number, filterTreeInv: FilterTreeInv, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getFiltredCasesByInvNumber(invNumber, filterTreeInv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getInvByUsername(username: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getInvByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public getInvsByUsername(username: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).getInvsByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvestigationDTO} investigationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public investigationCreate(investigationDTO: InvestigationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).investigationCreate(investigationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public setActiveStatusInv(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).setActiveStatusInv(invNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithInvestigationsApi
     */
    public setArchiveStatusInv(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithInvestigationsApiFp(this.configuration).setArchiveStatusInv(invNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithProfileApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmail: async (id: number, profileModificationDTO: ProfileModificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addEmail', 'id', id)
            // verify required parameter 'profileModificationDTO' is not null or undefined
            assertParamExists('addEmail', 'profileModificationDTO', profileModificationDTO)
            const localVarPath = `/api/profile/{id}/add-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileModificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHydraItemToProfileInfo: async (id: number, lri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addHydraItemToProfileInfo', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('addHydraItemToProfileInfo', 'lri', lri)
            const localVarPath = `/api/profile/{id}/hydra-item-data/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImage: async (id: number, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addImage', 'id', id)
            const localVarPath = `/api/profile/{id}/add-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addName: async (id: number, profileModificationDTO: ProfileModificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addName', 'id', id)
            // verify required parameter 'profileModificationDTO' is not null or undefined
            assertParamExists('addName', 'profileModificationDTO', profileModificationDTO)
            const localVarPath = `/api/profile/{id}/add-name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileModificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPassword: async (id: number, profileModificationDTO: ProfileModificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addPassword', 'id', id)
            // verify required parameter 'profileModificationDTO' is not null or undefined
            assertParamExists('addPassword', 'profileModificationDTO', profileModificationDTO)
            const localVarPath = `/api/profile/{id}/add-password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileModificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhone: async (id: number, profileModificationDTO: ProfileModificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addPhone', 'id', id)
            // verify required parameter 'profileModificationDTO' is not null or undefined
            assertParamExists('addPhone', 'profileModificationDTO', profileModificationDTO)
            const localVarPath = `/api/profile/{id}/add-phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileModificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfileItemToProfile: async (id: number, lri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProfileItemToProfile', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('addProfileItemToProfile', 'lri', lri)
            const localVarPath = `/api/profile/{id}/profile-item-data/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialProfileData} socialProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSocialProfile: async (id: number, socialProfileData: SocialProfileData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSocialProfile', 'id', id)
            // verify required parameter 'socialProfileData' is not null or undefined
            assertParamExists('addSocialProfile', 'socialProfileData', socialProfileData)
            const localVarPath = `/api/profile/{id}/add-social-profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialProfileData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeEntities: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('analyzeEntities', 'body', body)
            const localVarPath = `/api/profile/analyzeEntities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileTarget: async (inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/create-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProfile', 'id', id)
            const localVarPath = `/api/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSocialProfile: async (id: number, lri: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSocialProfile', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('deleteSocialProfile', 'lri', lri)
            const localVarPath = `/api/profile/{id}/social-profile/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FetchMoreFriendsReq} fetchMoreFriendsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMoreFriends: async (id: number, fetchMoreFriendsReq: FetchMoreFriendsReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMoreFriends', 'id', id)
            // verify required parameter 'fetchMoreFriendsReq' is not null or undefined
            assertParamExists('fetchMoreFriends', 'fetchMoreFriendsReq', fetchMoreFriendsReq)
            const localVarPath = `/api/profile/{id}/fetch-more-friends`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchMoreFriendsReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFriends: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdditionalFriends', 'id', id)
            const localVarPath = `/api/profile/{id}/friends/additional`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvataTaskCountInQueue: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/avatar-tasks/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactInfo', 'id', id)
            const localVarPath = `/api/profile/{id}/contact-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepWeb: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeepWeb', 'id', id)
            const localVarPath = `/api/profile/{id}/deep-web`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriends: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFriends', 'id', id)
            const localVarPath = `/api/profile/{id}/friends`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStat: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGeneralStat', 'id', id)
            const localVarPath = `/api/profile/{id}/general-stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageSearchResult: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImageSearchResult', 'id', id)
            const localVarPath = `/api/profile/{id}/image-search-result`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInformation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonalInformation', 'id', id)
            const localVarPath = `/api/profile/{id}/personal-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhotos: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPhotos', 'id', id)
            const localVarPath = `/api/profile/{id}/photos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferences: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreferences', 'id', id)
            const localVarPath = `/api/profile/{id}/preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfile', 'id', id)
            const localVarPath = `/api/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE'} [socialNetwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileActivityStat: async (id: number, socialNetwork?: 'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfileActivityStat', 'id', id)
            const localVarPath = `/api/profile/{id}/activity-stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (socialNetwork !== undefined) {
                localVarQueryParameter['socialNetwork'] = socialNetwork;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileLocationStat: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfileLocationStat', 'id', id)
            const localVarPath = `/api/profile/{id}/location-stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePostStat: async (id: number, dateRangeDTO: DateRangeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfilePostStat', 'id', id)
            // verify required parameter 'dateRangeDTO' is not null or undefined
            assertParamExists('getProfilePostStat', 'dateRangeDTO', dateRangeDTO)
            const localVarPath = `/api/profile/{id}/post-stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateRangeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPsychoProfile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPsychoProfile', 'id', id)
            const localVarPath = `/api/profile/{id}/psycho-profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSynopsis: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSynopsis', 'id', id)
            const localVarPath = `/api/profile/{id}/synopsis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebResults: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWebResults', 'id', id)
            const localVarPath = `/api/profile/{id}/web-results`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementalSearchSocialProfile: async (id: number, lri: number, socialProfileData?: SocialProfileData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('incrementalSearchSocialProfile', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('incrementalSearchSocialProfile', 'lri', lri)
            const localVarPath = `/api/profile/{id}/social-profile-incremental/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialProfileData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAssignProfileDataItem: async (id: number, profileItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileAssignProfileDataItem', 'id', id)
            // verify required parameter 'profileItemId' is not null or undefined
            assertParamExists('profileAssignProfileDataItem', 'profileItemId', profileItemId)
            const localVarPath = `/api/profile/{id}/assignProfileDataItem/{profileItemId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"profileItemId"}}`, encodeURIComponent(String(profileItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetPosts: async (id: number, postFilterDTO: PostFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileGetPosts', 'id', id)
            // verify required parameter 'postFilterDTO' is not null or undefined
            assertParamExists('profileGetPosts', 'postFilterDTO', postFilterDTO)
            const localVarPath = `/api/profile/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetSearchProgress: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileGetSearchProgress', 'id', id)
            const localVarPath = `/api/profile/{id}/search-progress`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePostAnalyze: async (id: string, postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profilePostAnalyze', 'id', id)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('profilePostAnalyze', 'postId', postId)
            const localVarPath = `/api/profile/{id}/post/{postId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reRunLookup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reRunLookup', 'id', id)
            const localVarPath = `/api/profile/{id}/run-lookup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reRunSearchCrawler: async (id: number, lri: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reRunSearchCrawler', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('reRunSearchCrawler', 'lri', lri)
            const localVarPath = `/api/profile/{id}/reRunSearchCrawler/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeHydraItemToProfileInfo: async (id: number, lri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeHydraItemToProfileInfo', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('removeHydraItemToProfileInfo', 'lri', lri)
            const localVarPath = `/api/profile/{id}/hydra-item-data/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfileItemFromProfile: async (id: number, lri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeProfileItemFromProfile', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('removeProfileItemFromProfile', 'lri', lri)
            const localVarPath = `/api/profile/{id}/profile-item-data/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGetContactLookup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runGetContactLookup', 'id', id)
            const localVarPath = `/api/profile/{id}/runGetContactLookup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runLookup: async (id: number, lri: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runLookup', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('runLookup', 'lri', lri)
            const localVarPath = `/api/profile/{id}/runLookup/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTelegramLookup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runTelegramLookup', 'id', id)
            const localVarPath = `/api/profile/{id}/runTelegramLookup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHistory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchHistory', 'id', id)
            const localVarPath = `/api/profile/{id}/search-history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPsychoFilterDTO} postPsychoFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPsychoProfileAnalyze: async (id: number, postPsychoFilterDTO: PostPsychoFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startPsychoProfileAnalyze', 'id', id)
            // verify required parameter 'postPsychoFilterDTO' is not null or undefined
            assertParamExists('startPsychoProfileAnalyze', 'postPsychoFilterDTO', postPsychoFilterDTO)
            const localVarPath = `/api/profile/{id}/psycho-profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPsychoFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSniperItem: async (id: string, value: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSniperItem', 'id', id)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('updateSniperItem', 'value', value)
            const localVarPath = `/api/profile/{id}/sniper`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSocialProfile: async (id: number, lri: number, socialProfileData?: SocialProfileData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSocialProfile', 'id', id)
            // verify required parameter 'lri' is not null or undefined
            assertParamExists('updateSocialProfile', 'lri', lri)
            const localVarPath = `/api/profile/{id}/social-profile/{lri}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lri"}}`, encodeURIComponent(String(lri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialProfileData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SynopsisDTO} synopsisDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSynopsis: async (id: number, synopsisDTO: SynopsisDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSynopsis', 'id', id)
            // verify required parameter 'synopsisDTO' is not null or undefined
            assertParamExists('updateSynopsis', 'synopsisDTO', synopsisDTO)
            const localVarPath = `/api/profile/{id}/synopsis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(synopsisDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithProfileApi - functional programming interface
 * @export
 */
export const ApiToWorkWithProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmail(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmail(id, profileModificationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHydraItemToProfileInfo(id: number, lri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHydraItemToProfileInfo(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addImage(id: number, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addImage(id, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addName(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addName(id, profileModificationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPassword(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPassword(id, profileModificationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhone(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPhone(id, profileModificationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProfileItemToProfile(id: number, lri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProfileItemToProfile(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialProfileData} socialProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSocialProfile(id: number, socialProfileData: SocialProfileData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSocialProfile(id, socialProfileData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyzeEntities(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyzeEntities(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfileTarget(inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfileTarget(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSocialProfile(id: number, lri: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSocialProfile(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FetchMoreFriendsReq} fetchMoreFriendsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMoreFriends(id: number, fetchMoreFriendsReq: FetchMoreFriendsReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMoreFriends(id, fetchMoreFriendsReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalFriends(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalFriendsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditionalFriends(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvataTaskCountInQueue(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvataTaskCountInQueue(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileContactInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeepWeb(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeepWebDTOView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeepWeb(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFriends(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFriends(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralStat(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileGeneralStatDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralStat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageSearchResult(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageSearchResultDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageSearchResult(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalInformation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalInformation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhotos(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PhotoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhotos(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferences(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfilePreferenceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreferences(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE'} [socialNetwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileActivityStat(id: number, socialNetwork?: 'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileActivityStatDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileActivityStat(id, socialNetwork, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileLocationStat(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<ProfileLocationStatDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileLocationStat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfilePostStat(id: number, dateRangeDTO: DateRangeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfilePostStat(id, dateRangeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPsychoProfile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPsychoProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSynopsis(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSynopsis(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebResults(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebResults(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incrementalSearchSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incrementalSearchSocialProfile(id, lri, socialProfileData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileAssignProfileDataItem(id: number, profileItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileAssignProfileDataItem(id, profileItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetPosts(id: number, postFilterDTO: PostFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetPosts(id, postFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetSearchProgress(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSearchProgressDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetSearchProgress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePostAnalyze(id: string, postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePostAnalyze(id, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reRunLookup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reRunLookup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reRunSearchCrawler(id: number, lri: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RerunSearchCrawlerStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reRunSearchCrawler(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeHydraItemToProfileInfo(id: number, lri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeHydraItemToProfileInfo(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProfileItemFromProfile(id: number, lri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProfileItemFromProfile(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runGetContactLookup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runGetContactLookup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runLookup(id: number, lri: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runLookup(id, lri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTelegramLookup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTelegramLookup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchHistory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchHistoryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPsychoFilterDTO} postPsychoFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPsychoProfileAnalyze(id: number, postPsychoFilterDTO: PostPsychoFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPsychoProfileAnalyze(id, postPsychoFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSniperItem(id: string, value: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSniperItem(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSocialProfile(id, lri, socialProfileData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SynopsisDTO} synopsisDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSynopsis(id: number, synopsisDTO: SynopsisDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSynopsis(id, synopsisDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithProfileApi - factory interface
 * @export
 */
export const ApiToWorkWithProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmail(id: number, profileModificationDTO: ProfileModificationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addEmail(id, profileModificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHydraItemToProfileInfo(id: number, lri: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addHydraItemToProfileInfo(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImage(id: number, inlineObject?: InlineObject, options?: any): AxiosPromise<boolean> {
            return localVarFp.addImage(id, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addName(id: number, profileModificationDTO: ProfileModificationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addName(id, profileModificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPassword(id: number, profileModificationDTO: ProfileModificationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addPassword(id, profileModificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfileModificationDTO} profileModificationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhone(id: number, profileModificationDTO: ProfileModificationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addPhone(id, profileModificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfileItemToProfile(id: number, lri: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addProfileItemToProfile(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialProfileData} socialProfileData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSocialProfile(id: number, socialProfileData: SocialProfileData, options?: any): AxiosPromise<boolean> {
            return localVarFp.addSocialProfile(id, socialProfileData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeEntities(body: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.analyzeEntities(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileTarget(inlineObject1?: InlineObject1, options?: any): AxiosPromise<Array<ProfileInfo>> {
            return localVarFp.createProfileTarget(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSocialProfile(id: number, lri: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteSocialProfile(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FetchMoreFriendsReq} fetchMoreFriendsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMoreFriends(id: number, fetchMoreFriendsReq: FetchMoreFriendsReq, options?: any): AxiosPromise<LookupResponseDTO> {
            return localVarFp.fetchMoreFriends(id, fetchMoreFriendsReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalFriends(id: number, options?: any): AxiosPromise<AdditionalFriendsResp> {
            return localVarFp.getAdditionalFriends(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvataTaskCountInQueue(options?: any): AxiosPromise<object> {
            return localVarFp.getAvataTaskCountInQueue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactInfo(id: number, options?: any): AxiosPromise<Array<ProfileContactInfoDTO>> {
            return localVarFp.getContactInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepWeb(id: number, options?: any): AxiosPromise<DeepWebDTOView> {
            return localVarFp.getDeepWeb(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriends(id: number, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getFriends(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStat(id: number, options?: any): AxiosPromise<ProfileGeneralStatDTO> {
            return localVarFp.getGeneralStat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageSearchResult(id: number, options?: any): AxiosPromise<Array<ImageSearchResultDTO>> {
            return localVarFp.getImageSearchResult(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInformation(id: number, options?: any): AxiosPromise<PersonalInfoDTO> {
            return localVarFp.getPersonalInformation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhotos(id: number, options?: any): AxiosPromise<Array<PhotoDTO>> {
            return localVarFp.getPhotos(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferences(id: number, options?: any): AxiosPromise<Array<ProfilePreferenceDTO>> {
            return localVarFp.getPreferences(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(id: number, options?: any): AxiosPromise<ProfileInfo> {
            return localVarFp.getProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE'} [socialNetwork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileActivityStat(id: number, socialNetwork?: 'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE', options?: any): AxiosPromise<ProfileActivityStatDTO> {
            return localVarFp.getProfileActivityStat(id, socialNetwork, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileLocationStat(id: number, options?: any): AxiosPromise<Set<ProfileLocationStatDTO>> {
            return localVarFp.getProfileLocationStat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilePostStat(id: number, dateRangeDTO: DateRangeDTO, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getProfilePostStat(id, dateRangeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPsychoProfile(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.getPsychoProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSynopsis(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSynopsis(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebResults(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.getWebResults(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementalSearchSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: any): AxiosPromise<boolean> {
            return localVarFp.incrementalSearchSocialProfile(id, lri, socialProfileData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAssignProfileDataItem(id: number, profileItemId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileAssignProfileDataItem(id, profileItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetPosts(id: number, postFilterDTO: PostFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.profileGetPosts(id, postFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetSearchProgress(id: number, options?: any): AxiosPromise<ProfileSearchProgressDTO> {
            return localVarFp.profileGetSearchProgress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePing(options?: any): AxiosPromise<string> {
            return localVarFp.profilePing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePostAnalyze(id: string, postId: string, options?: any): AxiosPromise<object> {
            return localVarFp.profilePostAnalyze(id, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reRunLookup(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.reRunLookup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reRunSearchCrawler(id: number, lri: number, options?: any): AxiosPromise<RerunSearchCrawlerStatusDTO> {
            return localVarFp.reRunSearchCrawler(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeHydraItemToProfileInfo(id: number, lri: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeHydraItemToProfileInfo(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProfileItemFromProfile(id: number, lri: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeProfileItemFromProfile(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGetContactLookup(id: number, options?: any): AxiosPromise<LookupResponseDTO> {
            return localVarFp.runGetContactLookup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runLookup(id: number, lri: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.runLookup(id, lri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTelegramLookup(id: number, options?: any): AxiosPromise<LookupResponseDTO> {
            return localVarFp.runTelegramLookup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHistory(id: number, options?: any): AxiosPromise<Array<SearchHistoryDTO>> {
            return localVarFp.searchHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPsychoFilterDTO} postPsychoFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPsychoProfileAnalyze(id: number, postPsychoFilterDTO: PostPsychoFilterDTO, options?: any): AxiosPromise<object> {
            return localVarFp.startPsychoProfileAnalyze(id, postPsychoFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSniperItem(id: string, value: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.updateSniperItem(id, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} lri 
         * @param {SocialProfileData} [socialProfileData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateSocialProfile(id, lri, socialProfileData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SynopsisDTO} synopsisDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSynopsis(id: number, synopsisDTO: SynopsisDTO, options?: any): AxiosPromise<string> {
            return localVarFp.updateSynopsis(id, synopsisDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithProfileApi - object-oriented interface
 * @export
 * @class ApiToWorkWithProfileApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithProfileApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {ProfileModificationDTO} profileModificationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addEmail(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addEmail(id, profileModificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addHydraItemToProfileInfo(id: number, lri: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addHydraItemToProfileInfo(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addImage(id: number, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addImage(id, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfileModificationDTO} profileModificationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addName(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addName(id, profileModificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfileModificationDTO} profileModificationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addPassword(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addPassword(id, profileModificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfileModificationDTO} profileModificationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addPhone(id: number, profileModificationDTO: ProfileModificationDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addPhone(id, profileModificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addProfileItemToProfile(id: number, lri: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addProfileItemToProfile(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SocialProfileData} socialProfileData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public addSocialProfile(id: number, socialProfileData: SocialProfileData, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).addSocialProfile(id, socialProfileData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public analyzeEntities(body: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).analyzeEntities(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public createProfileTarget(inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).createProfileTarget(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public deleteProfile(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).deleteProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public deleteSocialProfile(id: number, lri: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).deleteSocialProfile(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FetchMoreFriendsReq} fetchMoreFriendsReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public fetchMoreFriends(id: number, fetchMoreFriendsReq: FetchMoreFriendsReq, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).fetchMoreFriends(id, fetchMoreFriendsReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getAdditionalFriends(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getAdditionalFriends(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getAvataTaskCountInQueue(options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getAvataTaskCountInQueue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getContactInfo(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getContactInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getDeepWeb(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getDeepWeb(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getFriends(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getFriends(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getGeneralStat(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getGeneralStat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getImageSearchResult(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getImageSearchResult(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getPersonalInformation(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getPersonalInformation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getPhotos(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getPhotos(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getPreferences(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getPreferences(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getProfile(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE'} [socialNetwork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getProfileActivityStat(id: number, socialNetwork?: 'FB' | 'FACEBOOK' | 'VK' | 'VKONTAKTE' | 'OK' | 'ODNOKLASSNIKI' | 'LINKEDIN' | 'TUMBLR' | 'YOUTUBE' | 'JOBZELLA' | 'TWITTER' | 'FOURSQUARE' | 'ANGELLIST' | 'INSTAGRAM' | 'MY_MAIL_RU' | 'GPLUS' | 'GOOGLE_PLUS' | 'GAB' | 'SNAPCHAT' | 'BADOO' | 'FLING' | 'TPERSONA' | 'PH_NEW_ID' | 'BLACKHATWORLD' | 'DUBSMASH' | 'GEORGIA_CITIZENSHIP' | 'TELEGRAM' | 'SEARCH4FACES' | 'FINDCLONE' | 'WEBVS_PRIMARY' | 'BAIDU' | 'GOOGLESEARCH' | 'MYMAIL' | 'YANDEX' | 'YAHOO' | 'SKYPE' | 'MASSMAPPER' | 'MM' | 'PROFILER' | 'PIPL' | 'HYDRA' | 'TIKTOK' | 'INSTANAVIGATION' | 'SNIPER' | 'PSYCHOPROFILE', options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getProfileActivityStat(id, socialNetwork, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getProfileLocationStat(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getProfileLocationStat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DateRangeDTO} dateRangeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getProfilePostStat(id: number, dateRangeDTO: DateRangeDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getProfilePostStat(id, dateRangeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getPsychoProfile(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getPsychoProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getSynopsis(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getSynopsis(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public getWebResults(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).getWebResults(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} lri 
     * @param {SocialProfileData} [socialProfileData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public incrementalSearchSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).incrementalSearchSocialProfile(id, lri, socialProfileData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} profileItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public profileAssignProfileDataItem(id: number, profileItemId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).profileAssignProfileDataItem(id, profileItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostFilterDTO} postFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public profileGetPosts(id: number, postFilterDTO: PostFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).profileGetPosts(id, postFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public profileGetSearchProgress(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).profileGetSearchProgress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public profilePing(options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).profilePing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public profilePostAnalyze(id: string, postId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).profilePostAnalyze(id, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public reRunLookup(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).reRunLookup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public reRunSearchCrawler(id: number, lri: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).reRunSearchCrawler(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public removeHydraItemToProfileInfo(id: number, lri: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).removeHydraItemToProfileInfo(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public removeProfileItemFromProfile(id: number, lri: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).removeProfileItemFromProfile(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public runGetContactLookup(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).runGetContactLookup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} lri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public runLookup(id: number, lri: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).runLookup(id, lri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public runTelegramLookup(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).runTelegramLookup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public searchHistory(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).searchHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostPsychoFilterDTO} postPsychoFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public startPsychoProfileAnalyze(id: number, postPsychoFilterDTO: PostPsychoFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).startPsychoProfileAnalyze(id, postPsychoFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public updateSniperItem(id: string, value: boolean, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).updateSniperItem(id, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} lri 
     * @param {SocialProfileData} [socialProfileData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public updateSocialProfile(id: number, lri: number, socialProfileData?: SocialProfileData, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).updateSocialProfile(id, lri, socialProfileData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SynopsisDTO} synopsisDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProfileApi
     */
    public updateSynopsis(id: number, synopsisDTO: SynopsisDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProfileApiFp(this.configuration).updateSynopsis(id, synopsisDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithProxiesApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithProxiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProxyServer: async (proxyServerDTO: ProxyServerDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proxyServerDTO' is not null or undefined
            assertParamExists('createProxyServer', 'proxyServerDTO', proxyServerDTO)
            const localVarPath = `/api/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proxyServerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProxyServer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProxyServer', 'id', id)
            const localVarPath = `/api/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {string} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiesListPaged: async (pageSize?: number, pageNum?: number, sort?: string, property?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (property !== undefined) {
                localVarQueryParameter['property'] = property;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxyById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProxyById', 'id', id)
            const localVarPath = `/api/proxies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProxyServer: async (proxyServerDTO: ProxyServerDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proxyServerDTO' is not null or undefined
            assertParamExists('updateProxyServer', 'proxyServerDTO', proxyServerDTO)
            const localVarPath = `/api/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proxyServerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithProxiesApi - functional programming interface
 * @export
 */
export const ApiToWorkWithProxiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithProxiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProxyServer(proxyServerDTO: ProxyServerDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProxyServer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProxyServer(proxyServerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProxyServer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProxyServer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {string} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiesListPaged(pageSize?: number, pageNum?: number, sort?: string, property?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProxyServer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxiesListPaged(pageSize, pageNum, sort, property, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxyById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProxyServer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProxyServer(proxyServerDTO: ProxyServerDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProxyServer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProxyServer(proxyServerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithProxiesApi - factory interface
 * @export
 */
export const ApiToWorkWithProxiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithProxiesApiFp(configuration)
    return {
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProxyServer(proxyServerDTO: ProxyServerDTO, options?: any): AxiosPromise<ProxyServer> {
            return localVarFp.createProxyServer(proxyServerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProxyServer(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteProxyServer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {string} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiesListPaged(pageSize?: number, pageNum?: number, sort?: string, property?: string, options?: any): AxiosPromise<PageProxyServer> {
            return localVarFp.getProxiesListPaged(pageSize, pageNum, sort, property, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxyById(id: string, options?: any): AxiosPromise<ProxyServer> {
            return localVarFp.getProxyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProxyServerDTO} proxyServerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProxyServer(proxyServerDTO: ProxyServerDTO, options?: any): AxiosPromise<ProxyServer> {
            return localVarFp.updateProxyServer(proxyServerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithProxiesApi - object-oriented interface
 * @export
 * @class ApiToWorkWithProxiesApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithProxiesApi extends BaseAPI {
    /**
     * 
     * @param {ProxyServerDTO} proxyServerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProxiesApi
     */
    public createProxyServer(proxyServerDTO: ProxyServerDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProxiesApiFp(this.configuration).createProxyServer(proxyServerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProxiesApi
     */
    public deleteProxyServer(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProxiesApiFp(this.configuration).deleteProxyServer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSize] 
     * @param {number} [pageNum] 
     * @param {string} [sort] 
     * @param {string} [property] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProxiesApi
     */
    public getProxiesListPaged(pageSize?: number, pageNum?: number, sort?: string, property?: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProxiesApiFp(this.configuration).getProxiesListPaged(pageSize, pageNum, sort, property, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProxiesApi
     */
    public getProxyById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithProxiesApiFp(this.configuration).getProxyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProxyServerDTO} proxyServerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithProxiesApi
     */
    public updateProxyServer(proxyServerDTO: ProxyServerDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithProxiesApiFp(this.configuration).updateProxyServer(proxyServerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithQuotaApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithQuotaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testQ: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/quota/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithQuotaApi - functional programming interface
 * @export
 */
export const ApiToWorkWithQuotaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithQuotaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testQ(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testQ(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithQuotaApi - factory interface
 * @export
 */
export const ApiToWorkWithQuotaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithQuotaApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testQ(options?: any): AxiosPromise<string> {
            return localVarFp.testQ(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithQuotaApi - object-oriented interface
 * @export
 * @class ApiToWorkWithQuotaApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithQuotaApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithQuotaApi
     */
    public testQ(options?: AxiosRequestConfig) {
        return ApiToWorkWithQuotaApiFp(this.configuration).testQ(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithRekognitionApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithRekognitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeOptional: async (profileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('analyzeOptional', 'profileId', profileId)
            const localVarPath = `/api/rekognition/analyzeOptional/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (rekognitionImageEnroll: RekognitionImageEnroll, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rekognitionImageEnroll' is not null or undefined
            assertParamExists('delete1', 'rekognitionImageEnroll', rekognitionImageEnroll)
            const localVarPath = `/api/rekognition/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rekognitionImageEnroll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFace: async (rekognitionDetectRequest: RekognitionDetectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rekognitionDetectRequest' is not null or undefined
            assertParamExists('detectFace', 'rekognitionDetectRequest', rekognitionDetectRequest)
            const localVarPath = `/api/rekognition/detect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rekognitionDetectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enroll: async (rekognitionImageEnroll: RekognitionImageEnroll, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rekognitionImageEnroll' is not null or undefined
            assertParamExists('enroll', 'rekognitionImageEnroll', rekognitionImageEnroll)
            const localVarPath = `/api/rekognition/enroll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rekognitionImageEnroll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFaceById', 'id', id)
            const localVarPath = `/api/rekognition/face/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacesByIds: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getFacesByIds', 'requestBody', requestBody)
            const localVarPath = `/api/rekognition/faces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rekognitionPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rekognition/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rekognitionSearch: async (rekognitionDetectRequest: RekognitionDetectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rekognitionDetectRequest' is not null or undefined
            assertParamExists('rekognitionSearch', 'rekognitionDetectRequest', rekognitionDetectRequest)
            const localVarPath = `/api/rekognition/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rekognitionDetectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfiles: async (rekognitionDetectRequest: RekognitionDetectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rekognitionDetectRequest' is not null or undefined
            assertParamExists('searchProfiles', 'rekognitionDetectRequest', rekognitionDetectRequest)
            const localVarPath = `/api/rekognition/searchProfiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rekognitionDetectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithRekognitionApi - functional programming interface
 * @export
 */
export const ApiToWorkWithRekognitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithRekognitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyzeOptional(profileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyzeOptional(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(rekognitionImageEnroll: RekognitionImageEnroll, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(rekognitionImageEnroll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectFace(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectFace(rekognitionDetectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enroll(rekognitionImageEnroll: RekognitionImageEnroll, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enroll(rekognitionImageEnroll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacesByIds(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacesByIds(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rekognitionPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rekognitionPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rekognitionSearch(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rekognitionSearch(rekognitionDetectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfiles(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfiles(rekognitionDetectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithRekognitionApi - factory interface
 * @export
 */
export const ApiToWorkWithRekognitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithRekognitionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeOptional(profileId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.analyzeOptional(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(rekognitionImageEnroll: RekognitionImageEnroll, options?: any): AxiosPromise<boolean> {
            return localVarFp.delete1(rekognitionImageEnroll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFace(rekognitionDetectRequest: RekognitionDetectRequest, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.detectFace(rekognitionDetectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RekognitionImageEnroll} rekognitionImageEnroll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enroll(rekognitionImageEnroll: RekognitionImageEnroll, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.enroll(rekognitionImageEnroll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaceById(id: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getFaceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacesByIds(requestBody: Array<string>, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.getFacesByIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rekognitionPing(options?: any): AxiosPromise<string> {
            return localVarFp.rekognitionPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rekognitionSearch(rekognitionDetectRequest: RekognitionDetectRequest, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.rekognitionSearch(rekognitionDetectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RekognitionDetectRequest} rekognitionDetectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfiles(rekognitionDetectRequest: RekognitionDetectRequest, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.searchProfiles(rekognitionDetectRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithRekognitionApi - object-oriented interface
 * @export
 * @class ApiToWorkWithRekognitionApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithRekognitionApi extends BaseAPI {
    /**
     * 
     * @param {number} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public analyzeOptional(profileId: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).analyzeOptional(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RekognitionImageEnroll} rekognitionImageEnroll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public delete1(rekognitionImageEnroll: RekognitionImageEnroll, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).delete1(rekognitionImageEnroll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RekognitionDetectRequest} rekognitionDetectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public detectFace(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).detectFace(rekognitionDetectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RekognitionImageEnroll} rekognitionImageEnroll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public enroll(rekognitionImageEnroll: RekognitionImageEnroll, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).enroll(rekognitionImageEnroll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public getFaceById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).getFaceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public getFacesByIds(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).getFacesByIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public rekognitionPing(options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).rekognitionPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RekognitionDetectRequest} rekognitionDetectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public rekognitionSearch(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).rekognitionSearch(rekognitionDetectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RekognitionDetectRequest} rekognitionDetectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithRekognitionApi
     */
    public searchProfiles(rekognitionDetectRequest: RekognitionDetectRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithRekognitionApiFp(this.configuration).searchProfiles(rekognitionDetectRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithSpeechRecognizeAndAnalyticApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithSpeechRecognizeAndAnalyticApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/speech/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromtById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePromtById', 'id', id)
            const localVarPath = `/api/speech/promt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPromt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/speech/promt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByName: async (fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getFileByName', 'fileName', fileName)
            const localVarPath = `/api/speech/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromtById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPromtById', 'id', id)
            const localVarPath = `/api/speech/promt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSpeechById', 'id', id)
            const localVarPath = `/api/speech/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpeechSearchFilter} speechSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechRequests: async (speechSearchFilter: SpeechSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'speechSearchFilter' is not null or undefined
            assertParamExists('getSpeechRequests', 'speechSearchFilter', speechSearchFilter)
            const localVarPath = `/api/speech/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speechSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedLanguage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/speech/lang`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromtAnalyzeRequest} promtAnalyzeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promtAnalyze: async (promtAnalyzeRequest: PromtAnalyzeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promtAnalyzeRequest' is not null or undefined
            assertParamExists('promtAnalyze', 'promtAnalyzeRequest', promtAnalyzeRequest)
            const localVarPath = `/api/speech/custom/ai`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promtAnalyzeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActivePromtById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setActivePromtById', 'id', id)
            const localVarPath = `/api/speech/promt/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisiblePromtById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setVisiblePromtById', 'id', id)
            const localVarPath = `/api/speech/promt/{id}/visible`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechAnalyze: async (inlineObject5?: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/speech/stt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textAnalyze: async (inlineObject4?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/speech/text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} model 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAIRequest: async (id: string, model: string, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAIRequest', 'id', id)
            // verify required parameter 'model' is not null or undefined
            assertParamExists('updateAIRequest', 'model', model)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateAIRequest', 'lang', lang)
            const localVarPath = `/api/speech/response/{id}/model/{model}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromtById: async (id: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePromtById', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePromtById', 'body', body)
            const localVarPath = `/api/speech/promt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithSpeechRecognizeAndAnalyticApi - functional programming interface
 * @export
 */
export const ApiToWorkWithSpeechRecognizeAndAnalyticApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithSpeechRecognizeAndAnalyticApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePromtById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePromtById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPromt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPromt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileByName(fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileByName(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromtById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromtById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpeechById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpeechById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SpeechSearchFilter} speechSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpeechRequests(speechSearchFilter: SpeechSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpeechRequests(speechSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedLanguage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedLanguage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromtAnalyzeRequest} promtAnalyzeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promtAnalyze(promtAnalyzeRequest: PromtAnalyzeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promtAnalyze(promtAnalyzeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActivePromtById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActivePromtById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setVisiblePromtById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setVisiblePromtById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speechAnalyze(inlineObject5?: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speechAnalyze(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textAnalyze(inlineObject4?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textAnalyze(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} model 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAIRequest(id: string, model: string, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAIRequest(id, model, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePromtById(id: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePromtById(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithSpeechRecognizeAndAnalyticApi - factory interface
 * @export
 */
export const ApiToWorkWithSpeechRecognizeAndAnalyticApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromtById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePromtById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPromt(options?: any): AxiosPromise<object> {
            return localVarFp.getAllPromt(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByName(fileName: string, options?: any): AxiosPromise<object> {
            return localVarFp.getFileByName(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromtById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPromtById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getSpeechById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeechSearchFilter} speechSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechRequests(speechSearchFilter: SpeechSearchFilter, options?: any): AxiosPromise<object> {
            return localVarFp.getSpeechRequests(speechSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedLanguage(options?: any): AxiosPromise<Array<LanguageDTO>> {
            return localVarFp.getSupportedLanguage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromtAnalyzeRequest} promtAnalyzeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promtAnalyze(promtAnalyzeRequest: PromtAnalyzeRequest, options?: any): AxiosPromise<object> {
            return localVarFp.promtAnalyze(promtAnalyzeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActivePromtById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.setActivePromtById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisiblePromtById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.setVisiblePromtById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechAnalyze(inlineObject5?: InlineObject5, options?: any): AxiosPromise<object> {
            return localVarFp.speechAnalyze(inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textAnalyze(inlineObject4?: InlineObject4, options?: any): AxiosPromise<object> {
            return localVarFp.textAnalyze(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} model 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAIRequest(id: string, model: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAIRequest(id, model, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromtById(id: string, body: string, options?: any): AxiosPromise<object> {
            return localVarFp.updatePromtById(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithSpeechRecognizeAndAnalyticApi - object-oriented interface
 * @export
 * @class ApiToWorkWithSpeechRecognizeAndAnalyticApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithSpeechRecognizeAndAnalyticApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public _delete(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public deletePromtById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).deletePromtById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getAllPromt(options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getAllPromt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getFileByName(fileName: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getFileByName(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getPromtById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getPromtById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getSpeechById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getSpeechById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeechSearchFilter} speechSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getSpeechRequests(speechSearchFilter: SpeechSearchFilter, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getSpeechRequests(speechSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public getSupportedLanguage(options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).getSupportedLanguage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromtAnalyzeRequest} promtAnalyzeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public promtAnalyze(promtAnalyzeRequest: PromtAnalyzeRequest, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).promtAnalyze(promtAnalyzeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public setActivePromtById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).setActivePromtById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public setVisiblePromtById(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).setVisiblePromtById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject5} [inlineObject5] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public speechAnalyze(inlineObject5?: InlineObject5, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).speechAnalyze(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public textAnalyze(inlineObject4?: InlineObject4, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).textAnalyze(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} model 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public updateAIRequest(id: string, model: string, lang: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).updateAIRequest(id, model, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithSpeechRecognizeAndAnalyticApi
     */
    public updatePromtById(id: string, body: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithSpeechRecognizeAndAnalyticApiFp(this.configuration).updatePromtById(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiToWorkWithWebintApi - axios parameter creator
 * @export
 */
export const ApiToWorkWithWebintApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {WebintGroupDTO} webintGroupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroup: async (id: string, webintGroupDTO: WebintGroupDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addGroup', 'id', id)
            // verify required parameter 'webintGroupDTO' is not null or undefined
            assertParamExists('addGroup', 'webintGroupDTO', webintGroupDTO)
            const localVarPath = `/api/webint/{id}/add-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webintGroupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebintById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWebintById', 'id', id)
            const localVarPath = `/api/webint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {GroupActivityFilterDTO} groupActivityFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupActivity: async (invNumber: number, groupActivityFilterDTO: GroupActivityFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('getGroupActivity', 'invNumber', invNumber)
            // verify required parameter 'groupActivityFilterDTO' is not null or undefined
            assertParamExists('getGroupActivity', 'groupActivityFilterDTO', groupActivityFilterDTO)
            const localVarPath = `/api/webint/inv/{invNumber}/group-activity`
                .replace(`{${"invNumber"}}`, encodeURIComponent(String(invNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupActivityFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroups', 'id', id)
            // verify required parameter 'crawlerItemFilterDTO' is not null or undefined
            assertParamExists('getGroups', 'crawlerItemFilterDTO', crawlerItemFilterDTO)
            const localVarPath = `/api/webint/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crawlerItemFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages: async (id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImages', 'id', id)
            // verify required parameter 'crawlerItemFilterDTO' is not null or undefined
            assertParamExists('getImages', 'crawlerItemFilterDTO', crawlerItemFilterDTO)
            const localVarPath = `/api/webint/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crawlerItemFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvGroups: async (invNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invNumber' is not null or undefined
            assertParamExists('getInvGroups', 'invNumber', invNumber)
            const localVarPath = `/api/webint/inv/{invNumber}/groups`
                .replace(`{${"invNumber"}}`, encodeURIComponent(String(invNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostAvatar: async (postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getPostAvatar', 'postId', postId)
            const localVarPath = `/api/webint/post/{postId}/avatar`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostHistory: async (id: string, postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPostHistory', 'id', id)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getPostHistory', 'postId', postId)
            const localVarPath = `/api/webint/{id}/post-history/{postId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargets: async (id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTargets', 'id', id)
            // verify required parameter 'crawlerItemFilterDTO' is not null or undefined
            assertParamExists('getTargets', 'crawlerItemFilterDTO', crawlerItemFilterDTO)
            const localVarPath = `/api/webint/{id}/targets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crawlerItemFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopPosts: async (from?: number, to?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webint/post/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrends: async (keyword: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getTrends', 'keyword', keyword)
            const localVarPath = `/api/webint/trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} isMulti 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendsSerpApi: async (isMulti: boolean, keyword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isMulti' is not null or undefined
            assertParamExists('getTrendsSerpApi', 'isMulti', isMulti)
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getTrendsSerpApi', 'keyword', keyword)
            const localVarPath = `/api/webint/trend-serpapi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isMulti !== undefined) {
                localVarQueryParameter['isMulti'] = isMulti;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebintUserStatFilterDTO} webintUserStatFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStat: async (webintUserStatFilterDTO: WebintUserStatFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webintUserStatFilterDTO' is not null or undefined
            assertParamExists('getUserStat', 'webintUserStatFilterDTO', webintUserStatFilterDTO)
            const localVarPath = `/api/webint/user-stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webintUserStatFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideos: async (id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVideos', 'id', id)
            // verify required parameter 'crawlerItemFilterDTO' is not null or undefined
            assertParamExists('getVideos', 'crawlerItemFilterDTO', crawlerItemFilterDTO)
            const localVarPath = `/api/webint/{id}/videos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(crawlerItemFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWebintById', 'id', id)
            const localVarPath = `/api/webint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintPostStat: async (id: string, dateRangeDTO: DateRangeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWebintPostStat', 'id', id)
            // verify required parameter 'dateRangeDTO' is not null or undefined
            assertParamExists('getWebintPostStat', 'dateRangeDTO', dateRangeDTO)
            const localVarPath = `/api/webint/{id}/post-stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateRangeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintStat: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webint/stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawler: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runCrawler', 'id', id)
            const localVarPath = `/api/webint/{id}/manual-run-crawler`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintAssignProfileDataItem: async (id: number, profileItemId: string, number: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webintAssignProfileDataItem', 'id', id)
            // verify required parameter 'profileItemId' is not null or undefined
            assertParamExists('webintAssignProfileDataItem', 'profileItemId', profileItemId)
            // verify required parameter 'number' is not null or undefined
            assertParamExists('webintAssignProfileDataItem', 'number', number)
            const localVarPath = `/api/webint/{id}/assignProfileDataItem/{profileItemId}/case/{number}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"profileItemId"}}`, encodeURIComponent(String(profileItemId)))
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebintDTO} webintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintCreateTarget1: async (id: string, webintDTO: WebintDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webintCreateTarget1', 'id', id)
            // verify required parameter 'webintDTO' is not null or undefined
            assertParamExists('webintCreateTarget1', 'webintDTO', webintDTO)
            const localVarPath = `/api/webint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webintDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintGetPosts: async (id: string, postFilterDTO: PostFilterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webintGetPosts', 'id', id)
            // verify required parameter 'postFilterDTO' is not null or undefined
            assertParamExists('webintGetPosts', 'postFilterDTO', postFilterDTO)
            const localVarPath = `/api/webint/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintGetSearchProgress: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webintGetSearchProgress', 'id', id)
            const localVarPath = `/api/webint/{id}/search-progress`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintPostAnalyze: async (id: string, postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webintPostAnalyze', 'id', id)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('webintPostAnalyze', 'postId', postId)
            const localVarPath = `/api/webint/{id}/post/{postId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiToWorkWithWebintApi - functional programming interface
 * @export
 */
export const ApiToWorkWithWebintApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiToWorkWithWebintApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {WebintGroupDTO} webintGroupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGroup(id: string, webintGroupDTO: WebintGroupDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGroup(id, webintGroupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebintById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebintById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {GroupActivityFilterDTO} groupActivityFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupActivity(invNumber: number, groupActivityFilterDTO: GroupActivityFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupActivity(invNumber, groupActivityFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(id, crawlerItemFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImages(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImages(id, crawlerItemFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvGroups(invNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebintInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvGroups(invNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostAvatar(postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostAvatar(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostHistory(id: string, postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostHistory(id, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargets(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargets(id, crawlerItemFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopPosts(from?: number, to?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopPosts(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrends(keyword: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrends(keyword, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} isMulti 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrendsSerpApi(isMulti: boolean, keyword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrendsSerpApi(isMulti, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WebintUserStatFilterDTO} webintUserStatFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStat(webintUserStatFilterDTO: WebintUserStatFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStat(webintUserStatFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideos(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideos(id, crawlerItemFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebintById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebintById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebintPostStat(id: string, dateRangeDTO: DateRangeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebintPostStat(id, dateRangeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebintStat(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebintGeneralStatDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebintStat(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCrawler(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCrawler(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webintAssignProfileDataItem(id: number, profileItemId: string, number: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webintAssignProfileDataItem(id, profileItemId, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {WebintDTO} webintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webintCreateTarget1(id: string, webintDTO: WebintDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webintCreateTarget1(id, webintDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webintGetPosts(id: string, postFilterDTO: PostFilterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webintGetPosts(id, postFilterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webintGetSearchProgress(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrawlerProgressDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webintGetSearchProgress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webintPostAnalyze(id: string, postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webintPostAnalyze(id, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiToWorkWithWebintApi - factory interface
 * @export
 */
export const ApiToWorkWithWebintApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiToWorkWithWebintApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {WebintGroupDTO} webintGroupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroup(id: string, webintGroupDTO: WebintGroupDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addGroup(id, webintGroupDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebintById(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteWebintById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {GroupActivityFilterDTO} groupActivityFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupActivity(invNumber: number, groupActivityFilterDTO: GroupActivityFilterDTO, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getGroupActivity(invNumber, groupActivityFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getGroups(id, crawlerItemFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getImages(id, crawlerItemFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvGroups(invNumber: number, options?: any): AxiosPromise<Array<WebintInfoDTO>> {
            return localVarFp.getInvGroups(invNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostAvatar(postId: string, options?: any): AxiosPromise<AvatarDto> {
            return localVarFp.getPostAvatar(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostHistory(id: string, postId: string, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getPostHistory(id, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargets(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getTargets(id, crawlerItemFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopPosts(from?: number, to?: number, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getTopPosts(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrends(keyword: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getTrends(keyword, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} isMulti 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrendsSerpApi(isMulti: boolean, keyword: string, options?: any): AxiosPromise<object> {
            return localVarFp.getTrendsSerpApi(isMulti, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebintUserStatFilterDTO} webintUserStatFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStat(webintUserStatFilterDTO: WebintUserStatFilterDTO, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getUserStat(webintUserStatFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideos(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.getVideos(id, crawlerItemFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintById(id: number, options?: any): AxiosPromise<WebintDTO> {
            return localVarFp.getWebintById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DateRangeDTO} dateRangeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintPostStat(id: string, dateRangeDTO: DateRangeDTO, options?: any): AxiosPromise<object> {
            return localVarFp.getWebintPostStat(id, dateRangeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebintStat(options?: any): AxiosPromise<WebintGeneralStatDTO> {
            return localVarFp.getWebintStat(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawler(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.runCrawler(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} profileItemId 
         * @param {number} number 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintAssignProfileDataItem(id: number, profileItemId: string, number: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.webintAssignProfileDataItem(id, profileItemId, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WebintDTO} webintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintCreateTarget1(id: string, webintDTO: WebintDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.webintCreateTarget1(id, webintDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PostFilterDTO} postFilterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintGetPosts(id: string, postFilterDTO: PostFilterDTO, options?: any): AxiosPromise<Array<CrawlerResponseDTO>> {
            return localVarFp.webintGetPosts(id, postFilterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintGetSearchProgress(id: string, options?: any): AxiosPromise<Array<CrawlerProgressDTO>> {
            return localVarFp.webintGetSearchProgress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webintPostAnalyze(id: string, postId: string, options?: any): AxiosPromise<object> {
            return localVarFp.webintPostAnalyze(id, postId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiToWorkWithWebintApi - object-oriented interface
 * @export
 * @class ApiToWorkWithWebintApi
 * @extends {BaseAPI}
 */
export class ApiToWorkWithWebintApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {WebintGroupDTO} webintGroupDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public addGroup(id: string, webintGroupDTO: WebintGroupDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).addGroup(id, webintGroupDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public deleteWebintById(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).deleteWebintById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {GroupActivityFilterDTO} groupActivityFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getGroupActivity(invNumber: number, groupActivityFilterDTO: GroupActivityFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getGroupActivity(invNumber, groupActivityFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getGroups(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getGroups(id, crawlerItemFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getImages(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getImages(id, crawlerItemFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getInvGroups(invNumber: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getInvGroups(invNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getPostAvatar(postId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getPostAvatar(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getPostHistory(id: string, postId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getPostHistory(id, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getTargets(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getTargets(id, crawlerItemFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [from] 
     * @param {number} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getTopPosts(from?: number, to?: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getTopPosts(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} keyword 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getTrends(keyword: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getTrends(keyword, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} isMulti 
     * @param {string} keyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getTrendsSerpApi(isMulti: boolean, keyword: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getTrendsSerpApi(isMulti, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebintUserStatFilterDTO} webintUserStatFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getUserStat(webintUserStatFilterDTO: WebintUserStatFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getUserStat(webintUserStatFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CrawlerItemFilterDTO} crawlerItemFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getVideos(id: string, crawlerItemFilterDTO: CrawlerItemFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getVideos(id, crawlerItemFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getWebintById(id: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getWebintById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DateRangeDTO} dateRangeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getWebintPostStat(id: string, dateRangeDTO: DateRangeDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getWebintPostStat(id, dateRangeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public getWebintStat(options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).getWebintStat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public runCrawler(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).runCrawler(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} profileItemId 
     * @param {number} number 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public webintAssignProfileDataItem(id: number, profileItemId: string, number: number, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).webintAssignProfileDataItem(id, profileItemId, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WebintDTO} webintDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public webintCreateTarget1(id: string, webintDTO: WebintDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).webintCreateTarget1(id, webintDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PostFilterDTO} postFilterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public webintGetPosts(id: string, postFilterDTO: PostFilterDTO, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).webintGetPosts(id, postFilterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public webintGetSearchProgress(id: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).webintGetSearchProgress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiToWorkWithWebintApi
     */
    public webintPostAnalyze(id: string, postId: string, options?: AxiosRequestConfig) {
        return ApiToWorkWithWebintApiFp(this.configuration).webintPostAnalyze(id, postId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authenticateUser', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('logout', 'username', username)
            const localVarPath = `/api/auth/logout/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(loginDto: LoginDto, options?: any): AxiosPromise<JwtAuthenticationDto> {
            return localVarFp.authenticateUser(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.logout(username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUser(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authenticateUser(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public logout(username: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).logout(username, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobsAPIApi - axios parameter creator
 * @export
 */
export const JobsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsyncJobStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAsyncJobStatus', 'id', id)
            const localVarPath = `/api/jobs/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsAPIApi - functional programming interface
 * @export
 */
export const JobsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsyncJobStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsyncTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsyncJobStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobsAPIApi - factory interface
 * @export
 */
export const JobsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsAPIApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsyncJobStatus(id: string, options?: any): AxiosPromise<AsyncTaskDTO> {
            return localVarFp.getAsyncJobStatus(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsAPIApi - object-oriented interface
 * @export
 * @class JobsAPIApi
 * @extends {BaseAPI}
 */
export class JobsAPIApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsAPIApi
     */
    public getAsyncJobStatus(id: string, options?: AxiosRequestConfig) {
        return JobsAPIApiFp(this.configuration).getAsyncJobStatus(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApiGrpcExceptionApi - axios parameter creator
 * @export
 */
export const TestApiGrpcExceptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGRPCException: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/grpc/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApiGrpcExceptionApi - functional programming interface
 * @export
 */
export const TestApiGrpcExceptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiGrpcExceptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testGRPCException(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testGRPCException(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApiGrpcExceptionApi - factory interface
 * @export
 */
export const TestApiGrpcExceptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiGrpcExceptionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGRPCException(options?: any): AxiosPromise<string> {
            return localVarFp.testGRPCException(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApiGrpcExceptionApi - object-oriented interface
 * @export
 * @class TestApiGrpcExceptionApi
 * @extends {BaseAPI}
 */
export class TestApiGrpcExceptionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiGrpcExceptionApi
     */
    public testGRPCException(options?: AxiosRequestConfig) {
        return TestApiGrpcExceptionApiFp(this.configuration).testGRPCException(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAPIApi - axios parameter creator
 * @export
 */
export const UserAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullDeleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fullDeleteUser', 'id', id)
            const localVarPath = `/api/user/{id}/full`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/userInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoByUserName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getUserInfoByUserName', 'name', name)
            const localVarPath = `/api/user/userInfo/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserFilter} userFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (userFilter: UserFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userFilter' is not null or undefined
            assertParamExists('getUsers', 'userFilter', userFilter)
            const localVarPath = `/api/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('registerUser', 'user', user)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserNameDTO} userNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameUser: async (id: string, userNameDTO: UserNameDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renameUser', 'id', id)
            // verify required parameter 'userNameDTO' is not null or undefined
            assertParamExists('renameUser', 'userNameDTO', userNameDTO)
            const localVarPath = `/api/user/{id}/rename`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNameDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAPIApi - functional programming interface
 * @export
 */
export const UserAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fullDeleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fullDeleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoByUserName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoByUserName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserFilter} userFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(userFilter: UserFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(userFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserNameDTO} userNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameUser(id: string, userNameDTO: UserNameDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameUser(id, userNameDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAPIApi - factory interface
 * @export
 */
export const UserAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAPIApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullDeleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.fullDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoByUserName(name: string, options?: any): AxiosPromise<UserDetails> {
            return localVarFp.getUserInfoByUserName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserFilter} userFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(userFilter: UserFilter, options?: any): AxiosPromise<PageUser> {
            return localVarFp.getUsers(userFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(user: User, options?: any): AxiosPromise<void> {
            return localVarFp.registerUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserNameDTO} userNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameUser(id: string, userNameDTO: UserNameDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.renameUser(id, userNameDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, user: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(id, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAPIApi - object-oriented interface
 * @export
 * @class UserAPIApi
 * @extends {BaseAPI}
 */
export class UserAPIApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public fullDeleteUser(id: string, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).fullDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public getUserInfoByUserName(name: string, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).getUserInfoByUserName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserFilter} userFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public getUsers(userFilter: UserFilter, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).getUsers(userFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public registerUser(user: User, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).registerUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserNameDTO} userNameDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public renameUser(id: string, userNameDTO: UserNameDTO, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).renameUser(id, userNameDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public updateUser(id: string, user: User, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).updateUser(id, user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhookToGetSmsApi - axios parameter creator
 * @export
 */
export const WebhookToGetSmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SmsDinstarDTO} smsDinstarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDinstarSms: async (smsDinstarDTO: SmsDinstarDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsDinstarDTO' is not null or undefined
            assertParamExists('addDinstarSms', 'smsDinstarDTO', smsDinstarDTO)
            const localVarPath = `/rest-api/sms/webhook/dinstar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsDinstarDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SmsDTO} smsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHg7000Sms: async (smsDTO: SmsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsDTO' is not null or undefined
            assertParamExists('addHg7000Sms', 'smsDTO', smsDTO)
            const localVarPath = `/rest-api/sms/webhook/hg7000`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookToGetSmsApi - functional programming interface
 * @export
 */
export const WebhookToGetSmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookToGetSmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SmsDinstarDTO} smsDinstarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDinstarSms(smsDinstarDTO: SmsDinstarDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDinstarSms(smsDinstarDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SmsDTO} smsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHg7000Sms(smsDTO: SmsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHg7000Sms(smsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhookToGetSmsApi - factory interface
 * @export
 */
export const WebhookToGetSmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookToGetSmsApiFp(configuration)
    return {
        /**
         * 
         * @param {SmsDinstarDTO} smsDinstarDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDinstarSms(smsDinstarDTO: SmsDinstarDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.addDinstarSms(smsDinstarDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SmsDTO} smsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHg7000Sms(smsDTO: SmsDTO, options?: any): AxiosPromise<SmsDTO> {
            return localVarFp.addHg7000Sms(smsDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookToGetSmsApi - object-oriented interface
 * @export
 * @class WebhookToGetSmsApi
 * @extends {BaseAPI}
 */
export class WebhookToGetSmsApi extends BaseAPI {
    /**
     * 
     * @param {SmsDinstarDTO} smsDinstarDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookToGetSmsApi
     */
    public addDinstarSms(smsDinstarDTO: SmsDinstarDTO, options?: AxiosRequestConfig) {
        return WebhookToGetSmsApiFp(this.configuration).addDinstarSms(smsDinstarDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SmsDTO} smsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookToGetSmsApi
     */
    public addHg7000Sms(smsDTO: SmsDTO, options?: AxiosRequestConfig) {
        return WebhookToGetSmsApiFp(this.configuration).addHg7000Sms(smsDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


