import styled from "styled-components";
import {Layout} from "antd";
import {Link} from "react-router-dom";

const { Header} = Layout;

export const CustomHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  line-height: 56px;
  box-shadow: inset 0px -1px 0px ${props => props.theme.header_bg};
  /*filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));*/
  filter: drop-shadow(0px 2px 10px ${props => props.theme.header_bg}3F);
  background: ${props => props.theme.header_bg};
`;

export const NavbarBrand = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
