import i18n from "i18next";
import moment from "moment";

//yd
const transformSource0 = (chunks = []) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  chunks?.forEach((chunk, idx) => {
    const [alter] = chunk.alternatives;
    const {words} = alter;
    const start = words[0].startTime;
    const end = words[words.length - 1].endTime;
    wordsArr.push(...words.map(wr => wr.word));
    wordsCount += words.length;
    out.push({
      text: alter.text,
      duration: `${start} - ${end}`,
      //start: Math.floor(start.replace('s', '') * 1),
      //end: Math.ceil(end.replace('s', '') * 1),
      start: start.replace('s', '') * 1,
      end: end.replace('s', '') * 1,
      speaker: idx % 2 == 0 ? 1 : 2,
    })
  })
  return {phrases: out, wordsCount, words: wordsArr};
}

//gl
const transformSource1 = (chunks = {}) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  const transcript = chunks?.transcript;
  const results = transcript?.results || [];

  results.forEach((res, idx) => {
    const alt = res.alternatives?.[0];
    if (alt) {
      if (alt?.words && alt?.words?.length > 0) {
        //console.log('word', alt.words);
        const start = alt.words[0]?.start_offset || '0';
        const end = alt.words[alt.words.length - 1].end_offset;
        wordsCount += alt.words.length;
        wordsArr.push(...alt.words.map(w => w.word))
        out.push({
          text: alt.transcript,
          duration: `${start} - ${end}`,
          start: start.replace('s', '') * 1,
          end: end.replace('s', '') * 1,
          speaker: idx % 2 == 0 ? 1 : 2,
        })
      } else if (alt?.transcript && alt?.result_end_offset) {
        /*const start = '0s';
        const end = alt?.result_end_offset;
        wordsCount += alt.transcript.length;
        wordsArr.push(...alt.words.map(w => w.word))
        out.push({
          text: alt.transcript,
          duration: `${start} - ${end}`,
          start: start.replace('s', '') * 1,
          end: end.replace('s', '') * 1,
          speaker: idx % 2 == 0 ? 1 : 2,
        })*/
      }
    }
  })

  return {phrases: out, wordsCount, words: wordsArr};
}

//am
const transformSource2 = (chunks = {}) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  const {results = {}} = chunks;
  const {items, transcripts} = results;

  const filteredItems = items?.filter(off => off?.type === 'pronunciation');
  let speaker = filteredItems[0].speaker_label;
  let outSpeaker = 1;
  let trans = [];
  wordsCount = transcripts?.[0]?.transcript?.split(' ').filter(a => a !== '.').length;
  filteredItems?.forEach(off => {
    if (speaker === off.speaker_label) {
      trans.push(off);
    } else {
      let phrase = trans.map(tr => tr.alternatives?.[0]?.content).join(' ');
      wordsArr.push(...trans.map(tr => tr.alternatives?.[0]?.content));
      const start = trans[0]?.start_time;
      const transF = trans.filter(tr => tr?.end_time);
      const end = transF[transF.length - 1]?.end_time;
      outSpeaker = outSpeaker === 1 ? 2 : 1;
      out.push({
        text: phrase,
        start: start,
        end: end,
        duration: `${start}s - ${end}s`,
        speaker: outSpeaker,
      })
      trans.length = 0;
      trans.push(off);
      speaker = off.speaker_label;
    }
  })
  if (trans.length > 0) {
    let phrase = trans.map(tr => tr.alternatives?.[0]?.content).join(' ');
    wordsArr.push(...trans.map(tr => tr.alternatives?.[0]?.content));
    const start = trans[0].start_time;
    const end = trans[trans.length - 1].end_time;
    outSpeaker = outSpeaker === 1 ? 2 : 1;
    out.push({
      text: phrase,
      start: start,
      end: end,
      duration: `${start}s - ${end}s`,
      speaker: outSpeaker,
    })
  }

  return {phrases: out, wordsCount, words: wordsArr};
}

//az
const transformSource3 = (chunks = {}) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  //console.log('chunks in', chunks);

  const phrasesArr = chunks?.recognizedPhrases;
  if (phrasesArr.length) {
    //console.log('phrasesArr', phrasesArr);
    phrasesArr.forEach((phrase, idx) => {
      const candidates = phrase.nBest;
      const start = moment.duration(phrase.offset, moment.ISO_8601).asSeconds().toFixed(2);
      const duration = moment.duration(phrase.duration, moment.ISO_8601).asSeconds();
      const end = (start * 1 + duration * 1).toFixed(2);

      const bestItem = candidates.reduce((prev, current) => (+prev.confidence > +current.confidence) ? prev : current)
      wordsCount += bestItem?.words?.length;
      wordsArr.push(...bestItem?.words.map(it => it.word));
      //console.log('bestItem', bestItem);

      out.push({
        text: bestItem.display,
        duration: `${start}s - ${end}s`,
        start,
        end,
        speaker: idx % 2 == 0 ? 1 : 2,
      })
    })
  }

  return {phrases: out, wordsCount, words: wordsArr};
}

//mh
const transformSource4 = (chunks = {}) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  const {result} = chunks;
  const {offsets} = result;

  let speaker = offsets[0].speaker;
  let trans = [];
  let outSpeaker = 1;
  wordsCount = offsets.length;

  offsets.forEach(off => {
    if (speaker === off.speaker) {
      trans.push(off);
    } else {
      let phrase = '';
      phrase = trans.map(tr => tr.word).join(' ');
      const start = trans[0].start / 1000;
      const end = trans[trans.length - 1].end / 1000;
      outSpeaker = outSpeaker === 1 ? 2 : 1;
      out.push({
        text: phrase,
        start: start,
        end: end,
        duration: `${start}s - ${end}s`,
        speaker: outSpeaker,
      })
      trans.length = 0;
      trans.push(off);
      speaker = off.speaker
    }
    wordsArr.push(off.word);
  })
  if (trans.length > 0) {
    let phrase = trans.map(tr => tr.word).join(' ');
    const start = trans[0].start / 1000;
    const end = trans[trans.length - 1].end / 1000;
    outSpeaker = outSpeaker === 1 ? 2 : 1;
    out.push({
      text: phrase,
      start: start,
      end: end,
      duration: `${start}s - ${end}s`,
      speaker: outSpeaker,
    })
  }

  return {phrases: out, wordsCount, words: wordsArr};
}

//gl v1
const transformSource6 = (chunks = {}) => {
  const out = [];
  let wordsCount = 0;
  const wordsArr = [];
  const results = chunks?.results || [];

  results.forEach((res, idx) => {
    const alt = res.alternatives?.[0];
    if (alt && alt?.words) {
      const start = alt.words[0]?.start_time;
      const end = alt.words[alt.words.length - 1].end_time;
      wordsCount += alt.words.length;
      wordsArr.push(...alt.words.map(w => w.word))
      out.push({
        text: alt?.transcript || '',
        duration: `${start} - ${end}`,
        start: start.replace('s', '') * 1,
        end: end.replace('s', '') * 1,
        speaker: idx % 2 == 0 ? 1 : 2,
      })
    }
  })

  return {phrases: out, wordsCount, words: wordsArr};
}

export const transformChunks = (service, chunksData) => {
  //console.log('SERVICE', service, chunksData);
  switch (service) {
    case '0':
      return transformSource0(chunksData);
    case '1':
      return transformSource1(chunksData);
    case '2':
      return transformSource2(chunksData);
    case '3':
      return transformSource3(chunksData);
    case '4':
      return transformSource4(chunksData);
    case '6':
      return transformSource6(chunksData);
    default:
      return [];
  }
};

export const collectWords = (chunksData) => {
  const words = [];
  Object.keys(chunksData).forEach(service => {
    const collect = transformChunks(service, chunksData[service]?.parsing);
    if (collect?.words) {
      words.push(...collect.words);
    }
  })
  return words;
};