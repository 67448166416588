import React, {useEffect, useState} from 'react';
import PermissionContext from "./PermissionContext";
import {useDispatch, useSelector} from "react-redux";
import UsersAction from "../../store/users/usersAction";
import {getIsAuth} from "../../store/auth/authSelector";


const PermissionProvider = ({children}) => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState({});
  const isAuth = useSelector(getIsAuth);


  useEffect(() => {
    if (isAuth) {
      //console.log('permission provider loaded!');
      dispatch(UsersAction.getUserInfo(apiPermissions => {
        setPermissions(apiPermissions);
      }));
      //dispatch(UsersAction.getUserQuota(quotas => void(0)));
    }
  }, [isAuth])


  const isAllowedTo = (permission) => {
    if (!permission) return true;
    return permissions[permission]
  };


  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;