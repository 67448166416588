import moment from "moment";
import ExportPdfResults from "../ExportPdf/ExportPdfResults";
import React from "react";

class ExportJson {
  constructor(data) {
    this.data = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "JSON_TRANSCRIPTION_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }

  prepareResults() {
    console.log('this.rawData', this.data)
    return this;
  }

  export() {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.fileName + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    return this;
  }
}

export default ExportJson