import React from "react";

import * as Styled from "./styled";
import "./style.css";

import MainLogo from "./MainLogo";
import MainMenu from "./MainMenu";

import { Layout} from 'antd';
import UserMenu from "./UserMenu";
import MainQuota from "./MainQuota";
import config from "../../../config/config";
import {EXT_TITLE, VERSION} from "../../../config/consts";
import LogoIcon from "../../../components/UI/Icons/LogoIcon";
const { Header} = Layout;

const MainHeader = () => (
  <Styled.CustomHeader id={'topnav'}>
    <MainLogo />
    <MainMenu />
    {
      config.irbisHeaderQuota && <MainQuota />
    }
    <Styled.NavbarBrand to={'/'}>
      <LogoIcon
        type={config.headerLogo}
        title={`${config.appTitle || EXT_TITLE} ${VERSION}`}
        style={{maxHeight: '40px'}}
      />
    </Styled.NavbarBrand>
    <UserMenu />
  </Styled.CustomHeader>
);

export default MainHeader;