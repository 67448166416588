import caseHeader from '../assets/img/themes/default/folder_header.png';
import addElement from "../assets/img/add_element.png";
import investigationCover from "../assets/img/case_cover.png";

export const defaultTheme = {
  main_bg: '#D6DCE4',
  header_bg: '#001529',
  header_shadow: '#5b687d',
  header_menu: '#C9CED6',
  header_menu_active: '#3282F7',
  header_user: '#c9ced6',
  header_user_avatar: '#252d3a',
  header_user_avatar_bg: '#ccc',
  footer_color: '#FFF',
  sider_bg: '#FFF',
  sider_tree_color: '#000',
  sider_tree_selected_color: '#000',
  sider_tree_selected_bg: '#D6DCE4',
  case_header: caseHeader,
  case_bg: '#F0F1F2',
  case_border: '#BDC8D3',
  case_title_color: '#14161B',
  case_subtitle_color: '#99A3B9',
  investigation_cover: investigationCover,
  add_element: addElement,
  add_element_title: ``,
  add_element_container: ``,
  target_element_border: '0px',
  grid_switcher_bg: '#FFF',
  grid_switcher_border: '',
  grid_switcher_highlight: 'lightgray',
  grid_switcher_color: '#6a7996',
  grid_switcher_inactive_color: '#726f6f91',
  grid_widget_bg: '#F8F9FA',
  grid_widget_shadow: 'none',
  grid_widget_border: '1px solid #A7B1BF',
  grid_widget_border_radius: '0',
  grid_widget_margin: [10, 10],
  grid_widget_row_height: 30,
  grid_header_bg: '#F0F1F2',
  grid_header_color: '',
  grid_header_border: '1px solid #C9D6EB',
  grid_resizer_position: `bottom: 0; right: 0;`,
  button_border: '#98A3B9',
  button_border_radius: '0',
  button_bg: '#98A3B9',
  button_hover: '#3282F7',
  button_active: '#3282F7',
  button_text_bg: 'transparent',
  modal_bg: '#f8f9fa',
  modal_border_radius: '2px',
  modal_title_color: '#14161B',
  input_border_radius: '2px',
  input_group_gap: 'inherit',
  post_line_bg: '#F0F1F2',
  post_line_border_radius: '0',
  tabs_bg: '#F2F2F2',
  tabs_border_radius: '0',
  tabs_active_bg: '#98A3B9',
  tabs_active_color: '#F8F9FA',
  login_bg: '#001529',
  login_border: '1px solid #A7B1BF',
  login_border_radius: '0',
  login_box_shadow: 'none',
  login_input_border: '1px solid #CFD0D7',
  login_input_border_radius: '2px',
}