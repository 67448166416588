import { put, takeEvery, select, delay, all, call } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import TasksAction from "./tasksAction";
import AudioAction from "../audio/audioAction";

import mockTasks from "../../mock/tasks.json";
import mockTask from "../../mock/task.json";

import mockParsing0 from "../../mock/parsingRaw0.json";
import mockParsing1 from "../../mock/parsingRaw1.json";
import mockParsing2 from "../../mock/parsingRaw2.json";
import mockParsing4 from "../../mock/parsingRaw4.json";
import mockAnalysing0 from "../../mock/analysingRaw0.json";
import mockAnalysing1 from "../../mock/analysingRaw1.json";
import mockAnalysing2 from "../../mock/analysingRaw2.json";
import mockAnalysing4 from "../../mock/analysingRaw4.json";
import mockModels from "../../mock/modelsRaw.json";
import config from "../../config/config";
import {apiAudioService} from "../../config/api";
import axios from "axios";
import {prepareSupportedLanguages, prepareTasksList} from "./tasksTransformer";



function* getTasksListSaga(action) {
  const {callback, options = {}, page = 0} = action.payload;
  try {
    const {data} = yield apiAudioService.getSpeechRequests({page, size: 15, ...options});
    //const data = mockTasks;
    yield put(TasksAction.updateTasksState({list: prepareTasksList(data)}));
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createTaskSaga(action) {
  const {postData, postOptions, callback} = action.payload;
  try {
    console.log('options', postOptions)
    //const {data} = yield apiAudioService.speechAnalyze(postData, postOptions);
    const {data} = yield axios.post(`${config.api_url}/speech/stt`, postData, postOptions);
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getTaskSaga(action) {
  const {id, callback} = action.payload;
  try {
    const {data: apiData} = yield apiAudioService.getSpeechById(id);
    const {request, response} = apiData;
    //console.log('apiData', apiData);

    //console.log('request', request.progress)
    if (request?.progress !== undefined) {
      yield put(AudioAction.updateAudioState({progress: request.progress}));
    }


    if (Array.isArray(response)) {
      const callEffects = [];

      response.forEach(item => {
        if (item.service !== undefined) {
          const service = item.service * 1;

          ///parsing
          if (item.response) {
            const resp = JSON.parse(item.response);
            //if (config.services.includes(service + '')) {
              //console.log('resp', resp, service)

              callEffects.push(call(function* () {
                yield put(AudioAction.updateAudioParsing(item.id, service, resp));
                yield put(AudioAction.updateAudioParsingStatus(service, 'done'));
                //yield put(AudioAction.updateAudioProgress(config.progressInc));
              }))

            //}
          }

          //analysis
          if (item.results && Array.isArray(item.results)) {
            item.results.forEach(res => {
              if (
                res.model === config.defaultModel
                //&& config.services.includes(service + '')
              ) {
                callEffects.push(call(function* () {
                  yield put(AudioAction.updateAudioAnalyzing(service, {
                    ...res.result,
                    text: item.text,
                    id: item.id,
                    model: res.model,
                  }));
                  yield put(AudioAction.updateAudioAnalyzingStatus(service, 'done'));
                  //yield put(AudioAction.updateAudioProgress(config.progressInc));
                }))
              }
              callEffects.push(call(function* () {
                yield put(AudioAction.updateAudioModels(item.id, service, res.model, res));
              }))

            })
          }

        }
      })

      if (callEffects.length > 0) {
        yield all(callEffects)
      }
    }


    //const data = mockTask;

    yield put(TasksAction.updateTasksState({
      task: {
        ...request,
        //url: "http://localhost:3000/demo/demo.mp3",
        url: request?.type === config.textService
          ? `${config.api_file_text}?fileName=${request.fileName}`
          : `${config.api_file}?fileName=${request.fileName}`
      }
    }));

    /*if (false) {
      yield put(AudioAction.updateAudioParsing(mockParsing0.service, mockParsing0.response));
      yield put(AudioAction.updateAudioParsingStatus(mockParsing0.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioParsing(mockParsing1.service, mockParsing1.response));
      yield put(AudioAction.updateAudioParsingStatus(mockParsing1.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioParsing(mockParsing2.service, mockParsing2.response));
      yield put(AudioAction.updateAudioParsingStatus(mockParsing2.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioParsing(mockParsing4.service, mockParsing4.response));
      yield put(AudioAction.updateAudioParsingStatus(mockParsing4.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));


      ///ANALYSING
      yield put(AudioAction.updateAudioAnalyzing(mockAnalysing0.service, {...mockAnalysing0.result, text: mockAnalysing0.text}));
      yield put(AudioAction.updateAudioAnalyzingStatus(mockAnalysing0.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioAnalyzing(mockAnalysing1.service, {...mockAnalysing1.result, text: mockAnalysing1.text}));
      yield put(AudioAction.updateAudioAnalyzingStatus(mockAnalysing1.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioAnalyzing(mockAnalysing2.service, {...mockAnalysing2.result, text: mockAnalysing2.text}));
      yield put(AudioAction.updateAudioAnalyzingStatus(mockAnalysing2.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioAnalyzing(mockAnalysing4.service, {...mockAnalysing4.result, text: mockAnalysing4.text}));
      yield put(AudioAction.updateAudioAnalyzingStatus(mockAnalysing4.service, 'done'));
      yield put(AudioAction.updateAudioProgress(config.progressInc));

      yield put(AudioAction.updateAudioState({models: mockModels}));
    }*/

    //yield delay(2000);
    callback(request);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* rerunTaskSaga(action) {
  const {id, model = config.defaultModel, callback} = action.payload;
  try {
    const {data} = yield apiAudioService.updateAIRequest(id, model);

    yield delay(2000);
    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* deleteTaskSaga(action) {
  const {id, callback} = action.payload;
  try {
    const {data: apiData} = yield apiAudioService._delete(id);
    console.log('apiData', apiData);

    callback(apiData);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getSupportedLanguagesSaga(action) {
  const callback = action.payload;
  try {
    const {data: apiData} = yield apiAudioService.getSupportedLanguage();
    const uniq = [...new Map(apiData.map(item => [item['code'], item])).values()];
    yield put(TasksAction.updateTasksState({languages: prepareSupportedLanguages(uniq)}));
    //console.log('supportedLanguages', apiData);

    callback(apiData);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchTasks() {
  yield takeEvery(TasksAction.GET_TASKS_LIST, getTasksListSaga);
  yield takeEvery(TasksAction.CREATE_TASK, createTaskSaga);
  yield takeEvery(TasksAction.DELETE_TASK, deleteTaskSaga);
  yield takeEvery(TasksAction.GET_TASK, getTaskSaga);
  yield takeEvery(TasksAction.RERUN_TASK, rerunTaskSaga);
  yield takeEvery(TasksAction.GET_SUPPORTED_LANGUAGES, getSupportedLanguagesSaga);
}