import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import "./i18n";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { reducers } from "./store/rootReducer";
import { rootSaga } from "./store/rootEffect";
import AppAction from "./store/app/appAction";
import config from "./config/config";

const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === AppAction.RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action)
}
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

const isEmbed = (config.irbisProMode && document.referrer && window !== window.parent) || !config.irbisProMode;

////
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
