import { all } from "redux-saga/effects";
import { watchSettings } from "./settings/settingsEffect";
import { watchAuth } from "./auth/authEffect";
import { watchUsers } from "./users/usersEffect";
import { watchAudit } from "./audit/auditEffect";
import {watchAudio} from "./audio/audioEffect";
import {watchTasks} from "./tasks/tasksEffect";
import {watchPrompt} from "./prompt/promptEffect";

export function* rootSaga() {
  yield all([
    watchAuth(),
    watchSettings(),
    watchUsers(),
    watchAudit(),
    watchAudio(),
    watchTasks(),
    watchPrompt(),
  ]);
}
