import ExportJson from "../ExportJson";
import ExportPdf from "../ExportPdf";


const HANDLERS = {
  json: ExportJson,
  pdf: ExportPdf,
};

class ExportFactory {
  static getExportHandler(type, data) {
    const handler = HANDLERS[type];
    return handler ? new handler(data) : null;
  }
}

export default ExportFactory