import BaseReducer from "../BaseReducer";
import TasksAction from "./tasksAction";

export default class TasksReducer extends BaseReducer {
  initialState = {
    list: {},
    task: null,
    languages: [],
  };

  [TasksAction.CLEAR_TASKS_STATE](state, action) {
    return {
      ...this.initialState,
    };
  }

  /* SET ANY PROPERTY TO Tasks store*/

  [TasksAction.UPDATE_TASKS_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }


  }
