import ActionUtility from "../ActionUtility";

export default class PromptAction {

  static UPDATE_PROMPT_STATE = "PromptAction.UPDATE_PROMPT_STATE";
  static CLEAR_PROMPT_STATE = "PromptAction.CLEAR_PROMPT_STATE";

  static GET_SUMMARY_PROMPT_LIST = "PromptAction.GET_SUMMARY_PROMPT_LIST";
  static UPDATE_SUMMARY_PROMPT_LIST = "PromptAction.UPDATE_SUMMARY_PROMPT_LIST";

  static GET_PROMPT_LIST = "PromptAction.GET_PROMPT_LIST";
  static UPDATE_PROMPT = "PromptAction.UPDATE_PROMPT";
  static GET_PROMPT = "PromptAction.GET_PROMPT";
  static SET_PROMPT_HISTORY = "PromptAction.SET_PROMPT_HISTORY";
  static DELETE_PROMPT = "PromptAction.DELETE_PROMPT";



  static clearPromptState() {
    return ActionUtility.createAction(PromptAction.CLEAR_PROMPT_STATE);
  }

  static updatePromptState(action) {
    return ActionUtility.createAction(PromptAction.UPDATE_PROMPT_STATE, action);
  }

  static getSummaryPromptList(callback) {
    return ActionUtility.createAction(PromptAction.GET_SUMMARY_PROMPT_LIST, {callback});
  }

  static updateSummaryPromptList(data, callback) {
    return ActionUtility.createAction(PromptAction.UPDATE_SUMMARY_PROMPT_LIST, {data, callback});
  }

  static getPromptList(callback) {
    return ActionUtility.createAction(PromptAction.GET_PROMPT_LIST, {callback});
  }

  static updatePrompt(id, prompt, callback) {
    return ActionUtility.createAction(PromptAction.UPDATE_PROMPT, {id, prompt, callback});
  }

  static getPrompt(id, callback) {
    return ActionUtility.createAction(PromptAction.GET_PROMPT, {id, callback});
  }

  static setPromptHistory(id, callback) {
    return ActionUtility.createAction(PromptAction.SET_PROMPT_HISTORY, {id, callback});
  }

  static deletePrompt(id, callback) {
    return ActionUtility.createAction(PromptAction.DELETE_PROMPT, {id, callback});
  }

}