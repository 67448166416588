import React, {memo, useEffect} from "react";
import { useSelector } from "react-redux";
import {themes} from "../../../config/themes";
import config from "../../../config/config";
import {ThemeProvider} from "styled-components";
//import PopupAction from "../../../store/popup/popupAction";

export default memo(() => {
  const isShown = useSelector((state) => state.popup.isPopupShown);
  const content = useSelector((state) => state.popup.popupContent);

  //const dispatch = useDispatch();
  /*const closePopup = useCallback(
    () => dispatch(PopupAction.closePopup()),
    [dispatch],
  );*/

  useEffect(() => {
    //console.log('isShown', isShown);
  }, [isShown])

  /*return (
    <Modal title="Basic Modal" visible={isShown} onCancel={closePopup} footer={null}>
      {content}
    </Modal>
  );*/

  return isShown ? <ThemeProvider theme={themes?.[config.theme]}>{content}</ThemeProvider> : null
});
