/**
 * This service implements functionality to make api calls through open api generated client
 * We are adding custom axios instance which helps customize the generated client with interceptors and more axios functionalities
 */
import {
  AuthControllerApi,
  UserAPIApi,
  ApiToWorkWithInvestigationsApi,
  ApiToWorkWithCasesApi,
  ApiToGetImageFileApi,
  ApiToGetAuditApi,
  ApiToWorkWithAvatarsApi,
  ApiToWorkWithProfileApi,
  ApiToWorkWithRekognitionApi,
  ApiToWorkWithAvatarActivityApi,
  ApiToWorkWithAnalyticsApi,
  ApiToWorkWithWebintApi,
  ApiCRUDToCreateAlertCriteriaApi,
  ApiToWorkWithProxiesApi,
  ApiToViewAndSearchInAlertsApi,
  JobsAPIApiFp,
  JobsAPIApi,
  ApiToWorkWithSpeechRecognizeAndAnalyticApi,
} from "../services/openapi";
import axios from "axios";
import { authHeader } from "../helpers/authHeader";
import config from "./config";

// Create axios instance and update auth header each time
const axiosInstance = axios.create({ headers: authHeader() });
axiosInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...authHeader() };
  //console.log('axios.config', config);
  return config;
});

// Configuration and base path are not provided
const basePath = config.api_base_path;
const apiAuthService = new AuthControllerApi(
  undefined,
  basePath,
  axiosInstance
);
const apiUsersService = new UserAPIApi(undefined, basePath, axiosInstance);
const apiInvestigationService = new ApiToWorkWithInvestigationsApi(
  undefined,
  basePath,
  axiosInstance
);
const apiCaseService = new ApiToWorkWithCasesApi(
  undefined,
  basePath,
  axiosInstance
);
const apiProfileService = new ApiToWorkWithProfileApi(
  undefined,
  basePath,
  axiosInstance
);
const apiWebintService = new ApiToWorkWithWebintApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAvatarsService = new ApiToWorkWithAvatarsApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAvatarsActivityService = new ApiToWorkWithAvatarActivityApi(
  undefined,
  basePath,
  axiosInstance
);
const apiImageService = new ApiToGetImageFileApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAuditService = new ApiToGetAuditApi(
  undefined,
  basePath,
  axiosInstance
);
const apiRekognitionService = new ApiToWorkWithRekognitionApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAnalyticsService = new ApiToWorkWithAnalyticsApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAlertsService = new ApiCRUDToCreateAlertCriteriaApi(
  undefined,
  basePath,
  axiosInstance
);
const apiAlertsListService = new ApiToViewAndSearchInAlertsApi(
  undefined,
  basePath,
  axiosInstance
);

const apiProxyService = new ApiToWorkWithProxiesApi(
  undefined,
  basePath,
  axiosInstance
);

const apiJobsService = new JobsAPIApi(undefined, basePath, axiosInstance);
const apiAudioService = new ApiToWorkWithSpeechRecognizeAndAnalyticApi(
  undefined,
  basePath,
  axiosInstance
);

export {
  apiAuthService,
  apiUsersService,
  apiInvestigationService,
  apiCaseService,
  apiProfileService,
  apiWebintService,
  apiAvatarsService,
  apiImageService,
  apiAuditService,
  apiRekognitionService,
  apiAvatarsActivityService,
  apiAnalyticsService,
  axiosInstance,
  apiAlertsService,
  apiAlertsListService,
  apiProxyService,
  apiJobsService,
  apiAudioService,
};
