import React from "react";
import {pdf} from '@react-pdf/renderer';
import moment from "moment";
import ExportPdfResults from "./ExportPdfResults";
import {transformChunks} from "../../../store/audio/audioTransformer";
import ExportPdfSummary from "./ExportPdfSummary";

class ExportPdf {
  constructor(data) {
    this.data = [];
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "RESULTS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareResults() {
    //console.log('this.rawData', this.rawData)
    this.fileName = "RESULTS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    const prepared = Object.keys(this.rawData).map(service => {
      return {
        service: service,
        chunks: transformChunks(service, this.rawData?.[service]?.parsing),
        analysis: this.rawData?.[service]?.analysis,
      }
    })
    console.log('prepared this.rawData', prepared)
    this.data = <ExportPdfResults data={prepared} />;
    return this;
  }

  prepareSummary() {
    console.log('this.rawData', this.rawData)
    this.fileName = "SUMMARY_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    const prepared = this.rawData;
    this.data = <ExportPdfSummary data={prepared} />;
    return this;
  }

  async export() {
    await this.downloadBlob(pdf(this.data).toBlob());
    return this;
  }

  //other methods
  downloadBlob(promise) {
    return new Promise(resolve => {
      promise.then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.fileName + '.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => {
          URL.revokeObjectURL(link.href)
          resolve('resolved');
        }, 3000);
      })
    });
  }
}

export default ExportPdf