import { lazy } from "react";

import MainLayout from "../layouts/MainLayout";
import LoginLayout from "../layouts/LoginLayout";

export const ROLES = {
  admin: "ADMIN",
  user: "USER",
};

export const paths = {
  home: "/",
  text: "/text",
  //tasks: "/tasks",
  task: "/task/:id",
  prompts: "/prompts",
  any: "*",
};

const Home = lazy(() => import("../pages/Tasks")),
  //Text = lazy(() => import("../pages/Text")),
  Text = lazy(() => import("../pages/TextTasks")),
  Task = lazy(() => import("../pages/Task")),
  Prompts = lazy(() => import("../pages/Prompts")),
  UsersManagement = lazy(() => import("../pages/UsersManagement")),
  Login = lazy(() => import("../pages/Login")),
  Logout = lazy(() => import("../pages/Logout")),
  NotFound = lazy(() => import("../pages/NotFoundPage"));

const createRoute = (
  pathname,
  component,
  layout,
  needRole = "",
  name = "",
  nameToDisplay = "",
  mainMenu = false,
  state = {},
  permission = false,
  redirect = false,
) => {
  return {
    location: {
      pathname,
      state,
    },
    name,
    nameToDisplay,
    needRole,
    mainMenu,
    component,
    layout,
    permission,
    redirect,
  };
};

export const Routes = [
  createRoute(paths.home, Home, MainLayout, ROLES.user, "Home", "Speech", true),
  createRoute(paths.text, Text, MainLayout, ROLES.user, "Text", "Text", true),
  createRoute(paths.task, Task, MainLayout, ROLES.user, "Task", "Task", false),
  createRoute(paths.prompts, Prompts, MainLayout, ROLES.user, "Prompts", "Prompts management", false),
  createRoute(paths.login, Login, LoginLayout),
  createRoute(paths.logout, Logout),
  createRoute(paths.any, NotFound, MainLayout, ROLES.admin),
];