import React, {memo} from "react";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import "./style.css";
import * as Styled from "./styled";
import MainWebSocket from "./MainWebSocket";
import {ThemeProvider} from "styled-components";
import {themes} from "../../config/themes";
import config from "../../config/config";
import MainDrawer from "./MainDrawer";

const MainLayout = memo(({children}) => {
  return (
    <ThemeProvider theme={themes?.[config.theme]}>
      <Styled.CustomLayout className="layout">
        <MainHeader/>
        {/*<Content><Styled.Wrapper>{children}</Styled.Wrapper></Content>*/}
        <>{children}</>
        <MainFooter/>
        <MainDrawer />
        {/*<MainWebSocket/>*/}
      </Styled.CustomLayout>
    </ThemeProvider>
  );
});

export default MainLayout;