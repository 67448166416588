import styled from "styled-components";
import {Drawer} from "antd";

export const FullVersionDrawer = styled(Drawer)`
  .ant-drawer-title,
  .ant-drawer-close
  {
    color: #FFFFFF;
  }
  .ant-drawer-wrapper-body,
  .ant-drawer-header,
  .ant-drawer-body{
    background-color: #001529;
  }
  .ant-drawer-header{
    border-bottom: none;
  }
  .ant-result-title,
  .ant-result-extra{
    color: #FFFFFF;
    line-height: initial;
  }
`;
