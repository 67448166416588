import BaseReducer from "../BaseReducer";
import PromptAction from "./promptAction";

export default class PromptReducer extends BaseReducer {
  initialState = {
    summaryList: {},
    list: {},
  };

  [PromptAction.CLEAR_PROMPT_STATE](state, action) {
    return {
      ...this.initialState,
    };
  }

  /* SET ANY PROPERTY TO Tasks store*/

  [PromptAction.UPDATE_PROMPT_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }


  }
