import React from 'react';
import {Page, Text, View, Document, Font, Image} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
//import Groups from "./Groups";
import config from "../../../../config/config";
//import {entities} from "../../../../config/summary";
import logo from "../../../../assets/img/logos/v2t.png";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {Icon} from "../Icons";
import {ImageRender} from "../Images";
import speaker1 from "../../../../assets/img/icons/speaker1.png";
import speaker2 from "../../../../assets/img/icons/speaker2.png";
import personIcon from "../../../../assets/img/manipulators/friends.png";
import {SupportService} from "../../../support";
import i18n from "i18next";


Font.register({
  family: 'pdfFont',
  src: font
});

const ExportPdfSummary = ({t, data: exportData}) => {
  const entities = [
    {
      field: 'topic',
      title: <><b>{i18n.t("Topic")}</b> {i18n.t("identifying a central theme")}</>,
      label: i18n.t("Topic"),
    },
    {
      field: 'summary',
      title: <><b>{i18n.t("Summary")}</b> {i18n.t("analyzing main points")}</>,
      label: i18n.t("Summary"),
    },
    {
      field: 'summary_with_entities',
      title: <><b>{i18n.t("Full report")}</b></>,
      label: i18n.t("Full report"),
    },
    /*{
      field: 'detail_topic',
      title: <><b>{i18n.t("Detailed Topic")}</b> </>,
      label: i18n.t("Detailed Topic"),
    },
    {
      field: 'summaries',
      title: <><b>{i18n.t("Speakers Summaries")}</b> </>,
      label: i18n.t("Speakers Summaries"),
    },
    {
      field: 'psychological_profile_of_the_character',
      title: <><b>{i18n.t("Profile of the character")}</b> </>,
      label: i18n.t("Profile of the character"),
    },*/
    {
      field: 'languages',
      title: <><b>{i18n.t("Languages")}</b> </>,
      label: i18n.t("Languages"),
      loaded: true,
    },
    {
      field: 'entities',
      title: <><b>{i18n.t("Keywords")}</b> </>,
      label: i18n.t("Keywords"),
      loaded: true,
    },
    /*{
      field: 'intellectual_level_of_character',
      title: <><b>{i18n.t("Intellectual Level of Characters")}</b> </>,
      label: i18n.t("Intellectual Level of Characters"),
      loaded: true,
      pairs: true,
    },
    {
      field: 'is_character_is_dangerous',
      title: <><b>{i18n.t("Is character is dangerous")}</b> </>,
      label: i18n.t("Is character is dangerous"),
      loaded: true,
      pairs: true,
    },*/
    {
      field: 'danger',
      title: <><b>{i18n.t("Danger Level")}</b> </>,
      label: i18n.t("Danger Level"),
      //loaded: true,
    },
    {
      field: 'dangerComment',
      title: <><b>{i18n.t("Danger Comment")}</b> </>,
      label: i18n.t("Danger Comment"),
      //loaded: true,
    },
    {
      field: 'aggression',
      title: <><b>{i18n.t("Aggression Level")}</b> </>,
      label: i18n.t("Aggression Level"),
      //loaded: true,
    },
    {
      field: 'aggressionComment',
      title: <><b>{i18n.t("Aggression Comment")}</b> </>,
      label: i18n.t("Aggression Comment"),
      //loaded: true,
    },
  ]

  //console.log('exportData', exportData);
  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header} fixed>
          {ImageRender(logo, {width: '100px', height: '20px'})}
          <Text>{t("Created by")} {config.appTitle || EXT_TITLE} {VERSION}</Text>
        </View>
        <View style={styles.headerPadding} fixed/>


        {/*<Groups data={exportData} />*/}
        {
          entities.map(en => <View key={en.field}>
            <Text style={{marginBottom: '10px', fontSize: '18px', color: '#001529', fontFamily: 'pdfFont'}}>{en.label}</Text>
            <View style={{borderBottom: '1px solid #ececec', marginBottom: '10px', }} />
            {
              !en.loaded && exportData?.[en.field]?.result && <View style={{marginBottom: '20px',}}>
                <Text style={{fontSize: '14px', fontFamily: 'pdfFont', backgroundColor: 'rgba(214, 220, 228, 0.48)', padding: '20px'}}>{exportData?.[en.field]?.result}</Text>
              </View>
            }
            {
              en.loaded && <View style={{marginBottom: '20px',}}>
                {
                  en.pairs && <View style={{display: 'flex', flexWrap: 'wrap', gap: '15px', flexDirection: 'row', alignItems: 'center'}}>
                    {
                      exportData?.[en.field]?.result?.map((entity, idx) => <Text key={idx} style={{
                        fontSize: '12px',
                        fontFamily: 'pdfFont',
                        padding: "5px 20px",
                        gap: '5px',
                        display: "flex",
                        whiteSpace: 'nowrap',
                        background: '#fafafa',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                      }}>{ImageRender(personIcon, {width: '15px', height: '10px'})} {typeof entity === 'boolean' ? (entity ? 'Yes' : 'No') : entity}</Text>)
                    }
                  </View>
                }
                {
                  !en.pairs && <View style={{ display: 'flex', lexWrap: 'wrap', gap: '15px', flexDirection: 'column', alignItems: 'flex-start'}}>
                    {
                      exportData?.[en.field]?.result?.map((entity, idx) => <Text key={idx} style={{
                        fontSize: '12px',
                        fontFamily: 'pdfFont',
                        padding: "5px 20px",
                        gap: '5px',
                        display: "flex",
                        whiteSpace: 'nowrap',
                        backgroundColor: '#' + SupportService.getMd5Color(entity),
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        color: '#FFFFFF',
                      }}>{entity}</Text>)
                    }
                  </View>
                }
              </View>
            }
          </View>)
        }

        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfSummary);