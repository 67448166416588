import React, {useContext, useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import * as Styled from "./styled";
//import "./style.css"
import { withTranslation } from "react-i18next";
import {ROLES, Routes} from "../../../../config/routes";
import {useSelector} from "react-redux";
import {getIsBlank} from "../../../../store/settings/settingsSelector";
import Restricted from "../../../../components/Permissions/Restricted";
import PermissionContext from "../../../../components/Permissions/PermissionContext";


const MainMenu = withTranslation()(({ t, match }) => {
  const isNewBlank = useSelector(getIsBlank);
  const {isAllowedTo} = useContext(PermissionContext);
  const [menuItems, setMenuItems] = useState(
    Routes.filter((route) =>
      route.needRole === ROLES.user
      && route.mainMenu
      && isAllowedTo(route?.permission)
    ).map(
      ({ location, name, nameToDisplay, permission, redirect }, key) => {
        return {
          label: <>
            <Styled.MenuLink
              to={location}
              target={isNewBlank ? '_blank' : '_self'}
              isActive={(match, location) => {
                if (!match) {
                  return false;
                }
                //console.log('menu Is active', match, location, location?.pathname?.indexOf(match?.url) >= 0)
                return location?.pathname?.indexOf(match?.url) >= 0 && match?.isExact;
              }}
            >
              <i className="icon icon_folder" /> {t(nameToDisplay)}
            </Styled.MenuLink>
          </>,
          key: location.pathname,
        }
      }
    )
  )

  return (
    <Styled.HeaderNavMenu>
      <Styled.NavMenu
        mode="horizontal"
        defaultSelectedKeys={Routes.map(({location}) => location.pathname).filter(location => location === match.path)} style={{justifyContent: 'center'}}
        items={menuItems}
      />
    </Styled.HeaderNavMenu>
  );
});

export default withRouter(MainMenu);
