import {globals} from './globals';
import locales from "./locales";
import {EXT_TITLE} from "./consts";

const points = {
  api_auth: globals.api_url + "/auth",
  api_image: globals.api_url + "/image",
  api_mm_image: globals.api_mm_url + "/image",
  api_user: globals.api_url + "/user",
  api_investigation: globals.api_url + "/inv",
  api_case: globals.api_url + "/case",
  api_profile: globals.api_url + "/profile",
  api_file: globals.api_url + "/speech/file",
  api_file_text: globals.api_url + "/speech/file/text",
  api_websocket: globals.api_base_path + "/mm-websocket",
};

const env = window._env_ || process.env;

const shared = {
  roles: {
    admin_roles: ["ADMIN"],
    user_roles: ["USER"],
  },
  usersPageSize: env?.REACT_APP_USERS_PAGE_SIZE ? env?.REACT_APP_USERS_PAGE_SIZE * 1 : 10,

  maxUploadFileSizeMB: env?.REACT_APP_MAX_UPLOAD_FILE_SIZE_MB || 10,
  maxUploadFileNumber: env?.REACT_APP_MAX_UPLOAD_FILE_NUMBER || 3,

  moduleAvatars: env?.REACT_APP_MODULE_AVATARS === 'true',
  moduleAlerts: env?.REACT_APP_MODULE_ALERTS === 'true',
  moduleTopPosts: env?.REACT_APP_MODULE_TOP_POSTS === 'true',

  headerNewTab: env?.REACT_APP_HEADER_NEW_TAB === 'true',
  headerLanguages: env?.REACT_APP_HEADER_LANGUAGES === 'true',
  headerLogout: env?.REACT_APP_HEADER_LOGOUT === 'true',
  headerLogo: env?.REACT_APP_HEADER_LOGO || 'logo',
  headerUsername: env?.REACT_APP_HEADER_USERNAME || null,

  appTitle: env?.REACT_APP_TITLE || EXT_TITLE,
  theme: env?.REACT_APP_THEME || 'default',

  services: env?.REACT_APP_SERVICES ? (env?.REACT_APP_SERVICES || '').split(',') : ['0', '1', '2', '4'],
  progressInc: 100 / (env?.REACT_APP_SERVICES ? (env?.REACT_APP_SERVICES || '').split(',') : ['0', '1', '2', '4']).length / 2,
  texServices: env?.REACT_APP_TEXT_SERVICES ? (env?.REACT_APP_TEXT_SERVICES || '').split(',') : ['0', '1', '2', '4'],
  textProgressInc: 100 / (env?.REACT_APP_TEXT_SERVICES ? (env?.REACT_APP_TEXT_SERVICES || '').split(',') : ['0', '1', '2', '4']).length,
  modelAliases: {
    //"gpt-3.5-turbo-1106": "Turbos",
    //"gpt-4-1106-preview": "Mistral",
    "Turbos": "Turbos",
    "Mistral": "Mistral",
  },
  defaultModel: 'Mistral',
  supportedLanguages: [
    {iso: 'ru', label: 'Russian'},
    {iso: 'en', label: 'English'},
    {iso: 'uz', label: 'Uzbek'},
  ],
  summaryDefaultMapping: {
    'ru-RU': 0,
    'uz-UZ': 4,
  },
  sourceAliases: {
    "0": "",
    "1": "",
    "2": "",
    "3": "",
    "4": "",
  },
  summaryDefaultService: 1,
  textService: 7,
};
const AppConfig = { ...globals, ...points, ...shared, ...locales };

export default AppConfig;