import React from 'react';
import {Page, Text, View, Document, Font, Image} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
//import Groups from "./Groups";
import config from "../../../../config/config";
import logo from "../../../../assets/img/logos/v2t.png";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {Icon} from "../Icons";
import {ImageRender} from "../Images";
import speaker1 from "../../../../assets/img/icons/speaker1.png";
import speaker2 from "../../../../assets/img/icons/speaker2.png";
import i18n from "i18next";
import personIcon from "../../../../assets/img/manipulators/friends.png";
import {SupportService} from "../../../support";


Font.register({
  family: 'pdfFont',
  src: font
});

const ExportPdfResults = ({t, data: exportData}) => {
  const entities = [
    {
      field: 'sentiment',
      title: <><b>{i18n.t("Sentiment")}</b></>,
      label: t("Sentiment"),
      type: 'text',
    },
    {
      field: 'topic',
      title: <><b>{i18n.t("Topic")}</b> {i18n.t("identifying a central theme")}</>,
      label: t("Topic"),
      type: 'text',
    },
    {
      field: 'summary',
      title: <><b>{i18n.t("Summary")}</b> {i18n.t("analyzing main points")}</>,
      label: t("Summary"),
      type: 'text',
    },
    {
      field: 'detail_topic',
      title: <><b>{i18n.t("Detailed Topic")}</b> </>,
      label: t("Detailed Topic"),
      type: 'text',
    },
    {
      field: 'summaries',
      title: <><b>{i18n.t("Speakers Summaries")}</b> </>,
      label: t("Speakers Summaries"),
      type: 'array_text',
    },
    /*{
      field: 'speakerSpeech',
      title: <><b>{i18n.t("Speakers")}</b> </>,
      label: t("Speakers"),
      type: 'array_text',
    },*/
    {
      field: 'emotional_tone_of_conversation',
      title: <><b>{i18n.t("Emotional tone of conversation")}</b> </>,
      label: t("Emotional tone of conversation"),
      type: 'text',
    },
    {
      field: 'languages',
      title: <><b>{i18n.t("Languages")}</b> </>,
      label: t("Languages"),
      type: 'array_tags',
    },
    {
      field: 'summary_with_entities',
      title: <><b>{i18n.t("Full report")}</b> </>,
      label: t("Full report"),
      loaded: true,
      type: 'text',
    },
    {
      field: 'entities',
      title: <><b>{i18n.t("Keywords")}</b> </>,
      label: t("Keywords"),
      type: 'array_tags',
    },
    {
      field: 'intellectual_level_of_character',
      title: <><b>{i18n.t("Intellectual Level of Characters")}</b> </>,
      label: t("Intellectual Level of Characters"),
      type: 'array_pairs',
    },
    {
      field: 'is_character_is_dangerous',
      title: <><b>{i18n.t("Is character is dangerous")}</b> </>,
      label: t("Is character is dangerous"),
      type: 'array_pairs',
    },
    {
      field: 'psychological_profile_of_the_character',
      title: <><b>{i18n.t("Profile of the character")}</b> </>,
      label: t("Profile of the character"),
      type: 'array_text',
    },
    {
      field: 'danger',
      title: <><b>{i18n.t("Danger Level")}</b> </>,
      label: t("Danger Level"),
      type: 'level',
    },
    {
      field: 'dangerComment',
      title: <><b>{i18n.t("Danger Comment")}</b> </>,
      label: t("Danger Comment"),
      type: 'text',
    },
    {
      field: 'aggression',
      title: <><b>{i18n.t("Aggression Level")}</b> </>,
      label: t("Aggression Level"),
      type: 'level',
    },
    {
      field: 'aggressionComment',
      title: <><b>{i18n.t("Aggression Comment")}</b> </>,
      label: t("Aggression Comment"),
      type: 'text',
    },
  ]

  //console.log('exportData', exportData);
  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header} fixed>
          {ImageRender(logo, {width: '100px', height: '20px'})}
          <Text>{t("Created by")} {config.appTitle || EXT_TITLE} {VERSION}</Text>
        </View>
        <View style={styles.headerPadding} fixed/>


        {/*<Groups data={exportData} />*/}
        {
          exportData.map(data => <>
            <View>
              <Text style={{marginBottom: '10px', fontSize: '18px', color: '#001529', fontFamily: 'pdfFont'}}>{t("Service")} {parseInt(data.service) + 1}</Text>
              <Text style={{marginBottom: '10px', fontSize: '12px', color: '#9a9999', fontFamily: 'pdfFont'}}>{t("Words")}: {data.chunks.wordsCount}</Text>
              <View style={{borderBottom: '1px solid #ececec', marginBottom: '30px', }} />
              {/*<Text style={{marginBottom: '20px', fontSize: '10px'}}>{moment(from, 'x').format('DD-MM-YYYY')} - {moment(to, 'x').format('DD-MM-YYYY')}</Text>*/}
              <View style={{}} >
                {
                  data.chunks.phrases.map((chunk, idx) => <View
                    key={idx}
                    style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', marginRight: '10px', width: '100%', borderBottom: '1px dashed #ececec' }}
                  >
                    <View style={{marginRight: '10px'}}>
                      {ImageRender(chunk.speaker === 1 ? speaker1 : speaker2, {width: '30px', height: '30px'})}
                    </View>
                    <View style={{width: '430px', paddingRight: '10px', fontFamily: 'pdfFont'}}>
                      <Text style={{fontSize: '14px', fontFamily: 'pdfFont'}}>{chunk.text}</Text>
                    </View>
                    <View style={{width: '150px'}}>
                      <Text style={{fontSize: '10px', color: 'violet', fontFamily: 'pdfFont' }}>{chunk.duration}</Text>
                    </View>
                  </View>)
                }
              </View>
            </View>
            <Text break/>
            <View>
              <Text style={{marginBottom: '10px', fontSize: '18px', color: '#001529', fontFamily: 'pdfFont'}}>{t("Service")} {parseInt(data.service) + 1}: {t("Analysis")}</Text>
              <View style={{borderBottom: '1px solid #ececec', marginBottom: '30px', }} />
              <View style={{}} >
                {
                  entities.map(en => <View key={en.field}>
                    <Text style={{marginBottom: '10px', fontSize: '18px', color: '#001529', fontFamily: 'pdfFont'}}>{en.label}</Text>
                    <View style={{borderBottom: '1px solid #ececec', marginBottom: '10px', }} />
                    {
                      en.type === 'text' && data.analysis?.[en.field] && <View style={{marginBottom: '20px',}}>
                        <Text style={{fontSize: '10px', fontFamily: 'pdfFont', backgroundColor: 'rgba(214, 220, 228, 0.48)', padding: '10px'}}>{data.analysis?.[en.field]}</Text>
                      </View>
                    }
                    {
                      en.type === 'array_text' && data.analysis?.[en.field] && <View style={{marginBottom: '20px',}}>
                        {
                          data.analysis?.[en.field]?.map((txt, idx) => <Text style={{fontSize: '10px', fontFamily: 'pdfFont', backgroundColor: 'rgba(214, 220, 228, 0.48)', padding: '10px'}}>{txt}</Text>)
                        }
                      </View>
                    }
                    {
                      en.type === 'level' && data.analysis?.[en.field] && <View style={{marginBottom: '20px',}}>
                        <View style={{display: 'flex', flexWrap: 'wrap', gap: '15px', flexDirection: 'row', alignItems: 'center'}}>
                        <Text style={{
                          fontSize: '12px',
                          fontFamily: 'pdfFont',
                          padding: "5px 20px",
                          gap: '5px',
                          display: "flex",
                          whiteSpace: 'nowrap',
                          border: '1px solid #d9d9d9',
                          borderRadius: '4px',
                          color: data.analysis?.[en.field] > 0 ? 'red' : 'green',
                        }}>{data.analysis?.[en.field]}</Text>
                        </View>
                      </View>
                    }
                    {
                      en.type === 'array_pairs' && data.analysis?.[en.field] && <View style={{marginBottom: '20px',}}>
                        <View style={{display: 'flex', flexWrap: 'wrap', gap: '15px', flexDirection: 'row', alignItems: 'center'}}>
                          {
                            data.analysis?.[en.field]?.map((entity, idx) => <Text key={idx} style={{
                              fontSize: '12px',
                              fontFamily: 'pdfFont',
                              padding: "5px 20px",
                              gap: '5px',
                              display: "flex",
                              whiteSpace: 'nowrap',
                              background: '#fafafa',
                              border: '1px solid #d9d9d9',
                              borderRadius: '4px',
                            }}>{ImageRender(personIcon, {width: '15px', height: '10px'})} {typeof entity === 'boolean' ? (entity ? 'Yes' : 'No') : entity}</Text>)
                          }
                        </View>
                      </View>
                    }
                    {
                      en.type === 'array_tags' && data.analysis?.[en.field] && <View style={{marginBottom: '20px',}}>
                        <View style={{display: 'flex', flexWrap: 'wrap', gap: '15px', flexDirection: 'row', alignItems: 'center'}}>
                          {
                            data.analysis?.[en.field]?.map((entity, idx) => <Text key={idx} style={{
                              fontSize: '12px',
                              fontFamily: 'pdfFont',
                              padding: "5px 20px",
                              gap: '5px',
                              display: "flex",
                              whiteSpace: 'nowrap',
                              backgroundColor: '#' + SupportService.getMd5Color(entity),
                              border: '1px solid #d9d9d9',
                              borderRadius: '4px',
                              color: '#FFFFFF',
                            }}>{entity}</Text>)
                          }
                        </View>
                      </View>
                    }
                  </View>)
                }
              </View>
            </View>
            <Text break/>
          </>)
        }

        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfResults);