import PopupReducer from "./popup/popupReducer";
import SettingsReducer from "./settings/settingsReducer";
import AuthReducer from "./auth/authReducer";
import UsersReducer from "./users/usersReducer";
import AuditReducer from "./audit/auditReducer";
import AudioReducer from "./audio/audioReducer";
import TasksReducer from "./tasks/tasksReducer";
import PromptReducer from "./prompt/promptReducer";

export const reducers = {
  auth: new AuthReducer().reducer,
  popup: new PopupReducer().reducer,
  settings: new SettingsReducer().reducer,
  users: new UsersReducer().reducer,
  audit: new AuditReducer().reducer,
  audio: new AudioReducer().reducer,
  tasks: new TasksReducer().reducer,
  prompt: new PromptReducer().reducer,
};
