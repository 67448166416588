export const prepareTasksList = data => {
  const {content} = data;
  content.forEach(item => {
    item.key = item.id;
  })
  //console.log('content', content)
  return {...data, content}
}

export const prepareSupportedLanguages = data => {
  const out = [];
  data.forEach(({code, country = null, language}) => {
    out.push({
      code,
      iso: code.split('-')?.[1]?.toLowerCase(),
      label: country ? `${language} (${country})` : language
    })
  })
  //console.log('prepared langs', out)
  return out;
}