import ActionUtility from "../ActionUtility";

export default class AudioAction {

  static UPDATE_AUDIO_STATE = "AudioAction.UPDATE_AUDIT_STATE";
  static CLEAR_AUDIO_STATE = "AudioAction.CLEAR_AUDIO_STATE";

  static UPDATE_AUDIO_PARSING = "AudioAction.UPDATE_AUDIO_PARSING";
  static UPDATE_AUDIO_PARSING_STATUS = "AudioAction.UPDATE_AUDIO_PARSING_STATUS";
  static UPDATE_AUDIO_ANALYZING = "AudioAction.UPDATE_AUDIO_ANALYZING";
  static UPDATE_AUDIO_ANALYZING_STATUS = "AudioAction.UPDATE_AUDIO_ANALYZING_STATUS";
  static UPDATE_AUDIO_PROGRESS = "AudioAction.UPDATE_AUDIO_PROGRESS";
  static UPDATE_AUDIO_MODELS = "AudioAction.UPDATE_AUDIO_MODELS";

  static GET_AUDIO_SUMMARY = "AudioAction.GET_AUDIO_SUMMARY";
  static UPDATE_AUDIO_SUMMARY = "AudioAction.UPDATE_AUDIO_SUMMARY";

  static EXPORT_RESULTS = "AudioAction.EXPORT_RESULTS";
  static EXPORT_SUMMARY = "AudioAction.EXPORT_SUMMARY";

  static clearAudioState() {
    return ActionUtility.createAction(AudioAction.CLEAR_AUDIO_STATE);
  }

  static updateAudioState(action) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_STATE, action);
  }

  static updateAudioParsing(id, sourceId, data) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_PARSING, {id, sourceId, data});
  }

  static updateAudioParsingStatus(sourceId, status) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_PARSING_STATUS, {sourceId, status});
  }

  static updateAudioAnalyzing(sourceId, data) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_ANALYZING, {sourceId, data});
  }

  static updateAudioAnalyzingStatus(sourceId, status) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_ANALYZING_STATUS, {sourceId, status});
  }

  static updateAudioProgress(inc) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_PROGRESS, inc);
  }

  static updateAudioModels(id, sourceId, model, result) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_MODELS, {id, sourceId, model, result});
  }

  static getAudioSummary(prompt, model = null, callback) {
    return ActionUtility.createAction(AudioAction.GET_AUDIO_SUMMARY, {prompt, model, callback});
  }

  static updateAudioSummary(field, data) {
    return ActionUtility.createAction(AudioAction.UPDATE_AUDIO_SUMMARY, {field, data});
  }

  static exportResults(type, callback) {
    return ActionUtility.createAction(AudioAction.EXPORT_RESULTS, {type, callback,});
  }

  static exportSummary(type, callback) {
    return ActionUtility.createAction(AudioAction.EXPORT_SUMMARY, {type, callback,});
  }

}