import { put, takeEvery, select, delay, all, call } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import PromptAction from "./promptAction";
import {preparePromptList, prepareUpdatePromptList} from "./promptTransformer";
import {apiAudioService} from "../../config/api";



function* getSummaryPromptListSaga(action) {
  const {callback} = action.payload;
  try {
    //const {data} = yield apiAudioService.getSpeechRequests({page, size: 15});
    const data = preparePromptList();
    //console.log('eff data', data);
    yield put(PromptAction.updatePromptState({summaryList: data}));
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* updateSummaryPromptListSaga(action) {
  const {data, callback} = action.payload;
  try {
    //const {data} = yield apiAudioService.getSpeechRequests({page, size: 15});
    //const data = mockTasks;
    const saved = prepareUpdatePromptList(data);

    yield put(PromptAction.updatePromptState({summaryList: saved}));
    callback(saved);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPromptListSaga(action) {
  const {callback} = action.payload;
  try {
    const {data} = yield apiAudioService.getAllPromt();
    console.log('prompt api data', data);
    yield put(PromptAction.updatePromptState({list: data}));
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* updatePromptSaga(action) {
  const {id, prompt, callback} = action.payload;
  try {
    const {data: saved} = yield apiAudioService.updatePromtById(id, prompt);
    callback(saved);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPromptSaga(action) {
  const {id, callback} = action.payload;
  try {
    const {data} = yield apiAudioService.getPromtById(id);
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* setPromptHistorySaga(action) {
  const {id, callback} = action.payload;
  try {
    const {data} = yield apiAudioService.setActivePromtById(id);
    yield put(PromptAction.getPromptList(() => {}));
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* deletePromptSaga(action) {
  const {id, callback} = action.payload;
  try {
    const {data} = yield apiAudioService.deletePromtById(id);
    yield put(PromptAction.getPromptList(() => {}));
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}


export function* watchPrompt() {
  yield takeEvery(PromptAction.GET_SUMMARY_PROMPT_LIST, getSummaryPromptListSaga);
  yield takeEvery(PromptAction.UPDATE_SUMMARY_PROMPT_LIST, updateSummaryPromptListSaga);

  yield takeEvery(PromptAction.GET_PROMPT_LIST, getPromptListSaga);
  yield takeEvery(PromptAction.UPDATE_PROMPT, updatePromptSaga);
  yield takeEvery(PromptAction.GET_PROMPT, getPromptSaga);
  yield takeEvery(PromptAction.SET_PROMPT_HISTORY, setPromptHistorySaga);
  yield takeEvery(PromptAction.DELETE_PROMPT, deletePromptSaga);
}