import React from "react";
import logoIcon from "../../../../assets/img/logos/v2t.png";

const LogoIcon = ({ type = 'logo', style, title = ''}) => {
  const images = require.context("../../../../assets/img/logos", true);
  //console.log('images', images, images(`./${type.toLowerCase()}.png`))
  try {
    let img = images(`./${type.toLowerCase()}.png`);
    return (
      <img style={style} alt={title} title={title} src={img} />
      /*<img style={style} alt={title} title={title} src={logoIcon} />*/
    );
  } catch (e) {
    return <></>;
  }
};

export default LogoIcon;
