import ActionUtility from "../ActionUtility";

export default class TasksAction {

  static UPDATE_TASKS_STATE = "TasksAction.UPDATE_TASKS_STATE";
  static CLEAR_TASKS_STATE = "TasksAction.CLEAR_TASKS_STATE";
  static GET_TASKS_LIST = "TasksAction.GET_TASKS_LIST";
  static CREATE_TASK = "TasksAction.CREATE_TASK";
  static DELETE_TASK = "TasksAction.DELETE_TASK";
  static GET_TASK = "TasksAction.GET_TASK";
  static RERUN_TASK = "TasksAction.RERUN_TASK";
  static GET_SUPPORTED_LANGUAGES = "TasksAction.GET_SUPPORTED_LANGUAGES";


  static clearTasksState() {
    return ActionUtility.createAction(TasksAction.CLEAR_TASKS_STATE);
  }

  static updateTasksState(action) {
    return ActionUtility.createAction(TasksAction.UPDATE_TASKS_STATE, action);
  }

  static getTasksList(page = 0, options = {}, callback) {
    return ActionUtility.createAction(TasksAction.GET_TASKS_LIST, {page, options, callback});
  }

  static createTask(postData, postOptions, callback) {
    return ActionUtility.createAction(TasksAction.CREATE_TASK, {postData, postOptions, callback});
  }

  static deleteTask(id, callback) {
    return ActionUtility.createAction(TasksAction.DELETE_TASK, {id, callback});
  }

  static getTask(id, callback) {
    return ActionUtility.createAction(TasksAction.GET_TASK, {id, callback});
  }

  static rerunTask(id, model, callback) {
    return ActionUtility.createAction(TasksAction.RERUN_TASK, {id, model, callback});
  }

  static getSupportedLanguages(callback) {
    return ActionUtility.createAction(TasksAction.GET_SUPPORTED_LANGUAGES, callback);
  }

}