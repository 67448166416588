import { put, takeEvery, select } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import AudioAction from "./audioAction";
import {apiAudioService} from "../../config/api";
import ExportFactory from "../../services/Export/ExportFactory";
import {getAudioResults, getAudioSummary} from "./audioSelector";

function* getAudioSummarySaga(action) {
  const {prompt, model = 'gpt-4-1106-preview', callback} = action.payload;
  try {
    const postData = {
      promt: prompt,
    }
    if (model) {
      postData.openAIModel = model;
    }
    const {data} = yield apiAudioService.promtAnalyze(postData);
    callback(data);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportResultsSaga(action) {
  const {type, callback} = action.payload;

  try {
    const audioResults = yield select(getAudioResults);
    const exportInstance = yield ExportFactory.getExportHandler(type, audioResults);
    yield exportInstance.prepareResults();
    console.log('exportInstance', exportInstance)

    yield exportInstance.export();

    callback(true)

  } catch (e) {
    callback(false)
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportSummarySaga(action) {
  const {type, callback} = action.payload;

  try {
    const audioSummary = yield select(getAudioSummary);
    const exportInstance = yield ExportFactory.getExportHandler(type, audioSummary);
    yield exportInstance.prepareSummary();
    //console.log('exportInstance', exportInstance)
    yield exportInstance.export();

    callback(true)

  } catch (e) {
    callback(false)
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchAudio() {
  yield takeEvery(AudioAction.GET_AUDIO_SUMMARY, getAudioSummarySaga);
  yield takeEvery(AudioAction.EXPORT_RESULTS, exportResultsSaga);
  yield takeEvery(AudioAction.EXPORT_SUMMARY, exportSummarySaga);
}